import React, { useMemo, useRef, useState } from 'react';
import { Tabs } from '../../utils/Tabs/Tabs';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import { Spinner } from '../../utils/Spinner/Spinner';
import { type Activity, ActivityStatus, type Flag, type Quote } from '../Service.types';
import { RmxServiceActivityModal, type RmxServiceActivityModalParam } from '../RmxServiceTabs/RmxServiceActivityModal';
import { useModal } from '../../hooks/useModal';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { useRmxServiceApi } from '../api';
import { workColumnsDef } from '../RmxServiceTableDefinitions/MyWorkTableDefinition';
import { activityColumnsDef } from '../RmxServiceTableDefinitions/ActivityTableDefinition';

import { type BaseDataTableHookParams, useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { RmxServiceContextProvider } from './RmxServiceContext';
import { flagsColumnsDef } from '../RmxServiceTableDefinitions/FlagTableDefinition';
import { RmxViewFlag } from '../Flags/RmxViewFlag';
import { RmxCreateFlag } from '../Flags/RmxCreateFlag';
import { quoteColumnsDef } from '../RmxServiceTableDefinitions/QuoteTableDefinition';
import { getApiKey } from '../../utils/ajax';
import { getUserInformation } from '../../utils';
import { myTimeColumnsDef } from '../RmxServiceTableDefinitions/MyTimeTableDefinition';
import { RmxServiceTimePicker } from '../RmxServiceTime/RmxServiceTimePicker';
import { Button } from 'react-bootstrap';
import s from './RmxServiceComponent.module.scss';
import { RmxServiceExpenses } from '../RmxServiceExpenses/RmxServiceExpenses';
import { MyWorkPartStatusOnboardingGuide, type PartStatusColumnHeaderRefType } from '../../onboardings/MyWorkPartStatusOnboardingGuide';
import { isNull } from '../../../common/util';
import { useOnboardingContext } from '../../onboardings/OnboardingContext';
import { RmxServicePartModal, type RmxServicePartModalParam } from '../RmxServicePartModal/RmxServicePartModal';
import { RmxServiceSampleModal, type RmxServiceSampleModalParam } from '../RmxServiceSampleModal/RmxServiceSampleModal';
import { redirectToAsset } from '../RmxServiceTabs/redirectToAsset';
import { myPartColumnsDef } from '../RmxServiceTableDefinitions/MyPartTableDefinition';
import { TimeStatsModal } from '../TimeSummary/TimeStatsModal';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import { MyPartsDropdownGuide } from '../../onboardings/MyPartsDropdownGuide';
import DarkSelect from '../../shared-compat/utils/DarkSelect/DarkSelect';
import { QuoteDetailModals } from '../RmxServiceQuotes/QuoteDetailModals';

interface Props {}
// Table Column Definitions
// const partsColumns = partsColumnsDef();
// const sampleColumns = sampleColumnsDef();
const myTimeColumns = myTimeColumnsDef();
// const invoiceColumns = invoicesColumnsDef();
export function RmxServiceComponent(_: Props) {
  const api = useRmxServiceApi();
  const { assetId } = getParams(); // gets assetId from url params
  // Modal states

  const partModal = useModal<RmxServicePartModalParam>();
  const sampleModal = useModal<RmxServiceSampleModalParam>();
  const quoteModal = useModal<Quote>();
  const invoiceModal = useModal();

  const flagViewModal = useModal<Flag>();
  const rmxExpenseModal = useModal();

  const nonActivityTimeModal = useModal();
  const timeStatsModal = useModal<unknown, unknown>();
  const createFlagModal = useModal<{ activityId?: string; customerId?: number }>();
  const [activeTab, setActiveTab] = useState<string>('work');
  const [displayPartStatusOnboarding, setDisplayPartStatusOnboarding] = useState<boolean>(false);
  const [displayMyPartsDropdownGuide, setDisplayMyPartsDropdownGuide] = useState<boolean>(false);

  const myPartsDropdownGuideRef = useRef<HTMLSelectElement>(null);

  // these refs are here so the onClose function can call refetch on the tables without getting a reference error
  const myWorkTableRef = useRef<typeof myWorkTable | null>(null);
  const serviceTableRef = useRef<typeof activityTable | null>(null);
  const activityModal = useModal<RmxServiceActivityModalParam>({
    onClose: async () => {
      if (activeTab === 'activity') await serviceTableRef.current!.refetch();
      else if (activeTab === 'work') await myWorkTableRef.current!.refetch();
    }
  });

  const userInformation = getUserInformation(getApiKey()!);
  // @ts-ignore
  const userRoles = userInformation?.role as string | string[];

  const [myPartDropDownValue, setMyPartDropDownValue] = useState('Tote');
  const myPartColumns = useMemo(() => myPartColumnsDef({ dropDownValue: myPartDropDownValue }), [myPartDropDownValue]);
  const { onBoardingTitleValueMap, refetchExistingGuides } = useOnboardingContext();
  const partStatusUserOnboardingInfo = onBoardingTitleValueMap?.get('MyWorkPartStatus');

  const partStatusColumnHeaderRef = useRef<HTMLDivElement>(null) satisfies PartStatusColumnHeaderRefType;
  const workColumns = useMemo(
    () =>
      workColumnsDef(
        () => {
          setDisplayPartStatusOnboarding(true);
        },
        partStatusUserOnboardingInfo?.isActive ?? false,
        partStatusColumnHeaderRef
      ),
    [partStatusUserOnboardingInfo?.isActive, partStatusColumnHeaderRef]
  );

  const flagsColumns = useMemo(() => flagsColumnsDef(), []);

  const [initializeQuoteAssetFilter, setInitializeQuoteAssetFilter] = useState<boolean>(false);
  const [quoteAssetFilter, setQuoteAssetFilter] = useState<number | null>(assetId);

  const quotesColumns = useMemo(
    () =>
      quoteColumnsDef({
        initializeAssetFilter: initializeQuoteAssetFilter,
        setAssetFilter: setQuoteAssetFilter
      }),
    [initializeQuoteAssetFilter]
  );

  const [serviceDateFilter, setServiceDateFilter] = useState<number | null | string>(null);
  const [quotesDateFilter, setQuotesDateFilter] = useState<number | null | string>(null);
  // const [partsDateFilter, setPartsDateFilter] = useState<number | null | string>(null);
  // const [sampleDateFilter, setSampleDateFilter] = useState<number | null | string>(null);
  const [flagsDateFilter, setFlagsDateFilter] = useState<number | null | string>(null);

  const sharedTableProps = {
    enableColumnFilters: true,
    showPageSizeSelector: true,
    enableGlobalSearchBar: false
  } as const satisfies Partial<BaseDataTableHookParams<any, any>>;

  const myWorkTable = useServerDataTable({
    ...sharedTableProps,
    columns: workColumns,
    queryKey: ['my-work', assetId],
    enabled: activeTab === 'work',
    getRows: async (params) => {
      return await api.getMyActivities({ ...params, assetId: assetId, daysFilter: null, myWork: true, statusFilter: null });
    },
    initialSorting: [
      // { id: 'partStatus', desc: true }, // was showing unpicked before ready and affecting the order
      { id: 'startDate', desc: false },
      { id: 'onSiteTime', desc: false }
    ]
  });

  myWorkTableRef.current = myWorkTable;

  const myTimeTable = useServerDataTable({
    ...sharedTableProps,
    queryKey: ['my-time', assetId],
    enabled: activeTab === 'time',
    columns: myTimeColumns,
    getRows: async (params) => {
      return await api.getMyTime({ ...params, assetId: assetId, daysFilter: null, myWork: false });
    },
    initialSorting: [
      { id: 'entryDate', desc: true },
      { id: 'startTime', desc: false }
    ]
  });

  const [initializeActivityAssetFilter, setInitializeActivityAssetFilter] = useState<boolean>(false);
  const [activityAssetFilter, setActivityAssetFilter] = useState<number | null>(assetId);
  const [statusFilter, setStatusFilter] = useState<string | null>(ActivityStatus.InProgress);

  const activityColumns = useMemo(
    () =>
      activityColumnsDef({
        initializeAssetFilter: initializeActivityAssetFilter,
        setActivityAssetFilter: setActivityAssetFilter,
        initializeStatusFilter: activeTab === 'activity',
        setStatusFilter: setStatusFilter
      }),
    [activeTab, initializeActivityAssetFilter]
  );

  const activityTable = useServerDataTable({
    ...sharedTableProps,
    queryKey: ['activities', activityAssetFilter, serviceDateFilter, statusFilter],
    enabled: activeTab === 'activity',
    columns: activityColumns,
    getRows: async (params) =>
      await api.getActivities({
        ...params,
        assetId: activityAssetFilter,
        daysFilter: serviceDateFilter,
        myWork: false,
        statusFilter: statusFilter
      }),
    initialSorting: [
      { id: 'status', desc: false },
      { id: 'startDate', desc: false },
      { id: 'onSiteTime', desc: false },
      { id: 'assignedToName', desc: false }
    ]
  });

  // const partsTable = useServerDataTable({
  //   ...sharedTableProps,
  //   queryKey: ['parts', assetId, partsDateFilter],
  //   enabled: activeTab === 'parts',
  //   columns: partsColumns,
  //   getRows: async (params) => {
  //     return await api.getParts({ ...params, assetId: assetId, daysFilter: partsDateFilter, activityId: null });
  //   },
  //   initialSorting: [{ desc: true, id: 'expectedOn' }]
  // });
  //
  serviceTableRef.current = activityTable;
  //
  // const samplesTable = useServerDataTable({
  //   ...sharedTableProps,
  //   queryKey: ['samples', assetId, sampleDateFilter],
  //   enabled: activeTab === 'samples',
  //   columns: sampleColumns,
  //   getRows: async (params) => {
  //     return await api.getSamples({ ...params, assetId: assetId, daysFilter: sampleDateFilter });
  //   }
  //   // initialSorting: [{ id: 'Recommendations', desc: false }]
  // });

  const flagsTable = useServerDataTable({
    ...sharedTableProps,
    enabled: activeTab === 'flags',
    queryKey: ['flags', flagsDateFilter],
    columns: flagsColumns,
    getRows: async (params) => {
      return await api.getFlags({ ...params, daysFilter: flagsDateFilter });
    },
    initialSorting: [
      { id: 'status', desc: false },
      { id: 'createdAt', desc: true }
    ]
  });

  const quotesTable = useServerDataTable({
    ...sharedTableProps,
    queryKey: ['quotes', quoteAssetFilter],
    enabled: activeTab === 'quotes',
    columns: quotesColumns,
    getRows: async (params) => {
      return await api.getQuotes({ ...params, assetId: quoteAssetFilter });
    },
    initialSorting: [
      { id: 'status', desc: false },
      { id: 'createdAt', desc: true }
    ]
  });

  const myPartsTable = useServerDataTable({
    ...sharedTableProps,
    queryKey: ['my-parts', myPartDropDownValue],
    enabled: activeTab === 'myParts',
    columns: myPartColumns,
    getRows: async (params) => {
      return await api.myParts({
        ...params,
        getSurplus: myPartDropDownValue === 'Surplus',
        getWip: myPartDropDownValue === 'WIP',
        getInventory: myPartDropDownValue === 'Inventory'
      });
    }
  });

  const partStatusOnBoardingFirstInteraction =
    partStatusUserOnboardingInfo && partStatusUserOnboardingInfo.isActive && isNull(partStatusUserOnboardingInfo.interactionId);
  return (
    <RmxServiceContextProvider refetchServiceTable={() => Promise.resolve()} refetchMyWorkTable={() => Promise.resolve()} userRoles={userRoles}>
      {activeTab === 'work' &&
        partStatusUserOnboardingInfo &&
        (partStatusOnBoardingFirstInteraction || displayPartStatusOnboarding) &&
        partStatusColumnHeaderRef.current !== null && ( // User will not be able to interact with displayPartStatus if the partStatusOnboardingInfo is not active
          <MyWorkPartStatusOnboardingGuide
            onExit={() => setDisplayPartStatusOnboarding(false)}
            refetch={refetchExistingGuides}
            partStatusColumnHeaderRef={partStatusColumnHeaderRef}
            forceDisplay={displayPartStatusOnboarding}
          />
        )}
      {activeTab === 'myParts' && (
        <MyPartsDropdownGuide
          forceDisplay={displayMyPartsDropdownGuide}
          onExit={() => setDisplayMyPartsDropdownGuide(false)}
          dropdownRef={myPartsDropdownGuideRef}
        />
      )}
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          {
            key: 'work',
            title: 'My Work',
            content: !myWorkTable ? (
              <Spinner />
            ) : (
              <>
                <DataTable
                  negativeMarginsOnMobile={true}
                  table={myWorkTable}
                  onRowClick={(row: Activity) => {
                    void activityModal.open({
                      resource: row,
                      disableEditingActivityDetails: true, // techs do not update activity details
                      enableTechEditing: true
                    });
                  }}
                  header={
                    <div>
                      <Button onClick={() => rmxExpenseModal.setIsOpen(true)} variant={'secondary'}>
                        Add Non-Activity Expense
                      </Button>
                    </div>
                  }
                />
              </>
            )
          },
          {
            key: 'time',
            title: 'My Time',
            content: !myTimeTable ? (
              <Spinner />
            ) : (
              <>
                <DataTable
                  negativeMarginsOnMobile={true}
                  table={myTimeTable}
                  header={
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button variant={'secondary'} onClick={() => nonActivityTimeModal.setIsOpen(true)}>
                        Add Time
                      </Button>

                      <Button variant={'secondary'} onClick={() => timeStatsModal.setIsOpen(true)}>
                        Time Stats
                      </Button>
                    </div>
                  }
                />
              </>
            )
          },
          {
            key: 'myParts',
            title: 'My Parts',
            content: !myPartsTable ? (
              <Spinner />
            ) : (
              <DataTable
                negativeMarginsOnMobile={true}
                table={myPartsTable}
                header={
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <DarkSelect
                      innerRef={myPartsDropdownGuideRef}
                      onChange={(value) => {
                        setMyPartDropDownValue(value);
                      }}
                      options={['Tote', 'Surplus', 'WIP', 'Inventory']}
                      value={myPartDropDownValue}
                    />
                    <IconButton
                      icon={faQuestionCircle}
                      size='lg'
                      onClick={(event) => {
                        setDisplayMyPartsDropdownGuide(true);
                      }}
                    />
                  </div>
                }
              />
            )
          },
          {
            key: 'flags',
            title: 'My Flags',
            content: (
              <DataTable
                negativeMarginsOnMobile={true}
                table={flagsTable}
                header={
                  <Button onClick={() => createFlagModal.setIsOpen(true)} variant={'secondary'}>
                    New Flag
                  </Button>
                }
                onRowClick={async (row) => {
                  void flagViewModal.open(row);
                }}
                footer={<DayFilter value={flagsDateFilter} setGlobalDateFilter={setFlagsDateFilter} />}
              />
            )
          },
          {
            key: 'activity',
            title: 'Activities',
            // serviceData.length === 0 ? <div className={s['no-data']}>{NoDataLabel}</div> :
            content: !activityTable ? (
              <Spinner />
            ) : (
              <DataTable
                negativeMarginsOnMobile={true}
                table={activityTable}
                onRowClick={(row: Activity) => {
                  void activityModal.open({
                    resource: row,
                    disableEditingActivityDetails: row.status !== 'In Progress' || hasRole('Technician'), // TODO this needs to check whether the slam is completed
                    enableTechEditing: false
                  });
                }}
                footer={<DayFilter value={serviceDateFilter} setGlobalDateFilter={setServiceDateFilter} />}
                // header={<DarkSelect value={serviceDropDownValue} options={['Open', 'Closed']} onChange={setServiceDropDownValue}/>}
              />
            )
          },
          {
            key: 'quotes',
            title: 'Quotes',
            content: !quotesTable ? (
              <Spinner />
            ) : (
              <DataTable
                negativeMarginsOnMobile={true}
                table={quotesTable}
                onRowClick={async (row) => {
                  await quoteModal.open(row);
                }}
                footer={<DayFilter value={quotesDateFilter} setGlobalDateFilter={setQuotesDateFilter} />}
              />
            )
          }
          // {
          //   key: 'parts',
          //   title: 'Parts',
          //   content: !partsTable ? (
          //     <Spinner />
          //   ) : (
          //     <DataTable
          //       negativeMarginsOnMobile={true}
          //       table={partsTable}
          //       onRowClick={async (row) => await partModal.open({ resource: row })}
          //       footer={<DayFilter value={partsDateFilter} setGlobalDateFilter={setPartsDateFilter} />}
          //     />
          //   )
          // },
          // {
          //   key: 'samples',
          //   title: 'Samples',
          //   content: !samplesTable ? (
          //     <Spinner />
          //   ) : (
          //     // TODO: Make Samples a Server Row Table so that we you can perform the backend logic in the backend.
          //     <DataTable
          //       negativeMarginsOnMobile={true}
          //       table={samplesTable}
          //       onRowClick={async (row) => await sampleModal.open({ resource: row })}
          //       footer={<DayFilter value={sampleDateFilter} setGlobalDateFilter={setSampleDateFilter} />}
          //     />
          //   )
          // }
        ]}
      />
      {activityModal.isOpen && (
        <RmxServiceActivityModal
          state={activityModal}
          isMyWork={activeTab === 'work'}
          createFlag={(a) => createFlagModal.open({ activityId: a.id, customerId: a.customerId })}
          openAsset={async (a) => await redirectToAsset(a.assetId, a.assetName)}
          openAssetHistory={async (a) => {
            // close the modal
            activityModal.setIsOpen(false);
            // switch to the activities tab
            setActiveTab('activity');
            // filter the activities table by the assetId
            setActivityAssetFilter(a.assetId);
            // set the filter to the assetId
            setInitializeActivityAssetFilter(true);
            // set the status filter to null so that all statuses are shown
            setStatusFilter(null);
          }}
          openQuoteHistory={async (a) => {
            // close the modal
            activityModal.setIsOpen(false);
            // switch to the quotes tab
            setActiveTab('quotes');
            // filter the quotes table by the assetId or activityId not sure yet.
            setQuoteAssetFilter(a.assetId);
            // set the filter to the assetId
            setInitializeQuoteAssetFilter(true);
          }}
        />
      )}
      {partModal.isOpen && <RmxServicePartModal state={partModal} />}
      {sampleModal.isOpen && <RmxServiceSampleModal state={sampleModal} />}
      {createFlagModal.isOpen && <RmxCreateFlag refetchFlagTable={flagsTable.refetch} state={createFlagModal} />}
      {flagViewModal.isOpen && <RmxViewFlag refetchFlagTable={flagsTable.refetch} state={flagViewModal} />}
      {createFlagModal.isOpen && <RmxCreateFlag refetchFlagTable={flagsTable.refetch} state={createFlagModal} />}
      <QuoteDetailModals state={quoteModal} />
      <GenericModal open={invoiceModal.isOpen} setIsOpen={invoiceModal.setIsOpen} label={'Invoice Details'}>
        {' '}
        No PDF available
      </GenericModal>
      <GenericModal
        dialogClassName={s['time-modal-dialog']}
        showClose={true}
        hideFooter={true}
        open={nonActivityTimeModal.isOpen}
        setIsOpen={nonActivityTimeModal.setIsOpen}
        label={'Add Time'}
      >
        <RmxServiceTimePicker
          onSubmit={nonActivityTimeModal.close}
          refetchTimeTable={myTimeTable.refetch}
          options={['Select', 'Non Billable', 'INST', 'Vacation', 'Sales', 'Flex', 'Jury', 'Bereave', 'Training', 'Lunch']}
        />
      </GenericModal>
      <GenericModal showClose={true} hideFooter={true} open={rmxExpenseModal.isOpen} setIsOpen={rmxExpenseModal.setIsOpen} label={'Add Non-Activity Expense'}>
        <RmxServiceExpenses enabled={true} onSubmit={rmxExpenseModal.close} />
      </GenericModal>
      <TimeStatsModal state={timeStatsModal} />
    </RmxServiceContextProvider>
  );
}

const numberOrNull = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  const num = Number(value);

  if (isNaN(num)) {
    return null;
  }
  return num;
};

function getParams() {
  const searchParams = new URL(window.location.href).searchParams;
  const assetId = numberOrNull(searchParams.get('a'));
  const siteId = numberOrNull(searchParams.get('s'));

  return {
    assetId,
    siteId
  };
}

function DayFilter({
  value,
  setGlobalDateFilter
}: {
  value: number | null | string;
  setGlobalDateFilter: (setGlobalDateFilter: number | null | string) => void;
}) {
  return (
    <DarkSelect
      value={value === null ? 'All' : value}
      options={dayOptions}
      onChange={(e) => {
        if (e === 'All') {
          setGlobalDateFilter(null);
        } else setGlobalDateFilter(e);
      }}
    />
  );
}

const dayOptions = ['All', 7, 30, 60, 90].map((day) => {
  if (day === 'All') {
    return { label: 'All', value: day };
  }
  return { label: `${day}d`, value: day };
});

export function hasRole(requiredRole: string) {
  const userInformation = getUserInformation(getApiKey()!);
  // @ts-ignore
  const userRoles = userInformation?.role as string | string[];
  if (typeof userRoles === 'string') {
    return userRoles === requiredRole;
  }

  return userRoles.includes(requiredRole);
}
