import { useOnboardingGuide } from './OnboardingContext';
import type { RefObject } from 'react';
import { DelayShow } from '../../components/util/DelayShow';
import { OnboardingGuideComponent } from './OnboardingGuideComponent';

interface Props {
  dateInputRef: RefObject<HTMLInputElement>;
}

export function NonActivityTimeDateInputGuide({ dateInputRef }: Props) {
  const { guide, addGuideInteraction } = useOnboardingGuide('NonActivityTimeDateInputGuide');
  return !guide || !dateInputRef.current ? null : (
    <DelayShow delay={500}>
      <OnboardingGuideComponent
        guide={guide}
        addGuideInteraction={addGuideInteraction}
        steps={[
          {
            element: dateInputRef.current,
            title: '<u>Non Activity Time</u>',
            intro: `
              <p style="font-size: 15px;">
                Press this input to change the date
              </p>
              `
          }
        ]}
      />
    </DelayShow>
  );
}
