import React, {type HTMLAttributes} from 'react';
import { Accordion } from 'react-bootstrap';
import {type AccordionCollapseProps} from 'react-bootstrap/AccordionCollapse';

export type AccordionBodyProps = AccordionCollapseProps & HTMLAttributes<HTMLDivElement>;
export const AccordionBody = (props: AccordionBodyProps) =>
  <Accordion.Collapse style={{padding: 1}}  {...props as any} eventKey={props.eventKey}>
      {props.children}
  </Accordion.Collapse>
;
