import { HumanizeDurationLanguage, HumanizeDuration, type HumanizeDurationOptions } from 'humanize-duration-ts';

type HumanizeProps = {
  duration: number;
  tempOptions?: HumanizeDurationOptions | undefined;
};
export function humanizeDuration({ tempOptions, duration }: HumanizeProps) {
  const humanizer = new HumanizeDuration(new HumanizeDurationLanguage());
  return humanizer.humanize(duration, tempOptions);
}
