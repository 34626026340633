import type { Column, Table, Updater } from '@tanstack/react-table';
import type { TableState } from '../hooks';
import { DebouncedInput, type DebouncedInputProps } from '../DebouncedInput';
import { numberOrUndefined, transformOr } from '../../../utils';
import React from 'react';

export type NumberFilterValue = [number | undefined, number | undefined] | undefined;

export function NumberColumnFilter<T>({ column, table }: { column: Column<any, any>; table: Table<any>; tableState: TableState<T> }) {
  const columnFilterValue = column.getFilterValue() as NumberFilterValue;
  const setFilterValue = column.setFilterValue as (updater: Updater<NumberFilterValue>) => void;
  const sharedProps = {
    type: 'number',
    min: Number(column.getFacetedMinMaxValues()?.[0] ?? ''),
    max: Number(column.getFacetedMinMaxValues()?.[1] ?? ''),
    className: 'w-24 border shadow rounded'
  } as const satisfies Partial<DebouncedInputProps>;
  return (
    <div>
      <div className='d-flex space-x-2'>
        <DebouncedInput
          {...sharedProps}
          value={columnFilterValue?.[0] ?? ''}
          onChange={(value) => setFilterValue((old) => [numberOrUndefined(value), old?.[1]])}
          placeholder={`Min ${transformOr(column.getFacetedMinMaxValues()?.[0], (n) => `(${n})`, '')}`}
        />
        <DebouncedInput
          value={columnFilterValue?.[1] ?? ''}
          onChange={(value) => setFilterValue((old) => [old?.[0], numberOrUndefined(value)])}
          placeholder={`Max ${transformOr(column.getFacetedMinMaxValues()?.[1], (n) => `(${n})`, '')}`}
        />
      </div>
      <div className='h-1' />
    </div>
  );
}
