import { Button } from 'react-bootstrap';
import React from 'react';
import type { ItemInventory } from '../Service.types';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import type { IncludedPartMap } from './QuoteForm';

const helper = createServerColumnHelper<ItemInventory>();
const ch = helper.accessor;
export const partLibraryTableDef = (setIncludedPartsData: React.Dispatch<React.SetStateAction<IncludedPartMap>>) => {
  return [
    ch('ccn', 'string', {
      header: 'CCN',
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id
    }),
    ch('partName', 'string', {
      header: 'Part Name',
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id
    }),

    ch('lastUsedOn', 'date', {
      header: 'Last Used Date',
      enableColumnFilter: true,
      meta: { dataType: 'date' },

      cell: (info) => {
        const date = info.getValue() as Date;
        return date != null ? date.toLocaleDateString() : 'N/A';
      },
      sortDescFirst: true,
      footer: (props) => props.column.id
    }),
    ch('altPart', 'string', {
      header: 'Alternative Parts',
      meta: { dataType: 'string' },
      enableColumnFilter: true,
      cell: (info) => info.getValue() ?? 'None',
      footer: (props) => props.column.id
    }),
    helper.display({
      id: 'Action',
      header: 'Action',
      cell: (info) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='secondary'
            onClick={() => {
              setIncludedPartsData((prevParts) => {
                return prevParts.set(info.row.original.ccn, { part: info.row.original, selectedQuantity: 0 });
              });
            }}
          >
            Add
          </Button>
        </div>
      ),
      footer: (props) => props.column.id
    })
  ];
};
