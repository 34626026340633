import React, { type CSSProperties, memo, type ReactElement, useEffect, useId, useMemo, useRef, useState } from 'react';
// tslint:disable-next-line:no-import-side-effect
import './polyfill.scss';
import s from './DataTable.module.scss';
import darkSelectClasses from '../utils/DarkSelect/DarkSelect.module.scss';
// tslint:disable-next-line:no-import-side-effect
import './tanstack-table-type-overrides';
import {
  type CellContext,
  type ColumnDef,
  type FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortDirection,
  type SortingFn,
  sortingFns,
  type Table,
  type Updater,
  useReactTable
} from '@tanstack/react-table';
import { compareItems, rankItem } from '@tanstack/match-sorter-utils';
import { Alert, Button, Form, Spinner as ReactBootStrapSpinner } from 'react-bootstrap';
import { useExportData } from './useExportData';
import { GrayButton } from '../utils/GrayButton/GrayButton';
import { type TableState } from './hooks';
import { useMountTransition } from '../utils/useMountTransition';
import { type RowData } from '@tanstack/table-core/src/types';
import clsx from 'clsx';
import { useResizeDetector } from 'react-resize-detector';
import { Icon } from '../../status-indicator/Icon/Icon';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import type { ColumnDataType, FilterTypes } from './filter-types';
import { DebouncedInput } from './DebouncedInput';
import { ColumnFilterField } from './ColumnFilterField/ColumnFilterField';
import throttle from 'lodash/throttle';
import { getColumnAccessorKey } from './utils';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    dataType?: ColumnDataType<TValue>;
    serverFilters?: FilterTypes[];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Table<TData extends RowData> {}
}

export function findColumnByIdentifier<T>(columns: ColumnDef<T, any>[], identifier: string) {
  return columns.find((c) => {
    const accessorKey = getColumnAccessorKey(c);
    const id = c.id;
    if (accessorKey === undefined && id === undefined) {
      throw new Error('Column must have an id or accessorKey when looking for ' + identifier);
    }
    return id === undefined ? accessorKey === identifier : id === identifier;
  })! as ColumnDef<T, any>;
}

export function Spinner({ style }: { style?: CSSProperties }) {
  return <ReactBootStrapSpinner style={{ margin: 'auto', display: 'block', ...style }} />;
}

export function getRow<T, F>(info: CellContext<T, F>) {
  return info.row.original;
}

type DataTableProps<T> = {
  onRowClick?: (row: T, rowIndex: number) => void;
  // if this is passed, don't pass the globalFilter prop
  enableExport?: boolean;
  table: TableState<T>;
  header?: ReactElement;
  footer?: ReactElement;
  negativeMarginsOnMobile?: boolean;
};

function LoadingPanel(_: {}) {
  const hasLoadingTransitions = useMountTransition(true, 1000);
  return (
    <div
      className={s['loading-panel']}
      style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: hasLoadingTransitions ? 0.5 : 0, backgroundColor: 'black' }}
    >
      <div style={{ transform: 'translate(-50%)', left: '50%', top: '50%', position: 'absolute' }}>
        <Spinner />
      </div>
    </div>
  );
}

export function DataTable<T>(props: DataTableProps<T>) {
  const { data, columns, globalFilter, setGlobalFilter, columnFilters, setColumnFilters, sorting, setSorting, pagination, setPagination } = props.table;
  const pageSize = pagination.pageSize;
  const rowModel = props.table.rowModel;

  const table = useReactTable({
    data: data ?? [],
    columns: columns as ColumnDef<T, any>[],
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter,
      sorting,
      pagination
    },
    manualFiltering: rowModel === 'Server',
    manualPagination: rowModel === 'Server',
    manualSorting: rowModel === 'Server',
    manualGrouping: rowModel === 'Server',
    manualExpanding: rowModel === 'Server',
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    // onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: false,
  });
  const total = props.table.rowModel === 'Server' ? props.table.total : table.getPrePaginationRowModel().rows.length;
  if (props.table.enableLogging) {
    console.log('debugging table', props.table);
  }
  const exportData = useExportData(table);

  function exportDataClick() {
    exportData('xlsx', 'export.xlsx');
  }

  useEffect(() => {
    table.setPageSize(pageSize);
  }, [pageSize, table]);

  const { responsiveMode, parentRef, tableRef, showScrollShadow } = useResponsiveMode();

  return (
    <div>
      {responsiveMode && (
        <Alert className='d-inline-block' variant='dark'>
          <Icon icon={faInfoCircle} /> Swipe left and right to scroll the table horizontally
        </Alert>
      )}
      {(props.table.enableGlobalSearchBar || props.header) && (
        <div className={s['header']}>
          {props.header ?? null}
          {props.table.enableGlobalSearchBar && <GlobalFilterComponent globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />}
        </div>
      )}
      <div
        ref={parentRef}
        className={clsx(
          'mb-3',
          s['scroll-container'],
          props.negativeMarginsOnMobile && s['negative-margins-on-mobile'],
          responsiveMode && showScrollShadow && s['table-is-scrolling']
        )}
      >
        {props.table.isFetching && <LoadingPanel />}
        <table className={clsx('table table-striped table-bordered mb-0', s['data-table'])} ref={tableRef}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                <tr>
                  {headerGroup.headers.map((header) => {
                    if (header.column.columnDef.meta?.isVisible === false) return null;
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                        className={clsx(s['column-head'], props.table.enableColumnFilters && s['no-border-bottom'])}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none d-flex justify-content-between align-items-start gap-1'
                                  : '',
                                onClick: header.column.getToggleSortingHandler()
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {header.column.getCanSort() && <SortHandle direction={header.column.getIsSorted()} />}
                            </div>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
                {/* Filter Row */}
                {props.table.enableColumnFilters && (
                  <tr>
                    {headerGroup.headers.map((header) => {
                      return (
                        (header.column.columnDef.meta?.isVisible === undefined || header.column.columnDef.meta?.isVisible) && (
                          <th key={`filter-${header.id}`} className={s['filter-column-head']}>
                            {header.column.getCanFilter() ? (
                              <ColumnFilterField
                                column={header.column}
                                table={table}
                                tableState={props.table}
                                forceInitialize={header.column.columnDef.meta?.initializeFilter}
                                onFilteredFocus={header.column.columnDef.meta?.onFilteredFocus}
                              />
                            ) : null}
                          </th>
                        )
                      );
                    })}
                  </tr>
                )}
              </React.Fragment>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className={props.onRowClick ? s['clickable-row'] : ''}
                  onClick={props.onRowClick ? () => props.onRowClick!(row.original, row.index) : undefined}
                >
                  {row.getVisibleCells().map((cell) => {
                    if (cell.column.columnDef.meta?.isVisible === false) return null;
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='h-2' />
      <div className='d-flex align-items-center justify-content-between gap-2 flex-wrap'>
        <div style={{ verticalAlign: 'middle' }} className='flex-row gap-2 align-items-center d-flex flex-wrap'>
          {props.footer ?? null}
          <div className='flex-row gap-2 align-items-center d-flex'>
            {props.table.showPageSizeSelector && (
              <>
                <Form.Label className={s['form-label']}> Show </Form.Label>
                <Form.Select
                  size={'lg'}
                  value={pageSize}
                  onChange={(e) => {
                    setPagination((p) => ({ ...p, pageSize: parseInt(e.target.value) }));
                  }}
                  className={darkSelectClasses['form-select']}
                >
                  {pageSizeOptions}
                </Form.Select>
                <Form.Label className={s['form-label']}> Entries </Form.Label>
              </>
            )}
          </div>
          {props.enableExport === false ? null : <GrayButton onClick={exportDataClick}>Export</GrayButton>}
        </div>
        <PaginationInformation table={table} total={total} />
        <PaginationButtons table={table} total={total} />
      </div>

      {/*<pre>{JSON.stringify(table.getState(), null, 2)}</pre>*/}
    </div>
  );
}

const fuzzyFilter: FilterFn<string> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value as string);

  // Store the itemRank info
  addMeta({ itemRank });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type GlobalFilterProps = {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
};

function getHorizontalScrollFromRight(tableElement: HTMLElement, parentElement: HTMLElement) {
  return tableElement.offsetWidth - parentElement.offsetWidth - parentElement.scrollLeft;
}

function getShowScrollShadow(tableElement: HTMLElement, parentElement: HTMLElement) {
  return getHorizontalScrollFromRight(tableElement, parentElement) > 5;
}

function useResponsiveMode() {
  const { width: tableWidth, ref: tableRef } = useResizeDetector<HTMLTableElement>({ refreshMode: 'debounce', refreshRate: 100 });
  const parentRef = useRef<HTMLDivElement>(null);
  const responsiveMode = tableWidth !== undefined && parentRef.current !== null && tableWidth > parentRef.current.offsetWidth;
  const tableElement = tableRef.current;
  const parentElement = parentRef.current;
  const [showScrollShadow, setShowScrollShadow] = useState(true);
  useEffect(() => {
    if (parentElement && tableElement) {
      const handleScroll = throttle(() => {
        setShowScrollShadow(getShowScrollShadow(tableElement, parentElement));
      }, 200);
      parentElement.addEventListener('scroll', handleScroll);
      return () => {
        parentElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [tableElement, parentElement]);
  return { responsiveMode, parentRef, tableRef, showScrollShadow } as const;
}

const pageSizeOptions = [5, 10, 20, 50, 100].map((limit) => (
  <option key={limit} value={limit}>
    {limit}
  </option>
));

export function GlobalFilterComponent({ globalFilter, setGlobalFilter }: GlobalFilterProps) {
  const id = useId();
  return (
    <div className='d-flex align-items-center gap-2 ml-auto'>
      <label htmlFor={id} className='mb-0'>
        Search:
      </label>
      <DebouncedInput
        id={id}
        type='search'
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
        className='form-control input-sm w-auto d-inline-block'
        placeholder=''
      />
    </div>
  );
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!);
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

function SortHandle({ direction }: { direction: false | SortDirection }) {
  return (
    {
      asc: <i className={`fas fa-sort-up ${s['sort']} ${s['sort-active']}`} />,
      desc: <i className={`fas fa-sort-down ${s['sort']} ${s['sort-active']}`} />
    }[direction as string] ?? <i className={`fas fa-sort ${s['sort']}`} />
  );
}

function PaginationInformation({ table, total: totalRows }: { table: Table<any>; total: number }) {
  const paginationState = table.getState().pagination;
  const endingRowNumber = Math.min((paginationState.pageIndex + 1) * paginationState.pageSize, totalRows);
  const beginningRowNumber = endingRowNumber === 0 ? 0 : paginationState.pageIndex * paginationState.pageSize + 1;
  return (
    <div>
      Showing {beginningRowNumber} to {endingRowNumber} of {totalRows} entries
    </div>
  );
}

function PaginationButtons({ table, total }: { table: Table<any>; total: number }) {
  const totalRows = total;
  const { pageIndex, pageSize } = table.getState().pagination;
  const totalPages = Math.ceil(totalRows / pageSize);
  const pages = useMemo(() => makePageIndicators(totalPages, pageIndex), [totalPages, pageIndex]);
  const setPageIndex = table.setPageIndex;
  return <PaginationButtonsMemo pages={pages} pageIndex={pageIndex} totalPages={totalPages} setPageIndex={setPageIndex} />;
}

type PaginationButtonMemoProps = {
  pages: PageIndication[];
  pageIndex: number;
  totalPages: number;
  setPageIndex: (updater: Updater<number>) => void;
};

const PaginationButtonsMemo = memo(({ pages, totalPages, pageIndex, setPageIndex }: PaginationButtonMemoProps) => {
  return (
    <div className='d-flex gap-1 align-items-center flex-wrap justify-content-center ml-auto'>
      <PaginationButton text='Previous' active={false} disabled={pageIndex === 0} onClick={() => setPageIndex((p) => p - 1)} />
      {pages.map(({ page, label }, index) => (
        <PaginationButton
          text={label}
          key={index}
          active={page === pageIndex}
          disabled={page === null}
          onClick={page !== null ? () => setPageIndex(page) : undefined}
        />
      ))}
      <PaginationButton text='Next' active={false} disabled={pageIndex === totalPages - 1} onClick={() => setPageIndex((p) => p + 1)} />
    </div>
  );
});

PaginationButtonsMemo.displayName = 'PaginationButtonsMemo';

function PaginationButton({ text, active, disabled, onClick }: { text: string; active: boolean; disabled: boolean; onClick?: () => void }) {
  const backgroundColor = disabled ? '#464646' : active ? '' : '#5C5C5C';
  return (
    <Button
      style={{ backgroundColor: backgroundColor }}
      type='button'
      variant={active ? 'primary' : 'dark'}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
    >
      {text}
    </Button>
  );
}

type PageIndication = {
  page: number | null;
  label: string;
};

function makePageIndicators(total: number, currentIndex: number) {
  const list: PageIndication[] = [];
  const morePagesThanButtons = total > 7;
  const hittingBeginning = currentIndex >= 4 && morePagesThanButtons;
  const hittingEnd = total - 1 - currentIndex < 4 && morePagesThanButtons;
  list.push({ label: '1', page: 0 });
  if (total >= 2) list.push(hittingBeginning ? { label: '...', page: null } : { label: '2', page: 1 });
  if (total >= 3)
    list.push(
      hittingBeginning && !hittingEnd
        ? { label: currentIndex.toString(), page: currentIndex - 1 }
        : hittingEnd
          ? { label: (total - 4).toString(), page: total - 5 }
          : { label: '3', page: 2 }
    );
  if (total >= 4)
    list.push(
      hittingBeginning && !hittingEnd
        ? { label: (currentIndex + 1).toString(), page: currentIndex }
        : hittingEnd
          ? { label: (total - 3).toString(), page: total - 4 }
          : { label: '4', page: 3 }
    );
  if (total >= 5)
    list.push(
      hittingBeginning && !hittingEnd
        ? { label: (currentIndex + 2).toString(), page: currentIndex + 2 }
        : hittingEnd
          ? { label: (total - 2).toString(), page: total - 3 }
          : { label: '5', page: 4 }
    );
  if (total >= 6)
    list.push(
      total - currentIndex >= 4 && morePagesThanButtons && !hittingEnd
        ? { label: '...', page: null }
        : hittingEnd
          ? { label: (total - 1).toString(), page: total - 2 }
          : { label: '6', page: 5 }
    );
  if (total >= 7) list.push({ label: total.toString(), page: total - 1 });
  return list;
}
