import { type Dispatch, type RefObject, type SetStateAction, useEffect, useRef } from 'react';
import { useOnboardingGuide } from './OnboardingContext';
import { OnboardingGuideComponent } from './OnboardingGuideComponent';
import { DelayShow } from '../../components/util/DelayShow';
import { useForceUpdateWhenRefIsSet } from '../utils/useForceUpdateWhenRefIsSet';

interface Props {
  toggleRef: RefObject<HTMLElement>;
  menuRef: RefObject<HTMLDivElement>;
  setForceShowDropDown: Dispatch<SetStateAction<boolean | undefined>>;
  forceDisplay?: boolean;
  onExit: () => void;
}

export function RmxServiceModalOptionsOnboardingGuide({ toggleRef, menuRef, setForceShowDropDown, forceDisplay, onExit }: Props) {
  const { guide, addGuideInteraction } = useOnboardingGuide('RmxServiceModalOptionsOnboardingGuideV2', forceDisplay, false);
  const clicked = useRef(false);
  useEffect(() => {
    if (guide && !clicked.current) {
      clicked.current = true;
      setForceShowDropDown(true);
    }
  }, [guide, setForceShowDropDown, toggleRef]);
  // on old ios devices the ref is not set immediately. Probably due to a race condition. This hook allows this component to be rerendered when it comes available
  useForceUpdateWhenRefIsSet(menuRef);

  return !guide || !toggleRef.current || !menuRef.current ? null : (
    <DelayShow delay={500}>
      <OnboardingGuideComponent
        guide={guide}
        addGuideInteraction={addGuideInteraction}
        onExit={() => {
          setForceShowDropDown(undefined);
          onExit();
        }}
        steps={[
          {
            element: toggleRef.current,
            title: '<u>Activity Options</u>',
            intro: `
           The options for this activity have been moved into this dropdown menu
          `
          },
          {
            element: menuRef.current,
            title: '<u>Activity Options</u>',
            intro: `
            <code>Open Asset</code> only shows if the asset is monitored.
            <br/><br/>
            <code>Create Flag</code> will create a flag for this activity
            <br/><br/>
            <code>Asset History</code> will filter the Activities table to show only activities associated with this asset
            <br/><br/>
            <code>Quote History</code> will filter the Quotes table to show only quotes associated with this asset
            <br/><br/>
            <code>Help</code> will open this guide again
          `
          }
        ]}
      />
    </DelayShow>
  );
}
