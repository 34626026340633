import { type ReactNode, useEffect, useState } from 'react';


export function DelayShow({ children, delay }: { children: ReactNode; delay: number }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);
  return <>{show && children}</>;
}
