import {type ChangeEvent, type KeyboardEvent, type KeyboardEventHandler} from 'react';
// import zxcvbn from 'zxcvbn';
// disable zxcvn because it adds a large amout to the bundle size
import {type FieldInputProps} from 'formik';
import {type HandleThunkActionCreator} from 'react-redux';
import {type FormControlElement} from 'src/components/util/Controls/Form/Control/FormControl';

export function combineClasses(...arr: Array<any|string|undefined|null>): string {
  return arr.filter((val) => !!val).join(' ');
}
export function combine(...arr: Array<any|string|undefined|null>): string {
  return arr.filter((val) => !!val).join(' ');
}
export function combineAsArray<T>(...arr: Array<T|null|undefined>): T[] {
  return arr.filter((val) => !!val) as T[];
}
export function getClasses<T extends Record<string, string>, K extends keyof T>(dic: T, ...arr: K[]): string {
  return arr.map(key => dic[key])
    .filter((key) => !!dic[key])
    .join(' ');
}

export type BootstrapFormEvent = ChangeEvent<FormControlElement>;

export function nullable<T>(value: T | null): T | undefined {
  if (value) {
    return value;
  }
  return undefined;
}

export type Stringify<T> = { [K in keyof T]: string|undefined };

export function isPassValid(password: string) {
  return true;
  // const result = zxcvbn(password);
  // return result.score >= 1;
}

export const isEmailValid = (email: string) => {
  return email.length === 0 || /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
};

export function getFieldValue(value: string | null | undefined, emptyAsNull: boolean = false): string|null {
  if(emptyAsNull && value === '')
    return null;
  if (value === undefined || value === null) {
    return emptyAsNull ? null : '';
  }
  return value;
}

export function getFieldCheckValue<T>(field: FieldInputProps<T>) {
  if (field.value === undefined || field.value === null) {
    return false;
  }
  return field.value;
}

export type FormikSetFieldValue<T = string> = (field: T, value: any, shouldValidate?: boolean) => void;
export type FormikSetFieldTouched<T = string> = (field: T & string, isTouched?: boolean, shouldValidate?: boolean) => void;

export type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : (T[P]);
};

export type NonNullableObject<T> = {
  [P in keyof T]: NonNullable<T[P]>
};

export function pipeLog<T>(value: T, extra?: any): T {
  const extraParams = [];
  if (extra) {
    extraParams.push(extra);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  console.log(value, ...extraParams);
  return value;
}

export const toMutable = <T>(val: readonly T[]): T[] => [...val];

export class ImmutableArray {
  static remove<T>(arr: T[], index: number): T[] {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }
  static push<T>(arr: T[], value: T): T[] {
    return [...arr, value];
  }
}

export const nodeListToArray = <T extends HTMLElement = HTMLElement>(nodeList: NodeList | HTMLCollection) => [].slice.call(nodeList) as T[];

export type EnumValues<T> = T[keyof T];

export function getElementParentMatching(el: HTMLElement, tagName: string): HTMLElement | null {
  if (el.parentElement === null) {
    return null;
  }
  if (el.parentElement.tagName === tagName) {
    return el.parentElement;
  }
  return getElementParentMatching(el.parentElement, tagName);
}

export const goToNextElementOnEnterKeyPress = <T extends Element>(e: KeyboardEvent<T>) => {
  if (e.key === 'Enter') {
    const input = e.currentTarget as unknown as HTMLInputElement;
    const form = input.form;
    if (form === null) {
      return;
    }
    e.preventDefault();
    const index = [...form.elements].indexOf(input);
    if (index + 1 < form.elements.length) {
      (form.elements[index + 1] as HTMLInputElement).focus();
    }
  }
};

/**
 * ex. <Form.Control  onKeyDown={makePerformActionOnEnter(() => doSomeAction()} />
 * @param action
 */
export const makePerformActionOnEnter = <T extends Element>(action: (() => void) | ((e: KeyboardEvent) => void)) => ((e: KeyboardEvent<T>) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    action(e);
  }
}) as KeyboardEventHandler<T>;

export const makePerformActionOnEnterOrGoToNext = <T extends Element>(action: () => boolean) => ((e: KeyboardEvent<T>) => {
  makePerformActionOnEnter(() => action() ? goToNextElementOnEnterKeyPress(e) : null)(e);
}) as KeyboardEventHandler<T>;

export {makeGetFieldIndex} from 'src/util/field';
export {makeGetArrayPropertyFieldName} from 'src/util/field';
export {useGetFieldName} from 'src/util/field';
export {makeGetFieldName} from 'src/util/field';
export {isPrefixProps} from 'src/util/field';

export type Thunk<T> = (...args: any[]) => (...args: any[]) => T;

export function thunk<T, K extends Thunk<T>>(
  actionCreator: K
): HandleThunkActionCreator<K> {
  return actionCreator as unknown as HandleThunkActionCreator<K>;
}

const bsUnit = 8.333333335;
export const getColSize = (n: number) => (n * bsUnit) + '%';

/**
 * Compares obj1 and obj2 properties.
 * @Precondition obj1 and obj2 are a standard record object with properties.
 * @returns true if obj1 and obj2 have the same properties according to referential integrity
 * @param obj1
 * @param obj2
 */
export function shallowRecordEquals<T extends Record<string, unknown>>(obj1: T, obj2?: T) {
  const keys = Object.keys(obj1) as unknown as (keyof T)[];
  if(obj2 === undefined) {
    return false;
  }
  for (const key of keys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}
