import { Card, CardBody, CardHeader, Form } from 'react-bootstrap';
import { type Activity, type ItemInventory, type Part } from '../Service.types';
import React, { useState } from 'react';
import { RmxPartPickerModal } from '../RmxPartPickerModal/RmxPartPickerModal';
import { ReactTable, type RowData, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import { useMutation, useQuery, type UseQueryResult } from '@tanstack/react-query';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '../../shared-compat/DataTable/DataTable';
import { useRmxServiceApi } from '../api';
import { hasRole } from '../RmxServiceComponent/RmxServiceComponent';
import { getUserInformation, range } from '../../utils';
import { getApiKey } from '../../utils/ajax';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import { Map } from 'immutable';
import s from '../RmxServiceParts/RmxServiceParts.module.scss';
import { Accordion, AccordionBody, AccordionToggle } from '../../../components/util/Controls';
import { CaretIcon } from '../../../components/util/widgets/CaretIcon/CaretIcon';
import { AccordionContextConsumer } from '../../../components/util/Controls/Accordion/AccordionContextConsumer';

interface RmxServicePartsProps {
  activity: Activity;
  activityParts: Part[];
  enabled: boolean;
  setActivityParts: (parts: Part[]) => void;
  activityPartQuery: UseQueryResult<Part[], Error>;
}

export function RmxServiceParts({ activity, enabled, activityParts, activityPartQuery }: RmxServicePartsProps) {
  const [showPartDetailsCard, setShowPartDetailsCard] = useState(false);
  const [selectedQuantities, setSelectedQuantities] = useState(Map<string, number | string | null>());
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const api = useRmxServiceApi();
  const userInformation = getUserInformation(getApiKey()!);

  const { data: activityPartRows, isFetching: fetchingActivityParts, isError: errorFetchingParts, refetch: refetchActivityParts } = activityPartQuery;
  const {
    data: toteParts,
    isFetching: fetchingTechTote,
    refetch: refetchToteParts
  } = useQuery({
    queryKey: ['toteParts', activityParts, userInformation.unique_name],
    enabled: hasRole('Technician'),
    queryFn: async () => {
      return await api.getToteParts(parseInt(userInformation.unique_name));
    }
  });

  const { mutateAsync: addTotePartToActivity, isPending: pendingAddTotePartToActivity } = useMutation({
    mutationFn: async (ccn: string) => {
      await api.addTotePartToActivity(activity.id, ccn, selectedQuantities.get(ccn) as number);
      await refetchToteParts();
      await refetchActivityParts();
    }
  });

  const partColumns = [
    { header: 'Part Name', accessorKey: 'partName' },
    { header: 'CCN', accessorKey: 'ccn' },
    { header: 'Current Location', accessorKey: 'currentLocation', hidden: true },
    { header: 'Status', accessorKey: 'status' },
    { header: 'Destination', accessorKey: 'prDestination' },
    { header: 'QTY', accessorKey: 'totalQuantity' }
    // { header: 'Picked Quantity', accessorKey: 'tot', valueFormatter: (value: number) => (value ? value : '0') }
  ] satisfies SimpleColumnDef[];

  const toteColumns = [
    {
      header: 'Part Name',
      accessorKey: 'partName',
      valueFormatter: (value: string) => (
        <div className={s['tote-part-name']} style={{ overflowX: 'auto' }}>
          {value}
        </div>
      )
    },
    {
      header: 'CCN',
      accessorKey: 'ccn',
      valueFormatter: (val) => <div className={s['tote-ccn']}>{val}</div>
    },
    { header: 'Stock', accessorKey: 'quantity' },
    {
      header: 'Select Quantity',
      accessorKey: 'select',
      valueFormatter: (value: number, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }} className={s['tote-select-quantity']}>
          <Form.Control
            as='select'
            disabled={!enabled}
            size={'sm'}
            aria-label='Select number of used parts'
            value={selectedQuantities.get((row as ItemInventory).ccn) ?? 0}
            onChange={(e) => {
              setSelectedQuantities(selectedQuantities.set((row as ItemInventory).ccn, e.currentTarget.value));
            }}
          >
            {range(0, (row as ItemInventory).quantity).map((value, index) => (
              <option key={index} value={index}>
                {value}
              </option>
            ))}
          </Form.Control>
        </div>
      )
    },
    {
      header: 'Action',
      accessorKey: ' ',
      valueFormatter: (_, row) => {
        return (
          <LoadingButton
            size='sm'
            variant='secondary'
            disabled={!enabled}
            loading={pendingAddTotePartToActivity}
            onClick={async () => {
              const ccn = (row as ItemInventory).ccn;
              if (selectedQuantities.get(ccn) === undefined || selectedQuantities.get(ccn) === '0') {
                alert('Please select a quantity greater than 0');
              } else {
                await addTotePartToActivity(ccn);
              }
            }}
            label='Add'
          />
        );
      }
    }
  ] satisfies SimpleColumnDef[];

  if (errorFetchingParts) {
    alert('Error loading parts please go to a area with better internet connection and try again');
  }

  function handleRowClick(row: RowData, index: number) {
    setSelectedRowIndex(index);
    setShowPartDetailsCard(true);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <div style={{ width: '100%', minHeight: '415px' }}>
        {fetchingActivityParts ? (
          <Spinner />
        ) : activityParts && activityParts.length > 0 ? (
          <>
            <Card>
              <CardBody>
                <FontAwesomeIcon icon={faInfoCircle} /> Select part to view details
              </CardBody>
            </Card>

            <Card>
              <CardHeader style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>Parts</CardHeader>
              <ReactTable
                striped={true}
                bordered={true}
                onRowClick={enabled ? handleRowClick : undefined}
                selectedRowIndex={selectedRowIndex ?? undefined}
                includeHeaders={true}
                columns={partColumns}
                data={activityPartRows ?? []}
              />
            </Card>
          </>
        ) : activityParts.length === 0 ? (
          <Card style={{ width: '100%' }}>
            <CardBody style={{ textAlign: 'center' }}>No Parts Associated With This Activity</CardBody>
          </Card>
        ) : (
          <Spinner />
        )}

        {fetchingTechTote ? (
          <Spinner />
        ) : hasRole('Technician') ? (
          <Accordion>
            <AccordionContextConsumer>
              {({ activeEventKey }) => (
                <>
                  <AccordionToggle as='span' eventKey='tote' style={{ cursor: 'pointer' }}>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1C1C1C' }}>
                        <CaretIcon isOpen={activeEventKey === 'tote'} />
                        Tote
                      </CardHeader>
                    </div>
                  </AccordionToggle>
                  <AccordionBody eventKey='tote'>
                    <>
                      {toteParts && toteParts?.length !== 0 ? (
                        <ReactTable
                          bordered={true}
                          striped={true}
                          className='mx-auto'
                          includeHeaders={true}
                          columns={toteColumns}
                          data={toteParts.filter((part) => part.quantity !== 0)}
                        />
                      ) : (
                        <p style={{ textAlign: 'center' }}>No Parts In Tote</p>
                      )}
                    </>
                  </AccordionBody>
                </>
              )}
            </AccordionContextConsumer>
          </Accordion>
        ) : null}
        <RmxPartPickerModal
          refetch={refetchActivityParts}
          part={activityParts[selectedRowIndex as number] as Part}
          open={showPartDetailsCard}
          setOpen={setShowPartDetailsCard}
        />
      </div>
    </div>
  );
}
