import { useOnboardingGuide } from './OnboardingContext';
import { OnboardingGuideComponent } from './OnboardingGuideComponent';
import AddContact from './media/AddContact.gif';
import ContactDropDown from './media/ContactDropDownActions.gif';
import type { RefObject } from 'react';

interface Props {
  contactModalHeaderRef: RefObject<HTMLElement>;
  forceDisplay: boolean;
  onExit: () => void;
}

export function ContactOnboardingGuide({ contactModalHeaderRef, forceDisplay, onExit }: Props) {
  const { guide, addGuideInteraction } = useOnboardingGuide('ContactOnboardingGuide', forceDisplay);
  return !guide ? null : (
    <OnboardingGuideComponent
      guide={guide}
      addGuideInteraction={addGuideInteraction}
      onExit={() => onExit()}
      steps={[
        {
          element: contactModalHeaderRef.current,
          title: '<u>Contacts Menu</u>',
          intro: `
           <p style="font-size: 15px;">
           From here you can view and manage customer and work order contacts.
           <br><br>
           <em>Agreement contracts will appear if they exist, but are not editable </em>
           </p>
          `
        },
        {
          element: contactModalHeaderRef.current,
          title: '<u>Contacts Menu</u>',
          intro: `
            <p style="font-size: 15px;">
            To add a contact expand the customer contacts section and click the add contact button
            <br><br>
            <img src="${AddContact}" alt="Add Contacts" style="max-width: 100%; height: auto; display: block; margin: auto;">
            </p>
            `
        },
        {
          /*add  gif */
          element: contactModalHeaderRef.current,
          title: '<u>Contacts Menu</u>',
          intro: `
            <p style="font-size: 15px;">
            Work order contacts can be edited or <code>Removed</code> from the work order
            <br><br>
            Customer contacts can be edited, or <code>Moved</code> to a work order.
            <img src="${ContactDropDown}" alt="Contact DropDown" style="max-width: 100%; height: auto; display: block; margin: auto;">
            </p>
            `
        },
        {
          element: contactModalHeaderRef.current,
          title: '<u>Contacts Menu</u>',
          intro: `
            <p style="font-size: 15px;">
            When this menu is opened from the debrief tab, the <code>Debriefed With</code> option will appear.
            <br><br>
            Select this to add a contact to the debrief.
            </p>
            `
        }
      ]}
    />
  );
}
