import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import { ReactTable } from '../../utils/ReactTable/ReactTable';

interface IReviewHistory {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Date: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Description: string;
}
export function RmxServiceSampleReview() {
  const [text, setText] = React.useState('');
  const reviewHistoryColumns = [
    { header: 'Date', accessorKey: 'Date' },
    { header: 'Description', accessorKey: 'Description' }
  ];
  const [reviewHistory, setReviewHistory] = React.useState<IReviewHistory[]>([]);

  return (
    <div style={{ minHeight: '415px', overflowX: 'hidden', overflowY: 'hidden' }}>
      <Row>
        <Col>
          <Card>
            <Card.Header style={{ textAlign: 'center' }}>Sample Review</Card.Header>
            <Card.Body style={{ display: 'flex', justifyContent: 'center' }}>
              <Form.Control
                size={'lg'}
                style={{ width: '100%' }}
                as='textarea'
                aria-label='With textarea'
                placeholder={'Review the sample here'}
                onChange={(event) => {
                  setText(event.currentTarget.value);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className={'mb-3'}>
        <Col>
          <Button
            onClick={() => {
              setReviewHistory((currentHistory) => {
                return [
                  ...currentHistory,
                  {
                    Date: new Date().toLocaleDateString(),
                    Description: text
                  }
                ];
              });
              // setText(''); //TODO: ask on click is the expected behavior to clear the text area
            }}
            variant='secondary'
            style={{ width: '100%', display: 'block' }}
          >
            Submit
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Review History</Card.Header>
            <Card.Body style={{ overflowY: 'auto', maxHeight: '200px' }}>
              <ReactTable
                bordered={false}
                striped={false}
                includeHeaders={false}
                columns={reviewHistoryColumns}
                data={reviewHistory}
                noItemsText='No Reviews Have Been Made'
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
