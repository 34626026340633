import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { VerticalInputRow } from '../../../components/util/form-components/VerticalInputRow';
import { DayDropDown } from '../../../components/util/DayDropDown/DayDropDown';
import { GroupedSectionedSelect } from '../../utils/GroupSectionedSelect/GroupedSectionedSelect';
import { CameraOrGalleryInput } from '../../utils/CameraOrGalleryInput/CameraOrGalleryInput';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import React, { type ChangeEvent, useState } from 'react';
import { useRmxServiceApi } from '../api';
import { useMutation } from '@tanstack/react-query';
import { type DropdownOption } from 'src/portal/utils/SearchableDropdown/SearchableDropdown';
import type { CreateQuoteRequest, ItemInventory, Quote, QuotePart } from '../Service.types';
import type { TimeSpan } from '../../utils/timespan';
import { RmxServicePartShop } from './RmxServicePartShop';
import { getMapEntries, getMapValues } from '../../../common/util';
import { Map } from 'immutable';
import s from './RmxServiceQuotes.module.scss';
import { useModal } from '../../hooks/useModal';

interface Props {
  assetId: number;
  activityId: string;
  enabled: boolean;
  existingQuoteInfo?: Quote | null;
  assetGroupedOptions: { label: string; options: DropdownOption<number>[] }[];
  refetch: () => Promise<unknown>;
  onSubmit?: () => void;
}
export type IncludedPartMap = Map<string, { selectedQuantity: number; part: ItemInventory }>;

export function QuoteForm({ assetGroupedOptions, assetId, refetch, activityId, enabled, existingQuoteInfo, onSubmit }: Props) {
  const api = useRmxServiceApi();

  const partHistoryModal = useModal();
  const partLibraryModal = useModal();

  const makeIncludedPartsMap = (p: QuotePart[], includedPartMap: IncludedPartMap): IncludedPartMap =>
    Map(
      p.map((item: QuotePart) => {
        const { part, selectedQuantity } = includedPartMap.get(item.part.ccn) ?? item;
        return [part.ccn, { part: part, selectedQuantity: selectedQuantity }];
      })
    );

  const [includedPartsData, setIncludedPartsData] = useState<IncludedPartMap>(makeIncludedPartsMap(existingQuoteInfo?.includedParts ?? [], Map()) ?? Map());

  const [title, setTitle] = useState(existingQuoteInfo?.title ?? '');
  const [numberOfTechs, setNumberOfTechs] = useState<number | null>(existingQuoteInfo?.numberOfTechs ?? null);
  const [estimatedDuration, setEstimatedDuration] = useState<string>(existingQuoteInfo?.estimatedDuration ?? '00:00');
  const [dayDropdown, setDayDropdown] = useState<string>(
    existingQuoteInfo?.estimatedDuration ? parseEstimatedDurationInto8HourDays(existingQuoteInfo.estimatedDuration) : '0'
  );
  const [notes, setNotes] = useState(existingQuoteInfo?.privateNotes ?? '');
  const [selectedAsset, setSelectedAsset] = useState<number>(existingQuoteInfo?.assetId ?? assetId);
  const [fileUpload, setFileUpload] = useState<File[] | undefined>(undefined);

  const [resetFileNames, setResetFileNames] = useState(0);

  const { mutateAsync: createQuote, isPending } = useMutation({
    mutationFn: async (req: CreateQuoteRequest) => {
      await api.createQuote(req, fileUpload);
      await refetch();
    }
  });

  if (assetGroupedOptions.length === 1 && assetGroupedOptions[0].label === 'No Location') {
    assetGroupedOptions[0].label = '';
  }

  const selectedAssetOption = assetGroupedOptions.flatMap((g) => g.options).find((a) => a.value === selectedAsset);

  async function handleOnSubmit() {
    switch (true) {
      case title === '':
        alert('Please fill out the title');
        break;
      case notes === '':
        alert('Please fill out the notes');
        break;
      default:
        const quoteParts = getMapEntries(includedPartsData).map(([_, value]) => ({
          ccn: value.part.ccn,
          part: value.part,
          selectedQuantity: value.selectedQuantity
        }));
        //   Object.entries(includedPartsData).map(([, value]) => ({
        //   ccn: value,
        //   part: value.part,
        //   selectedQuantity: value.selectedQuantity
        // })) satisfies QuotePart[];
        const request = {
          id: existingQuoteInfo?.qId ?? null,
          title: title,
          activityCrmId: activityId,
          assetId: selectedAsset,
          numberOfTechs: numberOfTechs ?? 0,
          estimatedDuration: estimatedDuration as TimeSpan,
          includedParts: quoteParts,
          privateNotes: notes
        } satisfies CreateQuoteRequest;

        await createQuote(request);
        await refetch();

        onSubmit?.();
        setTitle('');
        setNumberOfTechs(null);
        setEstimatedDuration(parseEstimatedDurationInto8HourDays('00:00'));
        setDayDropdown('0');
        setNotes('');
        setResetFileNames(resetFileNames + 1);
        setFileUpload(undefined);
        setIncludedPartsData(Map());
        break;
    }
  }

  const handleTechInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    // Check if the value is within the 1-10 range; if not, adjust it
    if (value > 10) {
      setNumberOfTechs(10);
    } else if (value < 1) {
      setNumberOfTechs(null);
    } else if (!isNaN(value)) {
      setNumberOfTechs(value);
    } else {
      setNumberOfTechs(null);
    }
  };
  return (
    <>
      <Form>
        <VerticalInputRow label='Title'>
          <Form.Control maxLength={80} value={title} onChange={(e) => setTitle(e.currentTarget.value)} placeholder={'Enter a Title'} />
          <Form.Text muted={title.length < 80}>{title.length} / 80 characters</Form.Text>
        </VerticalInputRow>

        <Row className={'mb-3'}>
          <Col className='mb-3' xs={12} sm={6}>
            <Form.Label># of Techs</Form.Label>
            <Form.Control placeholder={'0'} type='number' min={0} max={10} value={numberOfTechs ?? ''} onChange={handleTechInputChange} />
          </Col>
          <Col className='mb-3'>
            <Form.Label>Estimated Duration (8 Hr Days)</Form.Label>
            <DayDropDown
              value={dayDropdown}
              onChange={(e) => {
                setDayDropdown(e);
                setEstimatedDuration(parse8HourDaysIntoEstimatedDuration(e));
              }}
            />
          </Col>
        </Row>

        <VerticalInputRow label='Confirm Asset'>
          <GroupedSectionedSelect
            value={selectedAssetOption}
            options={assetGroupedOptions}
            onChange={async (e) => {
              setSelectedAsset(e.value); // e.value is the rmx asset id
            }}
            disabled={!enabled}
          />
        </VerticalInputRow>

        <VerticalInputRow label='Included Parts'>
          <InputGroup.Text className={s['text']}>
            {' '}
            {includedPartsData.size > 0
              ? getMapValues(includedPartsData)
                  .map((value) => `${value.part.ccn} - ${value.selectedQuantity}`)
                  .join('; ')
              : 'No Parts Have Been Included'}
          </InputGroup.Text>
        </VerticalInputRow>

        <Row className={'mb-3'}>
          <Col>
            <Button disabled={!enabled} onClick={() => partLibraryModal.setIsOpen(true)} className={s['buttons']} size={'sm'} variant={'secondary'}>
              Part Library
            </Button>
          </Col>
          <Col>
            <Button disabled={!enabled} onClick={() => partHistoryModal.setIsOpen(true)} className={s['buttons']} size={'sm'} variant={'secondary'}>
              Part History
            </Button>
          </Col>
        </Row>

        <VerticalInputRow label='Upload'>
          <CameraOrGalleryInput
            defaultFileName={existingQuoteInfo?.uploadFileName ? [existingQuoteInfo?.uploadFileName] : null}
            disabled={!enabled}
            resetFileNamesTrigger={resetFileNames}
            setFiles={setFileUpload}
          />
        </VerticalInputRow>

        <VerticalInputRow label='Private Notes'>
          <Form.Control value={notes} onChange={(e) => setNotes(e.currentTarget.value)} placeholder={'Enter Notes'} as='textarea' />
        </VerticalInputRow>

        <LoadingButton
          className='mb-3 w-100'
          variant='secondary'
          disabled={!enabled}
          loading={isPending}
          onClick={async () => await handleOnSubmit()}
          label='Submit'
        />
      </Form>

      {/*There are two RmxServicePartShops to avoid data being cached between both.*/}
      {/*Part Library Part Shop*/}
      <RmxServicePartShop
        includedParts={includedPartsData}
        assetId={selectedAsset}
        activityId={activityId}
        partHistory={false}
        setIncludedPartsData={setIncludedPartsData}
        state={partLibraryModal}
      />

      {/*Part History Part Shop*/}
      <RmxServicePartShop
        includedParts={includedPartsData}
        assetId={selectedAsset}
        activityId={activityId}
        partHistory={true}
        setIncludedPartsData={setIncludedPartsData}
        state={partHistoryModal}
      />
    </>
  );
}

export function parseEstimatedDurationInto8HourDays(estimatedDuration: string): string {
  if (estimatedDuration.includes('.')) {
    // if it is in the format of 0.00:00
    const [days, time] = estimatedDuration.split('.');
    const [hours, minutes] = time.split(':');
    const daysInMinutes = parseInt(days) * 24 * 60;
    const hoursInMinutes = parseInt(hours) * 60;
    const totalMinutes = daysInMinutes + hoursInMinutes + parseInt(minutes);
    return (totalMinutes / 480).toString();
  } else {
    // if it is in the format of 00:00
    const [hours, minutes] = estimatedDuration.split(':').map((x) => parseInt(x, 10));
    const totalMinutes = hours !== 0 ? hours * 60 + minutes : minutes;
    const totalHours = totalMinutes / 480;
    return totalHours.toString();
  }
}

export function parse8HourDaysIntoEstimatedDuration(days: string): string {
  const twentyFourHourDays = Math.floor((parseFloat(days) * 8) / 24);
  if (twentyFourHourDays < 1) {
    // if it is in the format of 0.00:00
    const totalMinutes = parseFloat(days) * 480;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  } else {
    // if it is in the format of 00:00
    const totalMinutes = parseFloat(days) * 480;
    const remainingTotalMinutes = totalMinutes - twentyFourHourDays * 24 * 60;
    const remainingHours = remainingTotalMinutes / 60;
    return `${twentyFourHourDays}.${remainingHours.toString().padStart(2, '0')}:00`;
  }
}
