import React, { type CSSProperties, useState } from 'react';
import { Icon } from '../Icon/Icon';
import s from './StatusIndicator.module.scss';
import { combine } from '../../utils';
import { type ActionHandler } from '../../menu-modal/ActionModal/ActionModal';
import clsx from 'clsx';

interface Props {
  color: AlertColor;
  symbol: AlertSymbol;
  label: string;
  requestIndicatorNumber: number | null;
  onClick: ActionHandler<any>;
}

export function StatusIndicator({ color, symbol, label, requestIndicatorNumber, onClick }: Props) {
  const [, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const indicatorNumberStyle = { '--length': String(requestIndicatorNumber).length } as any as CSSProperties;
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '10px' }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={combine(s[`icon-container`], s[`indicator-${color.toLocaleLowerCase()}` as keyof typeof s])}
        onClick={onClick}
      >
        <div className='d-flex flex-row align-items-center ml-1 mr-1'>
          {symbol === 'comment-dots' && (
            <span style={indicatorNumberStyle} className={s['tile-icon-indicator']}>
              {requestIndicatorNumber}
            </span>
          )}
          <Icon icon={symbol} className={clsx(s['icon'], s[`icon-color`])} />
          <Icon icon={'ellipsis-v'} className={clsx(s['icon'], s[`icon-color`], 'ml-2')} />
        </div>
      </div>
      <span className={s['label']}>{label}</span>
    </div>
  );
}

export type StatusIndicatorProps = (
  color: AlertColor,
  symbol: AlertSymbol,
  label: string,
  requestIndicatorNumber: number | null,
  handleOnClick: ActionHandler<any>
) => void;

export type AlertColor = 'Red' | 'Orange' | 'Blue' | 'Gray';
export type AlertSymbol =
  | 'exclamation-triangle'
  | 'exclamation-circle'
  | 'info'
  | 'check'
  | 'wifi-slash'
  | 'bell-slash'
  | 'ellipsis-v'
  | 'hourglass-half'
  | 'comment-dots';
