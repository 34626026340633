import * as React from 'react';
import { type CSSProperties, type SyntheticEvent } from 'react';
import { type ButtonProps, Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

interface LoadingButtonProps {
  loading: boolean;
  label: string;
  className?: string;
  type?: ButtonProps['type'];
  variant?: ButtonProps['variant'];
  disabled?: boolean;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
}

export const LoadingButton = ({ variant, onClick, className, disabled, label, loading, type, style }: LoadingButtonProps) => (
  <Button disabled={loading || disabled} variant={variant ?? 'primary'} type={type || 'button'} className={className} onClick={onClick} style={style}>
    {loading ? <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> : label}
  </Button>
);
