import type { Column, Table } from '@tanstack/react-table';
import { type TableState, useServerOptions } from '../hooks';
import React, { useId, useMemo, useRef, useState } from 'react';
import dataTableStyles from '../DataTable.module.scss';
import { transformOr } from '../../../utils';
import clsx from 'clsx';
import { DataList } from '../../DataList';
import { DebouncedInput } from '../DebouncedInput';
import { formatTimeForUser } from '../../../utils/timeOnly';
export function TimeColumnFilter<T>(props: { column: Column<any, any>; table: Table<any>; tableState: TableState<T> }) {
  const { column } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [initialized, setInitialized] = useState(false);
  const { optionsCount, isLoading, error, sortedUniqueValues, usingServerOptions, serverOptions } = useServerOptions({ ...props, initialized });
  const columnFilterValue = column.getFilterValue() as string | undefined;
  const formattedValues = useMemo(() => {
    return sortedUniqueValues.map((value) => {
      return formatTimeForUser(value);
    });
  }, [sortedUniqueValues]);
  const dataListId = useId();
  return (
    <>
      {error && <span style={{ color: 'red' }}>Failed to retrieve options</span>}
      {serverOptions !== undefined && <DataList id={dataListId} inputRef={inputRef} options={formattedValues} openOnMount={usingServerOptions} />}
      <DebouncedInput
        onFocus={(e) => {
          if (!initialized) setInitialized(true);
        }}
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={isLoading ? `Loading options...` : `Search... ${transformOr(optionsCount, (n) => `(${n})`, '')}`}
        className={clsx('border shadow rounded', dataTableStyles['input-box'])}
        list={serverOptions !== undefined ? dataListId : undefined}
        ref={inputRef}
      />
      <div className='h-1' />
    </>
  );
}
