import React, { useState } from 'react';
import { RmxServiceDetails } from '../RmxServiceActivityDetails/RmxServiceDetails';
import s from '../RmxServiceTabs/RmxServiceTabs.module.scss';
import { type ModalState } from '../../hooks/useModal';
import { type Part } from '../Service.types';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { Tabs } from '../../utils/Tabs/Tabs';
import { PartDetails } from '../RmxServiceActivityDetails/PartDetails';

export type ModalTab = 'details';
export type RmxServicePartModalParam = { resource: Part };
type Props = {
  state: ModalState<RmxServicePartModalParam, undefined>;
};

export function RmxServicePartModal(props: Props) {
  const resource = props.state.param!.resource;
  const part = resource as Part;
  const [activeTab, setActiveTab] = useState<ModalTab | string>('details');
  return (
    <GenericModal
      open={props.state.isOpen}
      setIsOpen={props.state.setIsOpen}
      className={s['tab-modal']}
      dialogClassName={s['tab-modal-dialog']}
      contentClassName={s['tab-modal-content']}
      bodyClassName='d-flex flex-column align overflow-hidden p-0'
      label={'Part'}
      hideFooter={true}
      showClose={true}
    >
      <Tabs
        setActiveTab={setActiveTab}
        withScroll={true}
        activeTab={activeTab}
        noMargin={true}
        growContent={true}
        tabs={[
          {
            key: 'details',
            title: 'Details',
            content: (
              <RmxServiceDetails>
                <PartDetails part={part} />
              </RmxServiceDetails>
            )
          }
        ]}
      />
    </GenericModal>
  );
}
