/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Chart } from 'highcharts';

const EXPORT_WIDTH = 1000; // Exportation width
type ExportImageResult = { uri: string; width: number; height: number };
function highchartsSVGtoImage(chart: Chart, callback: (result: ExportImageResult) => unknown, errorCallback?: (error: unknown) => unknown) {
  const svg = chart.getSVG();
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  img.onload = function () {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    callback({ uri: canvas.toDataURL('image/png'), width: img.width, height: img.height });
  };
  img.onerror = function (event: Event | string) {
    if (typeof event === 'string') {
      if (errorCallback) errorCallback(new Error('App: Failed to load image for highcharts export: ' + event));
    } else {
      if (errorCallback) errorCallback(new Error('App: Failed to load image for highcharts export'));
    }
  };
  // eslint-disable-next-line deprecation/deprecation
  img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg))));
}
// added this method from original example to ease use
function highchartsSVGtoImageAsync(chart: Chart) {
  return new Promise((resolve, reject) => {
    try {
      highchartsSVGtoImage(
        chart,
        (data) => resolve(data),
        (error) => reject(error)
      );
    } catch (error) {
      reject(error);
    }
  });
}
/**
 * This method requires the highcharts_export.js file
 */
function highchartsCustomSVGtoImage(chart: Chart, callback: (result: string) => unknown) {
  if (!chart) {
    console.error('No chart given ');
  }
  const renderWidth = EXPORT_WIDTH;
  const renderHeight = (renderWidth * chart.chartHeight) / chart.chartWidth;

  const svg = chart.getSVG({
    exporting: {
      sourceWidth: chart.chartWidth,
      sourceHeight: chart.chartHeight
    }
  });

  const canvas = document.createElement('canvas');
  canvas.height = renderHeight;
  canvas.width = renderWidth;
  const image = new Image();
  // eslint-disable-next-line deprecation/deprecation
  image.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)));
  console.log(image);
  image.addEventListener(
    'load',
    () => {
      console.log(chart);
      canvas.getContext('2d')!.drawImage(image, 0, 0, renderWidth, renderHeight);
      callback(canvas.toDataURL('image/png'));
    },
    false
  );

  image.src = 'data:image/svg+xml;base64,' + window.btoa(svg);
}

/**
 * This method requires the highcharts_export.js file
 */
function nativeSVGtoImage(element: HTMLElement, callback: (data: string) => unknown, format: 'jpeg' | 'jpg' | 'png') {
  if (!element.nodeName) {
    throw new Error("A DOM object of type SVG is required. Get with document.getElementById or a jQuery selector $(container).find('svg')[0]");
  }

  const svgData = new XMLSerializer().serializeToString(element);
  const canvas = document.createElement('canvas');
  canvas.width = element.getBoundingClientRect().width;
  canvas.height = element.getBoundingClientRect().height;
  const ctx = canvas.getContext('2d')!;
  const img = document.createElement('img');
  // eslint-disable-next-line deprecation/deprecation
  img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData))));
  img.onload = function () {
    ctx.drawImage(img, 0, 0);

    if (format === 'jpeg' || format === 'jpg') {
      callback(canvas.toDataURL('image/jpeg'));
    } else {
      callback(canvas.toDataURL('image/png'));
    }
  };
  return true;
}

const highchartsExport = {
  highchartsSVGtoImage,
  highchartsSVGtoImageAsync,
  highchartsCustomSVGtoImage,
  nativeSVGtoImage
};

// @ts-ignore
window.highchartsExport = highchartsExport;
