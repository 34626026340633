/* eslint-disable */
import { MenuConfig } from '../menu-modal/MenuModal/MenuModal';
import { createStandardAxios } from '../utils/ajax';
import { redirectToAsset } from './RmxServiceTabs/redirectToAsset';

const api = createStandardAxios();

export async function openPortalServiceMenu(extra: Partial<MenuConfig<number>>, assetId?: number, assetName?: string) {
  // these are required to be defined or else bypasses will not work
  // @ts-ignore
  window.orgId = assetId;
  // @ts-ignore
  window.orgType = 'Asset';

  let menuConfig: MenuConfig<number>;
  if (!assetId || !assetName) {
    menuConfig = {
      getLabel: () => 'Details',
      ...extra
    };
  } else {
    menuConfig = {
      open_asset: async () => {
        await redirectToAsset(assetId, assetName);
      },
      getLabel: () => 'Details',
      ...extra
    } satisfies MenuConfig<number>;
  }

  window.OpenMenuModal(assetName, menuConfig);
}
