import 'vite/modulepreload-polyfill';
// tslint:disable-next-line:no-import-side-effect
import './shared-compat/DataTable/polyfills';
// tslint:disable-next-line:no-import-side-effect
import './component-life';
// tslint:disable-next-line:no-import-side-effect
import './menu-modal';
// tslint:disable-next-line:no-import-side-effect
import './status-indicator';
import { configureFontAwesome } from './font-awesome-config';
// tslint:disable-next-line:no-import-side-effect
import './rmx-service';
// tslint:disable-next-line:no-import-side-effect
import './charting';
// import './datatable-example'; // comment this line out before committing as it causes a large bundle size
// tslint:disable-next-line:no-import-side-effect
import './form/FormBuilder/bootstrap';
// tslint:disable-next-line:no-import-side-effect
import './form/FormRenderer/bootstrap';
// tslint:disable-next-line:no-import-side-effect
import './menu-modal/MenuModal/portal-companySite-context';
// tslint:disable-next-line:no-import-side-effect
import './menu-modal/MenuModal/portal-installation-context';
// tslint:disable-next-line:no-import-side-effect
import './menu-modal/MenuModal/portal-asset-context';
import { configurePortalSentry } from './sentry-configuration';

configurePortalSentry();
configureFontAwesome();

/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    UniversalErrorMessage: string;
    User: PortalUser;
    performShowByPass: (title: string) => void;
    performAddBypass: (title: string, data?: any) => void;
    SentryDSN: string;
    PageParams: PageParams;
  }
  interface PortalUser {
    IsGodModePortal: boolean;
    IsRmxServiceEnabled: boolean;
    Email: string;
    Name: string;
  }

  interface PageParams {
    InstallationName?: string;
    CompanyId?: string;
    SiteId?: string;
    AssetUuid?: string;
    ParentAssetUuid?: string | null;
  }
}
