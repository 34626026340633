import type { Part } from '../Service.types';
import React, { useState } from 'react';
import { FieldRow } from './field-table';

export function PartDetails({ part: partProp }: { part: Part; onChange?: (value: any) => void }) {
  const [part, setPart] = useState<Part>(partProp);

  function onAttributeSave(name: keyof Part, value: unknown) {
    setPart({
      ...part,
      [name]: value
    });
  }

  return (
    <>
      <FieldRow<Part> name={'ccn'} header={'CCN'} value={part.ccn} onSave={onAttributeSave} />
      <FieldRow<Part> name={'partName'} header={'Part Name'} value={part.partName} onSave={onAttributeSave} />
      <FieldRow<Part> name={'totalQuantity'} header={'Issued Quantity'} value={part.totalQuantity} onSave={onAttributeSave} />
      <FieldRow<Part> name={'status'} header={'Status'} value={part.status} onSave={onAttributeSave} />
      <FieldRow<Part> name={'orderedOn'} header={'Ordered Date'} value={part.orderedOn} onSave={onAttributeSave} />
      <FieldRow<Part> name={'expectedOn'} header={'Expected Date'} value={part.expectedOn && part.expectedOn?.toDateString()} onSave={onAttributeSave} />
      <FieldRow<Part> name={'prDestination'} header={'PR Destination'} value={part.prDestination} onSave={onAttributeSave} />
      <FieldRow<Part> name={'poDestination'} header={'PO Destination'} value={part.poDestination} onSave={onAttributeSave} />
      <FieldRow<Part> name={'currentLocation'} header={'Current Location'} value={part.currentLocation} onSave={onAttributeSave} />
      <FieldRow<Part> name={'trackingNumber'} header={'Tracking'} value={part.trackingNumber} onSave={onAttributeSave} />
      <FieldRow<Part> name={'activityId'} header={'Activity ID'} value={part.activityCrmId} onSave={onAttributeSave} />
      <FieldRow<Part> name={'vendorName'} header={'Vendor'} value={part.vendorName} onSave={onAttributeSave} />
    </>
  );
}
