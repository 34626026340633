import type { ModalState } from '../../../hooks/useModal';
import { GenericModal } from '../../GenericModal/GenericModal';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { VerticalInputRow } from '../../../../components/util/form-components/VerticalInputRow';
import { LoadingButton } from '../../../../components/util/widgets/LoadingButton/LoadingButton';
import s from './ContactFormModal.module.scss';
import { type ContactInfo } from 'src/portal/rmx-service/Service.types';

interface Props {
  state: ModalState<ContactInfo | undefined, undefined>;
  customerContacts: ContactInfo[];
  upsertContact: (contact: ContactInfo) => Promise<void>;
  isLoading: boolean;
}

export function ContactFormModal({ state, customerContacts, upsertContact, isLoading }: Props) {
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    id: state.param?.id ?? null,
    contactName: state.param?.contactName ?? null,
    contactEmail: state.param?.contactEmail ?? null,
    contactPhone: state.param?.contactPhone ?? null
  });

  const [errors, setErrors] = useState<{ contactName: string | null; contactEmail: string | null; contactPhone: string | null }>({
    contactName: null,
    contactEmail: null,
    contactPhone: null
  });

  // Validation functions
  const validateName = (name: string | null) => (name ? '' : "Name can't be empty.");
  const validateEmail = (email: string | null) => (email ? (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? '' : 'Invalid email format.') : "Email can't be empty.");
  const validatePhone = (phone: string | null) =>
    phone ? (/^(\+\d{1,2}\s)?\(?\d{3}\)?[-]?\d{3}[-]?\d{4}$/.test(phone) ? '' : 'Phone number is invalid.') : "Phone number can't be empty.";

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      contactName: contactInfo.contactName ? validateName(contactInfo.contactName) : null,
      contactEmail: contactInfo.contactEmail ? validateEmail(contactInfo.contactEmail) : null,
      contactPhone: contactInfo.contactPhone ? validatePhone(contactInfo.contactPhone) : null
    }));
  }, [contactInfo.contactEmail, contactInfo.contactName, contactInfo.contactPhone]);

  const handleAddContact = async () => {
    const hasEmptyFields = !contactInfo.contactName || !contactInfo.contactEmail || !contactInfo.contactPhone;
    const hasErrors = errors.contactName || errors.contactEmail || errors.contactPhone;

    if (hasEmptyFields || hasErrors) {
      setErrors((prev) => ({
        ...prev,
        contactName: validateName(contactInfo.contactName),
        contactEmail: validateEmail(contactInfo.contactEmail),
        contactPhone: validatePhone(contactInfo.contactPhone)
      }));
      return;
    }

    // check if contact name exists in the customer and that the contact is not currently being edited
    const contactExists = customerContacts?.some(
      (c) =>
        (contactInfo.contactName === c.contactName || contactInfo.contactEmail === c.contactEmail || contactInfo.contactPhone === c.contactPhone) &&
        c.id !== contactInfo.id
    );

    if (contactExists) {
      alert('There is a contact that already exists with the same name, email or phone number.');
      return;
    }

    try {
      await upsertContact(contactInfo);
      setContactInfo({ contactName: null, contactEmail: null, contactPhone: null });
      state.close();
    } catch (e) {
      alert('Error adding contact');
    }
  };

  return (
    <GenericModal
      hideFooter={true}
      dialogClassName={s['contact-form-modal-dialog']}
      contentClassName={s['contact-form-modal-content']}
      label='Add Contact'
      showClose={true}
      state={state}
      onExit={() => {
        setContactInfo({ contactName: null, contactEmail: null, contactPhone: null });
        setErrors({ contactName: null, contactEmail: null, contactPhone: null });
      }}
    >
      <VerticalInputRow label={'Name'}>
        <Form.Control
          value={contactInfo.contactName === null ? '' : contactInfo.contactName}
          onChange={(e) => {
            setContactInfo((prev) => ({ ...prev, contactName: e.target.value }));
          }}
          disabled={state.param?.id !== undefined} // Disable name field if the contact is already in our database
          placeholder='Contact Name'
          size={'sm'}
          isInvalid={!!errors.contactName}
        />
        <Form.Control.Feedback type='invalid'>{errors.contactName}</Form.Control.Feedback>
      </VerticalInputRow>
      <VerticalInputRow label={'Email'}>
        <Form.Control
          value={contactInfo.contactEmail === null ? '' : contactInfo.contactEmail}
          onChange={(e) => {
            setContactInfo((prev) => ({ ...prev, contactEmail: e.target.value }));
          }}
          placeholder='Email'
          size={'sm'}
          isInvalid={!!errors.contactEmail}
        />
        <Form.Control.Feedback type='invalid'>{errors.contactEmail}</Form.Control.Feedback>
      </VerticalInputRow>
      <VerticalInputRow label={'Phone'}>
        <Form.Control
          value={contactInfo.contactPhone === null ? '' : contactInfo.contactPhone}
          onChange={(e) => {
            setContactInfo((prev) => ({ ...prev, contactPhone: e.target.value }));
          }}
          placeholder='Phone Number'
          size={'sm'}
          isInvalid={!!errors.contactPhone}
        />
        <Form.Control.Feedback type='invalid'>{errors.contactPhone}</Form.Control.Feedback>
      </VerticalInputRow>

      <VerticalInputRow>
        <LoadingButton loading={isLoading} label={'Add'} className={s['add-button']} size={'sm'} variant={'secondary'} onClick={handleAddContact} />
      </VerticalInputRow>
    </GenericModal>
  );
}
