import ReactDOM from 'react-dom/client';
import React from 'react';
import { RmxServiceComponent } from './RmxServiceComponent/RmxServiceComponent';
import { getPortalServiceApi } from './api/portal-api';
import { ContextProvider, createContextWithValue } from '../utils';
import { RmxServiceMenuProvider } from './RmxServiceMenuContext';
import { openPortalServiceMenu } from './portal-context';
import { RmxServiceApiProvider } from './api';
import { QueryClientProvider } from '@tanstack/react-query';
import { createStandardQueryClient } from '../utils/react-query/client';
import { BootStrapContextProvider } from '../../hooks/bootstrap-context';
import { useListenToPortalTabsClick } from '../portal-tabs-hook';
import { OnboardingContextProvider } from '../onboardings/OnboardingContext';
import { GlobalConfirmDialogProvider } from '../utils/GlobalConfirmDialog';

const queryClient = createStandardQueryClient();

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BootstrapRmxService: (targetId: string) => void;
  }
}
const portalApi = getPortalServiceApi();

export function RmxServiceContainer() {
  const tabBtn = document.querySelector('#tab_rmx_service_btn');
  const isVisible = tabBtn?.classList.contains('active');
  useListenToPortalTabsClick();
  if (!isVisible) {
    return null;
  }
  const contexts = [createContextWithValue(RmxServiceApiProvider, portalApi), createContextWithValue(RmxServiceMenuProvider, openPortalServiceMenu)];
  return (
    <React.StrictMode>
      <BootStrapContextProvider version={4}>
        <QueryClientProvider client={queryClient}>
          <ContextProvider contexts={contexts}>
            <GlobalConfirmDialogProvider>
              <OnboardingContextProvider>
                <RmxServiceComponent />
              </OnboardingContextProvider>
            </GlobalConfirmDialogProvider>
          </ContextProvider>
        </QueryClientProvider>
      </BootStrapContextProvider>
    </React.StrictMode>
  );
}

window.BootstrapRmxService = (targetId: string) => {
  const root = ReactDOM.createRoot(document.getElementById(targetId) as HTMLElement);
  root.render(<RmxServiceContainer />);
};
