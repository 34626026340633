import React, { type RefObject } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from 'src/components/util/Controls/Button/Button';

interface Props {
  formRef: RefObject<HTMLIFrameElement>;
  formName: string;
}
export function FormPDFExporter({ formRef, formName }: Props) {
  const downloadPdfDocument = () => {
    if (!formRef.current) return;
    const iframe = formRef.current;
    const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
    if (iframeDocument) {
      html2canvas(iframeDocument.body)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt'
          });
          // Calculate the dimensions to fit the pdf page
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save(formName + '.pdf');
        })
        .catch((err) => {
          console.error('Error capturing iframe content:', err);
        });
    }
  };

  return (
    <div>
      <Button style={{ width: '100%' }} onClick={downloadPdfDocument}>
        Download as PDF
      </Button>
    </div>
  );
}
