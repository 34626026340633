import s from './DarkSelect.module.scss';
import { Form } from 'react-bootstrap';
import React from 'react';
import clsx from 'clsx';

type ValidOptions = string | number;

type DarkSelectProps<T extends ValidOptions> = {
  options: { value: T; label: string }[] | T[];
  value: T;
  style?: React.CSSProperties;
  className?: string;
  onChange: (value: T) => void;
  enabled?: boolean;
  innerRef?: React.Ref<HTMLSelectElement>;
};
export function DarkSelect<T extends ValidOptions>({ enabled, value, options, onChange, style, className, innerRef }: DarkSelectProps<T>) {
  const isNumber = typeof value === 'number' || typeof options[0] === 'number';
  return (
    <Form.Select
      ref={innerRef}
      className={clsx(s['form-select'], className)}
      disabled={enabled !== undefined ? !enabled : false}
      style={style}
      value={value}
      onChange={(e) => {
        const v = isNumber ? (Number(e.target.value) as T) : (e.target.value as T);
        onChange(v);
      }}
    >
      {options.map((o, index) => {
        return isOptionObject(o) ? (
          <option value={o.value} key={index}>
            {o.label}
          </option>
        ) : (
          <option value={o} key={index}>
            {o}
          </option>
        );
      })}
    </Form.Select>
  );
}

export default DarkSelect;

function isOptionObject<T>(o: T | { value: T; label: string }): o is { value: T; label: string } {
  return typeof o === 'object' && o && 'value' in o && 'label' in o;
}
