import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { type ComponentLifeModel } from './component-life-types';
import { type QueryClient, useQueryClient } from '@tanstack/react-query';
import { type ComponentId, type ComponentLifeParsedResponse } from './api';
import { arrayToRecord } from '../utils';

type ComponentLifeData = {
  response: ComponentLifeParsedResponse | null;
  dictionary: Record<ComponentId, ComponentLifeModel>;
  queryClient: QueryClient;
  queryKey: readonly unknown[];
};
type ContextData = { data: ComponentLifeData | null; setData: Dispatch<SetStateAction<ComponentLifeData | null>> };
const ComponentLifeDataContext = createContext<ContextData | null>(null);

export const ComponentLifeDataProvider = (props: PropsWithChildren) => {
  const [data, setData] = useState<ComponentLifeData | null>(null);
  const context = useMemo<ContextData>(() => ({ data, setData }), [data, setData]);
  return <ComponentLifeDataContext.Provider value={context}>{props.children}</ComponentLifeDataContext.Provider>;
};

export function useSyncComponentLifeContext(data: ComponentLifeParsedResponse | undefined, queryClient: QueryClient, queryKey: readonly unknown[]) {
  const context = useContext(ComponentLifeDataContext);
  if (!context) {
    throw new Error('useComponentLifeData must be used within a ComponentLifeDataProvider');
  }
  const queryKeyRef = useRef(queryKey);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serializedQueryKey = useMemo(() => JSON.stringify(queryKey), [...queryKey]);
  const setData = context.setData;
  useEffect(() => {
    setData((oldData) => {
      const newData = data ? data : oldData?.response ?? null;
      return { response: newData, dictionary: newData ? convertToRecord(newData) : {}, queryKey: queryKeyRef.current, queryClient: queryClient };
    });
  }, [setData, data, queryClient, serializedQueryKey]);
  if (data !== context.data?.response || !context.data?.response) {
    return data;
  }
  return context.data.response;
}

export const useComponentLifeData = () => {
  const queryClient = useQueryClient();
  const context = useContext(ComponentLifeDataContext);
  if (!context) {
    throw new Error('useComponentLifeData must be used within a ComponentLifeDataProvider');
  }

  const queryKey = context.data?.queryKey;
  const setData = context.setData;
  const data = context.data;
  const dictionary = data?.dictionary;
  return {
    getByComponentId: useCallback((componentId: number) => (dictionary ? dictionary[componentId] ?? null : null), [dictionary]),
    updateComponentLifeRow: useCallback(
      (row: ComponentLifeModel) => {
        // trigger the asset page in portal to refresh and pull in the updates if it exists
        if (window.refreshAssetData) {
          window.refreshAssetData();
        }
        // update the ReactQuery cache
        // also update the context cache
        queryClient.setQueryData(queryKey!, (oldData: ComponentLifeParsedResponse) => {
          const newData = {
            ...oldData,
            Components: oldData!.Components.map((c) => (c.ComponentId === row.ComponentId ? row : c))
          };
          setData((prev) => ({ ...prev!, response: newData, dictionary: convertToRecord(newData) }));
          return newData;
        });
      },
      [queryClient, queryKey, setData]
    ),

    removeComponentLifeRow: useCallback(
      (componentId: ComponentId) => {
        // trigger the asset page in portal to refresh and pull in the updates if it exists
        if (window.refreshAssetData) {
          window.refreshAssetData();
        }
        // update the ReactQuery cache
        // also update the context cache
        queryClient.setQueryData(queryKey!, (oldData: ComponentLifeParsedResponse) => {
          const newData = {
            ...oldData,
            Components: oldData!.Components.filter((c) => c.ComponentId !== componentId)
          };
          setData((prev) => ({ ...prev!, response: newData, dictionary: convertToRecord(newData) }));
          return newData;
        });
      },
      [queryClient, queryKey, setData]
    )
  };
};

function convertToRecord(resp: ComponentLifeParsedResponse) {
  return arrayToRecord(
    resp.Components,
    (r) => r.ComponentId,
    (r) => r
  );
}

export const getComponentLifeQueryKey = (assetId: number) => ['component-life-data', assetId];
