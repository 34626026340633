import { Form } from 'react-bootstrap';

interface Props {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  dayFormatter?: (day: number) => string;
}

export function DayDropDown({value, disabled, onChange, dayFormatter}: Props) {
  const days: number[] = [0,0.25, 0.50, 0.75];
  for (let i = 1; i <= 20; i+=0.5) {
    days.push(i);
  }
  return (
    <Form.Group>
      <Form.Select
        style={{
          font: '12px',
          width: '100%',
          height: '34px',
          borderRadius: '3px',
          border: '1px solid transparent',
          padding: '6px 12px',
          fontSize: '12px',
          background: '#666666',
          outline: 'none',
          color: '#fff'
        }}
        disabled={disabled}
        value={value}
        onChange={ (event) => {
          onChange(event.target.value);
        }}
      >
        {days.map((day) => (
          <option key={day} value={day}>
            {dayFormatter ? dayFormatter(day): day}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

