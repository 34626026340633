import { Form } from 'react-bootstrap';
import s from './ReactFormSelect.module.scss';
import React, { type ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  defaultValue?: string;
  size?: 'sm' | 'lg' | undefined;
  disabled?: boolean;
  style?: React.CSSProperties;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
export function ReactFormSelect({ style, defaultValue, children, size, value, onChange }: Props) {
  return (
    <Form.Select
      style={style}
      size={size}
      className={s['form-select']}
      defaultValue={defaultValue} // Ensure the value is treated as a string for the select
      onChange={onChange}
      value={value}
    >
      {children}
    </Form.Select>
  );
}
