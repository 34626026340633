import { useForceUpdate } from './hooks/useForceUpdate';
import { useEffect } from 'react';

export function useListenToPortalTabsClick(delay = 500) {
  //delay is required for it to wait for the new active tab to have the active class on it
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    function handler() {
      if (delay === 0) {
        forceUpdate();
        return;
      }
      setTimeout(() => forceUpdate(), delay);
    }
    const items = document.querySelectorAll('.tiles-panel-heading .nav-items .nav-link');
    items.forEach((item) => item.addEventListener('click', handler));
    return () => items.forEach((item) => item.removeEventListener('click', handler));
  }, [forceUpdate, delay]);
}
