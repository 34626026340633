import React, {type CSSProperties, type FunctionComponent, type PropsWithChildren, type ReactNode} from 'react';
import {FormGroup, FormLabel} from 'src/components/util/Controls';

export type InputRowProps = PropsWithChildren<{
  label?: string;
  hidden?: boolean;
  style?: CSSProperties;
  hintText?: string | ReactNode;
  className?: string;
}>;

export const VerticalInputRow: FunctionComponent<InputRowProps> = ({className, hidden, label, children, style, hintText}) => {
  return (
    <FormGroup hidden={hidden} style={style} className={className}>
      {!!label && <FormLabel>{label}</FormLabel>}
       {children}
      {!!hintText &&
        <small className="form-text text-muted">
         {hintText}
        </small>
      }
    </FormGroup>
  );
};
