import { useModal } from '../hooks/useModal';
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';

export type ConfirmParams<T> = {
  title: string;
  message: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  onConfirm?: () => void;
  confirmActionParameter?: T;
};
export let OpenConfirmDialog: <T>(params: ConfirmParams<T>) => Promise<unknown> = () => Promise.resolve(undefined);
export function ConfirmDialog<T>() {
  const modal = useModal<ConfirmParams<T>, undefined>();
  OpenConfirmDialog = async <TParam,>(params: ConfirmParams<TParam>) => {
    await modal.open(params as unknown as ConfirmParams<T>);
  };
  return (
    <>
      {modal.isOpen && (
        <ConfirmationDialog
          title={modal.param!.title}
          open={modal.isOpen}
          onAccept={() => {
            modal.close();
            modal.param!.onConfirm?.();
          }}
          onDecline={modal.close}
          prompt={modal.param!.message}
          positiveText={modal.param!.confirmButtonTitle}
          negativeText={modal.param!.cancelButtonTitle}
        />
      )}
    </>
  );
  // return modal.isOpen && <Modal state={modal} title={modal.param.title} noPadding={true}>
  //   <h4 style={{textAlign: 'center', maxWidth: 400, padding: '0.5em 1em'}}> {modal.param.message}</h4>
  //   <div className="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
  //     <div className='ui-dialog-buttonset'>
  //       <button type='button' className='ui-button ui-corner-all ui-widget' onClick={() => {modal.close(); modal.param.onConfirm();}}>{modal.param.confirmButtonTitle ?? 'Confirm'}</button>
  //       <button type='button' className='ui-button ui-corner-all ui-widget' onClick={modal.close}>{modal.param.cancelButtonTitle ?? 'Cancel'}</button>
  //     </div>
  //   </div>
  // </Modal>
}
