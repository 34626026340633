import React, { createContext, type PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import type { AddInteractionRequest, UserOnboardingInteraction } from '../rmx-service/Service.types';
import { type RefetchOptions, type UseMutateAsyncFunction, useMutation, useQuery } from '@tanstack/react-query';
import { useRmxServiceApi } from '../rmx-service/api';
import { arrayToMap } from '../../common/util';

type OnboardingContextValueType = {
  onBoardingTitleValueMap: Map<string, UserOnboardingInteraction> | undefined;
  refetchExistingGuides: (options?: RefetchOptions | undefined) => Promise<unknown>;
  getGuideByTitle: (title: string) => UserOnboardingInteraction | null | undefined;
  addGuideInteraction: UseMutateAsyncFunction<void, Error, AddInteractionRequest, unknown>;
};

const OnboardingContext = createContext<OnboardingContextValueType | null>(null);

export function useOnboardingContext() {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboardingContext must be used within a OnboardingContextProvider');
  }
  return context;
}

/**
 * Gets the guide and add interaction handler for the given title. If the guide has never been interacted with it will be displayed.
 * @param title
 * @param forceDisplay if true the guide will be displayed even if it has been completed
 * @param showOnFirstInteraction if set to false the guide will not be displayed on the first interaction
 */
export function useOnboardingGuide(title: string, forceDisplay = false, showOnFirstInteraction = true) {
  const { getGuideByTitle, addGuideInteraction } = useOnboardingContext();
  const guide = getGuideByTitle(title);
  const displayGuide = forceDisplay || (guide?.interactionId === null && showOnFirstInteraction);
  return { guide: displayGuide ? guide : null, addGuideInteraction };
}

export function OnboardingContextProvider({ children }: PropsWithChildren) {
  const api = useRmxServiceApi();
  const { data: guidesAndInteractions, refetch } = useQuery({
    queryKey: ['onboardingGuides'],
    queryFn: async () => {
      return await api.getUserOnboardGuideInfo();
    }
  });

  const guidesAndInteractionsByTitle = useMemo(
    () =>
      guidesAndInteractions
        ? arrayToMap(
            guidesAndInteractions.filter((g) => g.isActive) ?? [],
            (i) => i.title,
            (i) => i
          )
        : undefined,
    [guidesAndInteractions]
  );

  const { mutateAsync: addGuideInteraction } = useMutation({
    mutationFn: async (request: AddInteractionRequest) => {
      await api.addOnboardingInteraction(request);
      await refetch();
    }
  });

  const getGuideByTitle = useCallback(
    (title: string) => (guidesAndInteractionsByTitle ? guidesAndInteractionsByTitle.get(title) ?? null : undefined),
    [guidesAndInteractionsByTitle]
  );

  const contextValue = useMemo(
    () => ({ onBoardingTitleValueMap: guidesAndInteractionsByTitle, refetchExistingGuides: refetch, getGuideByTitle, addGuideInteraction }),
    [addGuideInteraction, getGuideByTitle, guidesAndInteractionsByTitle, refetch]
  );
  return <OnboardingContext.Provider value={contextValue}>{children}</OnboardingContext.Provider>;
}
