import type { Sample } from '../Service.types';
import { FieldRow } from './field-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export function SampleDetails({ sample }: { sample: Sample; onChange?: (value: any) => void }) {
  function onAttributeSave(name: keyof Sample, value: any) {
    console.log(name, value);
  }

  const issuesString = sample.issues?.toUpperCase() ?? undefined;
  const issuesArray = issuesString?.split('; ');

  const fieldNames = [
    'assetId',
    'siteId',
    'loadHours',
    'bearingMetals',
    'coolerMetals',
    'oxidation',
    'filter',
    'ph',
    'viscosityDeviations',
    'viscosity',
    'sampleDate'
  ] as const satisfies (keyof Sample & string)[];

  return (
    <>
      {fieldNames.map((name, index) => {
        const isIssue = issuesArray?.includes(name.toUpperCase());
        return (
          <FieldRow<Sample>
            key={index}
            name={name}
            header={name.charAt(0).toUpperCase() + name.slice(1)}
            value={sample[name]}
            onSave={onAttributeSave}
            extraContent={<td>{isIssue && <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red', marginLeft: '4px' }} />}</td>}
          />
        );
      })}
    </>
  );
}
