import { handleAxiosError } from '../../utils/http';
import { getAssetUuidFromAssetId } from '../../portal-api';

export async function redirectToAsset(assetId: number, assetName: string) {
  try {
    // const companyAndSiteId = await getCompanyAndSiteId(assetId);
    // const companyId = companyAndSiteId.companyUuid;
    // const siteId = companyAndSiteId.siteUuid;
    // console.log(`id: ${assetId}, type: ${'Asset'}, assetName: ${assetName}`);
    // const companyName = await getCompanyNameAsync(companyId);
    // const siteName = await getSiteNameAsync(siteId);
    // const installationName = await getInstallationName(companyName, siteName);
    const assetUuid = await getAssetUuidFromAssetId(assetId);
    window.location.href = `${window.origin}?p=AssetPage&pid=${assetUuid}`;
  } catch (ex) {
    alert(handleAxiosError(ex));
  }
}
