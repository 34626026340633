import { addAssetTypeComponent } from './api';
import { GenericModal } from '../utils/GenericModal/GenericModal';
import { type ModalState, useModal } from '../hooks/useModal';
import { useState } from 'react';
import { capitalizeEachWord, numberOrNull } from '../utils';
import { type AssetTypeComponent } from './component-life-types';
import { handleAxiosError } from '../utils/http';

export let openAddAssetTypeComponentDialog: (assetId: number) => Promise<AssetTypeComponent | undefined> = () => {
  throw new Error('openAddAssetTypeComponentDialog is not set yet');
};
// AddAssetTypeComponentDialog
export function AddAssetTypeComponentDialogComponentContainer() {
  const modal = useModal<{ assetId: number }, AssetTypeComponent>();
  openAddAssetTypeComponentDialog = (assetId) => modal.open({ assetId });
  return <>{modal.isOpen && <AddAssetTypeComponentDialogComponent state={modal} />}</>;
}
export function AddAssetTypeComponentDialogComponent({ state }: { state: ModalState<{ assetId: number }, AssetTypeComponent> }) {
  const assetId = state.param!.assetId;
  const [name, setName] = useState('');
  const [fullLoadHours, setFullLoadHours] = useState<number | null>(null);
  const [submitting, setSubmitting] = useState(false);
  async function submitAddAssetTypeComponent() {
    const submittedName = capitalizeEachWord(name.trim());
    if (submittedName.length < 3) {
      alert('Please enter a name with at least 3 characters');
      return;
    }
    if (fullLoadHours === null || isNaN(fullLoadHours) || fullLoadHours <= 0) {
      alert('Please enter a valid full load hours');
      return;
    }
    try {
      setSubmitting(true);
      const result = await addAssetTypeComponent(submittedName, assetId, fullLoadHours);
      state.close(result);
      return result;
    } catch (error) {
      alert(handleAxiosError(error, { serverError: 'Failed to add component' }));
      throw error;
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <GenericModal open={true} label='Add Component to library' hideFooter={true}>
      <div className='form-group'>
        <label htmlFor='dialog-add-component-type__name'>Name</label>
        <input className='form-control' type='text' value={name} onChange={(e) => setName(e.currentTarget.value)} />
      </div>
      <div className='form-group'>
        <label htmlFor='dialog-add-component-type__full-load-hours'>Full load Hours</label>
        <input className='form-control' type='number' value={fullLoadHours ?? ''} onChange={(e) => setFullLoadHours(numberOrNull(e.currentTarget.value))} />
      </div>
      <div className='row mt-2'>
        <div className='col-6'>
          <button onClick={() => state.close()} className='btn-warning btn btn-small ml-auto text-white'>
            Cancel
          </button>
        </div>
        <div className='col-6 d-flex'>
          <button disabled={submitting} className='btn-primary btn btn-small ml-auto text-white' onClick={submitAddAssetTypeComponent}>
            {submitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </GenericModal>
  );
}
