import {Card, useAccordionButton} from 'react-bootstrap';
import React, {type HTMLAttributes} from 'react';
import {type AccordionCollapseProps} from 'react-bootstrap/AccordionCollapse';

export type BootStrapAccordionToggleProps = Omit<AccordionCollapseProps & HTMLAttributes<HTMLDivElement>, 'children'> & {children?: any};
export const BootstrapAccordionToggle = (props: BootStrapAccordionToggleProps) => {
  const {eventKey, ...remainingProps} = props;
  const AsComponent = props.as ?? Card.Header;
  const decoratedOnClick = useAccordionButton(eventKey, props.onClick);
  return <AsComponent {...remainingProps} onClick={decoratedOnClick}>
      {props.children}
  </AsComponent>;
};
