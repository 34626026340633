import { Card } from 'react-bootstrap';
import React, { useState } from 'react';
import { type AssetWithDetailsResponse, getAssetDetailsLabel, type Part, type Quote } from '../Service.types';
import { type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import { Spinner } from '../../shared-compat/DataTable/DataTable';
import { convertToDropDownOptionByPredicate, getMapEntries, groupBy } from '../../../common/util';
import { DropdownTable } from '../../utils/DropdownTable/DropdownTable';
import { QuoteForm, parseEstimatedDurationInto8HourDays } from './QuoteForm';
import { useMutation, type UseQueryResult } from '@tanstack/react-query';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { useModal } from '../../hooks/useModal';
import { useRmxServiceApi } from '../api';

interface Props {
  partLibrary: Part[] | undefined;
  assetId: number;
  activityId: string;
  enabled: boolean;
  quoteHistoryQuery: UseQueryResult<Quote[], Error>;
  assetOptions: AssetWithDetailsResponse[];
}

export function RmxServiceQuotes({ assetOptions, assetId, activityId, enabled, quoteHistoryQuery }: Props) {
  const { data: quoteHistory, refetch, isRefetching, isFetching } = quoteHistoryQuery;

  const assetGroups = groupBy(assetOptions, (a) => a.location ?? 'No Location');
  const assetGroupedOptions = getMapEntries(assetGroups).map(([key, g]) => ({
    label: key,
    options: convertToDropDownOptionByPredicate(
      g,
      (asset) => getAssetDetailsLabel(asset),
      (a) => a.id
    )
  }));
  const api = useRmxServiceApi();

  const { mutateAsync: deleteQuote, isPending: pendingDelete } = useMutation({
    mutationFn: async (quoteId: string) => {
      await api.deleteQuote(quoteId);
    }
  });

  const quoteHistoryColumns = [
    {
      header: 'Date',
      accessorKey: 'createdAt',
      valueFormatter: (value: Date) => value.toLocaleDateString()
    },
    {
      header: 'Title',
      accessorKey: 'title'
    },
    {
      header: 'Techs',
      accessorKey: 'numberOfTechs'
    },
    {
      header: 'Estimated Duration',
      accessorKey: 'estimatedDuration',
      valueFormatter: (value: string) => {
        return `${parseFloat(parseEstimatedDurationInto8HourDays(value))} days`;
      }
    },
    {
      header: 'Private Notes',
      accessorKey: 'privateNotes'
    },
    {
      header: 'Description',
      accessorKey: 'description',
      valueFormatter: (value: string) => {
        return `${value === '' ? 'Description Pending' : value}`;
      }
    }
  ] as const satisfies SimpleColumnDef<Quote>[];

  const editQuoteModal = useModal();

  const [modalAssetId, setModalAssetId] = useState<number | null>(null);
  const [editQuoteInfo, setEditQuoteInfo] = useState<Quote | null>();
  // TODO add upload filename to CameraOrGalleryInput
  // populate the part library for existing quotes.
  return (
    <div>
      <QuoteForm assetId={assetId} activityId={activityId} enabled={enabled} assetGroupedOptions={assetGroupedOptions} refetch={refetch} />
      <Card className='mb-3'>
        <Card.Header>Quotes History</Card.Header>
        <Card.Body>
          {isRefetching || isFetching || pendingDelete ? (
            <Spinner />
          ) : quoteHistory && quoteHistory.length === 0 ? (
            <p style={{ textAlign: 'center' }}>No Quotes Have Been Made</p>
          ) : (
            <DropdownTable
              data={quoteHistory}
              columns={quoteHistoryColumns}
              ellipsisDropDownItems={[
                {
                  label: 'Edit',
                  onClick: async (row) => {
                    setModalAssetId(row.assetId);
                    setEditQuoteInfo(row);
                    await editQuoteModal.open(undefined);
                  }
                },
                {
                  label: 'Delete',
                  onClick: async (row) => {
                    await deleteQuote(row.qId);
                    await refetch();
                  }
                }
              ]}
            />
          )}
        </Card.Body>
      </Card>

      <GenericModal
        onExit={() => setEditQuoteInfo(null)}
        state={editQuoteModal}
        showHeader={true}
        showClose={true}
        hideFooter={true}
        label={'Edit Quote'}
        open={undefined}
      >
        <QuoteForm
          existingQuoteInfo={editQuoteInfo}
          onSubmit={editQuoteModal.close}
          assetId={modalAssetId ?? assetId}
          activityId={activityId}
          enabled={enabled}
          assetGroupedOptions={assetGroupedOptions}
          refetch={refetch}
        />
      </GenericModal>
    </div>
  );
}
