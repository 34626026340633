import { type Activity, type AssetWithDetailsResponse, getAssetDetailsLabel, type GetCustomerAndSiteAssetsRequest } from '../Service.types';
import React, { useMemo, useState } from 'react';
import { useModal } from '../../hooks/useModal';
import { useRmxServiceApi } from '../api';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { TimeSpan } from '../../utils/timespan';
import { useRmxServiceContext } from '../RmxServiceComponent/RmxServiceContext';
import { arrayToRecord } from '../../utils';
import { formatDateAndTime, formatDateToTimeString } from '../../utils/date-time';
import { DateInput } from '../../utils/DateInput';
import { TimeDropDown } from '../../utils/TimeDropDown/TimeDropDown';
import { MakeFieldRow, stringsToOptions } from './field-table';
import TimeField from 'react-simple-timefield';
import { ContactModal } from '../../utils/Contact/ContactModal/ContactModal';
import { useContactContext } from '../../utils/Contact/ContactContext';
import { convertToDropDownOptionByPredicate, getMapEntries, groupBy } from '../../../common/util';

export type DateTime = { startDate: Date; onSiteTime: Date };
const ActivityFieldRow = MakeFieldRow<Activity>();

export function ActivityDetails({
  activity: activityProps,
  disableEditing,
  setCustomerAndSiteAssets,
  // confirmedContactQuery,
  isMyWork
}: {
  activity: Activity;
  onChange?: (value: any) => void;
  disableEditing: boolean;
  setCustomerAndSiteAssets: (value: AssetWithDetailsResponse[]) => void;
  // confirmedContactQuery: UseQueryResult<ActivityContactInteraction[], Error>;
  isMyWork: boolean;
}) {
  const [activity, setActivity] = useState<Activity>(activityProps);
  const api = useRmxServiceApi();

  // const editable = activity.status === 'In Progress' || !hasRole('Technician');

  const { workOrderContacts, agreementContacts, isLoading: loadingContacts } = useContactContext();

  const updateActivityDescription = useMutation({
    mutationFn: async (description: string) => await api.updateActivityDescription(activity.id, description)
  });

  const updateActivityWorkOrderType = useMutation({
    mutationFn: async (workOrderType: string) => await api.updateActivityWorkOrderType(activity.id, workOrderType)
  });

  const updateActivityAvailability = useMutation({
    mutationFn: async (availability: string) => await api.updateActivityAvailability(activity.id, availability)
  });

  const updateActivityAssignedTo = useMutation({
    mutationFn: async (assignedTo: string) => await api.updateActivityAssignedTo(activity.id, assignedTo)
  });

  const updateActivityStartDate = useMutation({
    mutationFn: async (startDate: Date) => await api.updateActivityStartDate(activity.id, startDate)
  });

  const updateActivityOnSiteTime = useMutation({
    mutationFn: async (onSiteTime: Date) => await api.updateActivityOnSiteTime(activity.id, onSiteTime)
  });

  const updateActivityEstimatedDuration = useMutation({
    mutationFn: async (estimatedDuration: TimeSpan) => await api.updateActivityEstimatedDuration(activity.id, estimatedDuration)
  });

  const updateActivityAssetId = useMutation({
    mutationFn: async (assetId: number) => await api.updateActivityAssetId(activity.id, assetId)
  });

  // const { mutateAsync: confirmContact, isPending: confirmingContact } = useMutation({
  //   mutationFn: async (contactId: number) => await api.confirmContactInteraction(activity.id, contactId)
  // });
  //
  // const { mutateAsync: deleteContactInteraction, isPending: deletingContact } = useMutation({
  //   mutationFn: async (contactId: number) => await api.deleteContactInteraction(activity.id, contactId)
  // });
  //
  // const { mutateAsync: confirmContactNoResponse, isPending: confirmingContactNoResponse } = useMutation({
  //   mutationFn: async (noResponse: boolean) => {
  //     try {
  //       await api.updateActivityContactNoResponse(activity.id, noResponse);
  //       setActivity({ ...activity, contactConfirmedNoResponse: noResponse });
  //     } catch (error) {
  //       reportException(`Error confirming contact no response \n Exception: ${error}`);
  //       alert('Error confirming contact no response');
  //     }
  //   }
  // });
  //
  // const contactRowRef = React.useRef<HTMLDivElement>(null);
  const { data: assetsWithDetails, isFetching: isFetchingAssetDropdown } = useQuery({
    queryKey: ['assets', activity.id, activity.customerId, activity.siteId],
    queryFn: async () => {
      const request = { customerId: activity.customerId, siteId: activity.siteId } satisfies GetCustomerAndSiteAssetsRequest;
      const result = await api.getCustomerAndSiteAssets(request);
      setCustomerAndSiteAssets(result);
      return result;
    },
    enabled: activity.siteId != null || activity.customerId != null,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const { data: assignedToOptions, isFetching: isFetchingAssignedTo } = useQuery({
    queryKey: ['assignedToOptions', activity.id],
    queryFn: async () => {
      return await api.getAssignedToOptions(1);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const { refetchServiceTable } = useRmxServiceContext();

  // const onContactConfirmedNoResponseChange = async (noResponse: boolean, event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
  //   try {
  //     if ((confirmedContactQuery.data ?? []).length > 0) {
  //       alert('Cannot confirm contact no response when contacts are confirmed, remove confirmed contacts first.');
  //       event.currentTarget.checked = false;
  //       return;
  //     }
  //     await confirmContactNoResponse(noResponse);
  //     setActivity({ ...activity, contactConfirmedNoResponse: noResponse });
  //   } catch (error) {
  //     console.log('Error confirming contact no response', error);
  //   }
  // };
  //
  async function onAttributeSave(name: keyof Activity, value: unknown) {
    switch (name) {
      case 'startDate':
        console.log(`Attribute Save: ${name} - ${JSON.stringify(value)}`);
        setActivity({ ...activity, startDate: (value as DateTime).startDate, onSiteTime: (value as DateTime).onSiteTime });
        break;
      default:
        setActivity({ ...activity, [name]: value });
        break;
    }

    switch (name) {
      case 'description':
        await updateActivityDescription.mutateAsync(value as string);
        break;
      case 'activityType':
        await updateActivityWorkOrderType.mutateAsync(value as string);
        break;
      case 'availability':
        await updateActivityAvailability.mutateAsync(value as string);
        break;
      case 'assignedToUserId':
        await updateActivityAssignedTo.mutateAsync(value as string);
        break;
      case 'startDate':
        await updateActivityStartDate.mutateAsync((value as DateTime).startDate);
        (value as DateTime).onSiteTime && (await updateActivityOnSiteTime.mutateAsync((value as DateTime).onSiteTime));
        break;
      case 'estimatedDuration':
        await updateActivityEstimatedDuration.mutateAsync(value as TimeSpan);
        break;
      case 'assetId':
        await updateActivityAssetId.mutateAsync(value as number);
        break;
      default:
        break;
    }
    await refetchServiceTable();
  }
  const assetDetailsByAssetId = useMemo(() => {
    return arrayToRecord(
      assetsWithDetails ?? [],
      (a) => a.id,
      (a) => a
    );
  }, [assetsWithDetails]);

  const assignedToOptionsByAssignedTo = useMemo(() => {
    return arrayToRecord(
      assignedToOptions ?? [],
      (a) => a.id,
      (a) => a
    );
  }, [assignedToOptions]);

  const contactModal = useModal();
  const assetsGroupedByLocation = groupBy(assetsWithDetails ?? [], (a) => a.location ?? 'No Location');

  // const [showConfirmContactOnboardingGuide, setShowConfirmContactOnboardingGuide] = useState<boolean>(false);

  // function formatContactRow(contact: ContactInfo) {
  //   const confirmedContact = (confirmedContactQuery.data ?? []).find((ci) => ci.contactId === contact.id);
  //   return (
  //     <div key={`${contact.id}`}>
  //       <input
  //         disabled={!isMyWork}
  //         defaultChecked={confirmedContact !== undefined}
  //         onClick={async (event) => {
  //           if (activity.contactConfirmedNoResponse) {
  //             alert('Cannot confirm contact when contact confirmed no response is checked, uncheck contact confirmed no response first.');
  //             event.currentTarget.checked = false;
  //             return;
  //           }
  //           if (event.currentTarget.checked) {
  //             contact.id && (await confirmContact(contact.id));
  //             await confirmedContactQuery.refetch();
  //           } else {
  //             contact.id && (await deleteContactInteraction(contact.id));
  //             await confirmedContactQuery.refetch();
  //           }
  //         }}
  //         type='checkbox'
  //         style={{ marginRight: '10px' }}
  //       />
  //       {contact.contactName} - <a href={`mailto:${contact?.contactEmail}`}>{contact?.contactEmail}</a> -{' '}
  //       <a href={`tel:${contact?.contactPhone}`}>{contact?.contactPhone}</a>
  //     </div>
  //   );
  // }

  return (
    <>
      <ActivityFieldRow
        name={'assetId'}
        header={'Asset'}
        value={activity.assetId}
        onSave={onAttributeSave}
        editable={!disableEditing}
        isLoading={isFetchingAssetDropdown}
        display={(assetId) => {
          const asset = assetDetailsByAssetId[assetId];
          return asset ? getAssetDetailsLabel(asset) : assetId;
        }}
        groupedOptions={getMapEntries(assetsGroupedByLocation).map(([key, g]) => ({
          label: key,
          options: convertToDropDownOptionByPredicate(
            g,
            (asset) => {
              return getAssetDetailsLabel(asset);
            },
            (a) => a.id
          )
        }))}
      />

      <ActivityFieldRow
        name={'assignedToUserId'}
        header={'Assigned To'}
        value={activity.assignedToUserId}
        onSave={onAttributeSave}
        editable={!disableEditing}
        isLoading={isFetchingAssignedTo}
        display={(assignedToId) => {
          const assignedTo = assignedToOptionsByAssignedTo[assignedToId];
          return assignedTo ? assignedTo.name : 'Unscheduled';
        }}
        options={(assignedToOptions ?? []).map((assignedTo) => ({
          value: assignedTo.id,
          label: assignedTo.name
        }))}
      />

      <ActivityFieldRow
        name={'startDate'}
        header={'Start Date'}
        value={{ startDate: activity.startDate, onSiteTime: activity.onSiteTime }}
        onSave={onAttributeSave}
        display={(value) => formatDateAndTime({ date: value.startDate, time: value.onSiteTime }, true)}
        editable={!disableEditing}
        input={(value, onChange) => {
          const dateTime = value as DateTime;
          const startDate = dateTime.startDate;
          const onSiteTime = dateTime.onSiteTime;
          const dateEdit = (value: Date | null) => {
            console.log('DateEdit', value, typeof value);
            onChange({ startDate: value!, onSiteTime });
          };
          const timeEdit = (value: Date) => {
            onChange({ startDate, onSiteTime: value });
          };

          return (
            startDate &&
            onSiteTime && (
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <DateInput value={startDate} onChange={(date) => dateEdit(date)} />
                <TimeDropDown increment={15} defaultDate={onSiteTime} onChange={(time) => timeEdit(time)} disabled={false} />
              </div>
            )
          );
        }}
      />
      {/*<ActivityFieldRow name={'onSiteTime'} header={'On Site Time'} value={activity.onSiteTime} onSave={onAttributeSave} editable={!disableEditing} />*/}
      <ActivityFieldRow
        name={'description'}
        type='textarea'
        header={'Description'}
        value={activity.description}
        onSave={onAttributeSave}
        editable={!disableEditing}
      />
      <ActivityFieldRow
        header={'Private Notes'}
        type='textarea'
        name={'privateNotes'}
        onSave={onAttributeSave}
        value={activity.privateNotes == null || activity.privateNotes === '' ? 'None' : activity.privateNotes}
      />
      <ActivityFieldRow
        name={'activityType'}
        header={'Activity Type'}
        value={activity.activityType}
        onSave={onAttributeSave}
        editable={!disableEditing}
        options={stringsToOptions(['PM', 'STARTUP', 'WARRANTY', 'REPAIR'])}
      />
      <ActivityFieldRow
        name={'availability'}
        header={'Work Order Type'}
        value={activity.availability}
        onSave={onAttributeSave}
        editable={!disableEditing}
        options={stringsToOptions(['Regular', 'Special'])}
      />
      <ActivityFieldRow
        name={'estimatedDuration'}
        header={'Estimated Duration (Hrs)'}
        value={activity.estimatedDuration}
        onSave={onAttributeSave}
        editable={!disableEditing} // if false this will be true
        input={(value, onChange) => {
          const [hours, minutes] = value.split(':').map(Number);
          const date = new Date();
          date.setHours(hours!, minutes);

          return (
            <TimeField
              value={value}
              onChange={(_e, value) => {
                onChange(value as TimeSpan);
              }}
              colon={':'}
              showSeconds={false}
            />
          );
        }}
        display={(value) => {
          const [hours, minutes] = value.split(':').map(Number);
          const date = new Date();
          date.setHours(hours, minutes, 0, 0);
          return formatDateToTimeString(date, false, 'en-GB'); //  setting locale to en-GB for some reason fixes the date displaying 24:30 when it should be 00:30
        }}
      />

      <ActivityFieldRow name={'customerName'} header={'Customer'} value={activity.customerName} onSave={onAttributeSave} />
      <ActivityFieldRow name={'customerNotes'} header={'General Notes'} value={activity.customerNotes ?? 'None'} />
      <ActivityFieldRow
        name={'contactEmail'}
        editable={activity.status === 'In Progress'}
        value={activity.contactId}
        isLoading={loadingContacts} //{confirmedContactQuery.isFetching || deletingContact || confirmingContact || loadingContacts || confirmingContactNoResponse}
        display={() => {
          return (
            <div key={activity.id} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              {/* If Agreements Contacts Exist Display Them*/}
              {/*{agreementContacts && agreementContacts.length !== 0*/}
              {/*  ? agreementContacts?.map((contact, index) => formatContactRow(contact))*/}
              {/*  : workOrderContacts && workOrderContacts.length !== 0 && workOrderContacts?.map((contact, index) => formatContactRow(contact))}*/}
              {/*/!*Contact Confirmed No Response Checkbox *!/*/}
              {/*<div style={{ display: 'flex', alignItems: 'center' }}>*/}
              {/*  <input*/}
              {/*    defaultChecked={activity.contactConfirmedNoResponse}*/}
              {/*    disabled={!isMyWork}*/}
              {/*    onClick={async (event) => {*/}
              {/*      if (event.currentTarget.checked) {*/}
              {/*        await onContactConfirmedNoResponseChange(true, event);*/}
              {/*        // console.log(`activity contactConfirmedNoResponse: ${activity.contactConfirmedNoResponse}`);*/}
              {/*      } else {*/}
              {/*        await onContactConfirmedNoResponseChange(false, event);*/}
              {/*        // console.log(`activity contactConfirmedNoResponse: ${activity.contactConfirmedNoResponse}`);*/}
              {/*      }*/}
              {/*    }}*/}
              {/*    type='checkbox'*/}
              {/*    style={{ marginRight: '10px' }}*/}
              {/*  />*/}
              {/*  Contact Confirmed - No Response*/}
              {/*</div>*/}

              {agreementContacts && agreementContacts.length !== 0 ? (
                agreementContacts?.map((contact, index) => (
                  <div key={`${contact.id}`}>
                    {contact.contactName} - <a href={`mailto:${contact?.contactEmail}`}>{contact?.contactEmail}</a> -{' '}
                    <a href={`tel:${contact?.contactPhone}`}>{contact?.contactPhone}</a>
                  </div>
                ))
              ) : workOrderContacts && workOrderContacts.length !== 0 ? (
                workOrderContacts?.map((contact, index) => (
                  <div key={`${contact.id}`}>
                    {contact.contactName} - <a href={`mailto:${contact?.contactEmail}`}>{contact?.contactEmail}</a> -{' '}
                    <a href={`tel:${contact?.contactPhone}`}>{contact?.contactPhone}</a>
                  </div>
                ))
              ) : (
                <p> No contacts have been assigned to this work order.</p>
              )}
            </div>
          );
        }}
        onEditClick={() => {
          contactModal.setIsOpen(true);
        }}
        onSave={onAttributeSave}
        header={
          'Contact'
          // <div style={{ display: 'flex', gap: '5px' }}>
          //   <div>Contacts</div>
          //   <div>
          //     <IconButton
          //       icon={faQuestionCircle}
          //       size='lg'
          //       onClick={(event) => {
          //         setShowConfirmContactOnboardingGuide(true);
          //       }}
          //     />
          //   </div>
          // </div>
        }
      />

      {/*<ActivityFieldRow name={'id'} header={'ActivityId'} value={activity.activityCrmId} onSave={onAttributeSave} />*/}
      <ActivityFieldRow name={'workOrderId'} header={'Work Order Id'} value={activity.workOrderId} onSave={onAttributeSave} />
      <ActivityFieldRow name={'createdAt'} header={'Activity Created'} value={activity.createdAt} onSave={onAttributeSave} />
      <ActivityFieldRow
        name={'agreementType'}
        header={'Agreement Type'}
        value={activity.agreementType === null ? 'None' : activity.agreementType}
        onSave={onAttributeSave}
      />
      {contactModal.isOpen && <ContactModal state={contactModal} activity={activity} />}

      {/*<ConfirmContactOnboardingGuide*/}
      {/*  rowRef={contactRowRef}*/}
      {/*  forceDisplay={showConfirmContactOnboardingGuide}*/}
      {/*  onExit={() => setShowConfirmContactOnboardingGuide(false)}*/}
      {/*/>*/}
    </>
  );
}
