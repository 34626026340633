import { Card, Col, Row } from 'react-bootstrap';
import { Spinner } from '../../shared-compat/DataTable/DataTable';
import { ReactTable, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import type { ActivityExpense } from '../Service.types';
import React from 'react';
import { type UseQueryResult } from '@tanstack/react-query';

export interface Props {
  expenseHistoryQuery: UseQueryResult<ActivityExpense[], Error>;
}

export function RmxServiceHistory({ expenseHistoryQuery }: Props) {
  const { data: expenseHistory, isFetching: isFetchingExpenseHistory, isRefetching } = expenseHistoryQuery;

  const expenseHistoryColumns = [
    { header: 'Date', accessorKey: 'createdAt', valueFormatter: (value: Date) => value.toLocaleDateString() },
    { header: 'Description', accessorKey: 'description' },
    { header: 'Amount', accessorKey: 'amount' },
    { header: 'Sales Tax', accessorKey: 'saleTaxAmount' },
    { header: 'Payment Method', accessorKey: 'paymentType' }
  ] satisfies SimpleColumnDef[];

  return (
    <Row className={'mb-3'}>
      <Col>
        <Card>
          <Card.Header>Expense History</Card.Header>
          <Card.Body style={{ overflowY: 'auto', maxHeight: '200px' }}>
            {isRefetching || isFetchingExpenseHistory ? (
              <Spinner />
            ) : expenseHistory && expenseHistory.length === 0 ? (
              <p style={{ textAlign: 'center' }}>No Expenses Have Been Made</p>
            ) : (
              <ReactTable
                bordered={false}
                striped={false}
                includeHeaders={true}
                columns={expenseHistoryColumns}
                data={expenseHistory === undefined ? [] : (expenseHistory as ActivityExpense[])}
              />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
