import { useRef } from 'react';

/**
 * Syncs a ref with the current value
 * @param val
 */
export function useSyncRef<T>(val: T) {
  const ref = useRef(val);
  ref.current = val;
  return ref;
}
