import type { RefObject } from 'react';
import { OnboardingGuideComponent } from './OnboardingGuideComponent';
import { useOnboardingGuide } from './OnboardingContext';
import MyPartsDropdownImg from './media/MyPartsDropdown.png';

interface Props {
  dropdownRef: RefObject<HTMLSelectElement>;
  forceDisplay: boolean;
  onExit: () => void;
}

export function MyPartsDropdownGuide({ dropdownRef, forceDisplay, onExit }: Props) {
  const { guide, addGuideInteraction } = useOnboardingGuide('MyPartsDropdownGuide', forceDisplay);
  if (!guide) {
    return null;
  }
  return (
    <OnboardingGuideComponent
      guide={guide}
      addGuideInteraction={addGuideInteraction}
      onExit={onExit}
      steps={[
        {
          element: dropdownRef.current,
          title: '<u>My Parts Table Dropdown</u>',
          intro: `
          <strong>The "My Parts" table has 3 options.</strong>
          <br><br>
          Each shows different part locations.
           <br><br>
           <img src="${MyPartsDropdownImg}" alt="Deleting Optional Forms" style="max-width: 100%; height: auto; display: block; margin: auto;">\n
          `
        },
        {
          element: dropdownRef.current,
          title: '<u>My Parts Table Dropdown</u>',
          intro: `
          <strong><code>Tote</code></strong>
          <br><br>
           Shows all parts that you have on hand.`
        },
        {
          element: dropdownRef.current,
          title: '<u>My Parts Table Dropdown</u>',
          intro: `
          <strong><code>Surplus</code></strong>
          <br><br>
          Shows all leftover parts that you did not use in your previous activities.`
        },
        {
          element: dropdownRef.current,
          title: '<u>My Parts Table Dropdown</u>',
          intro: `
          <strong><code>WIP</code></strong>
          <br><br>
          Shows all picked parts for your "In Progress" activities.`
        },
        {
          element: dropdownRef.current,
          title: '<u>My Parts Table Dropdown</u>',
          intro: `
          <strong><code>Inventory</code></strong>
          <br><br>
          Shows all parts in inventory.`
        }
      ]}
    />
  );
}
