import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { type IconProp, type SizeProp } from '@fortawesome/fontawesome-svg-core';
import s from './Icon.module.scss';
interface IconProps {
  icon: IconProp;
  size?: SizeProp;
  customSize?: number;
  style?: React.CSSProperties;
  className?: string;
  color?: string;
}

export function Icon(props: IconProps) {
  if (props.icon === 'wifi-slash') {
    return <div className={s['wifi-off']} />;
  }
  return (
    <FontAwesomeIcon
      icon={props.icon}
      size={props.size}
      className={props.className}
      style={props.style ?? { ...(props.customSize ? { fontSize: props.customSize } : {}) }}
    />
  );
}
