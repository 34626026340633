import Table from 'react-bootstrap/Table';
import React, { type CSSProperties, type ReactElement, type ReactNode } from 'react';
import s from './ReactTable.module.scss';

export type RowData = Record<string, any>;

export interface SimpleColumnDef<T extends RowData = RowData, K extends (keyof T & string) | string = string> {
  header: ReactNode;
  accessorKey: K;
  hidden?: boolean;
  style?: CSSProperties;
  className?: string;
  valueFormatter?: (value: K extends keyof T ? T[K] : any, row: T) => ReactNode;
}

interface ReactTableProps<T extends RowData> {
  includeHeaders?: boolean;
  columns: SimpleColumnDef<T>[];
  data?: T[];
  /**
   * If true, the table will only show one row that has no data and is intended to be used as a form.
   */
  singleRowFormTable?: boolean;
  className?: string;
  tableClassName?: string;
  striped?: boolean;
  bordered?: boolean;
  onRowClick?: (row: T, index: number) => void;
  actionButton?: (row: T) => ReactElement;
  selectedRowIndex?: number;
  noItemsText?: string;
}

export function ReactTable<T extends RowData>(props: ReactTableProps<T>) {
  const visibleColumns = props.columns.filter((column) => !column.hidden);
  const handleRowClick = (row: T, index: number) => {
    if (props.onRowClick) {
      props.onRowClick(row, index);
    }
  };

  return (
    <div className={props.className} style={{ overflowX: 'auto' }}>
      {props.data?.length === 0 && props.noItemsText ? (
        <p style={{ textAlign: 'center' }}>{props.noItemsText}</p>
      ) : (
        <Table
          className={props.tableClassName ? props.tableClassName : s['table']}
          striped={props.striped !== undefined ? props.striped : true}
          bordered={props.bordered !== undefined ? props.bordered : true}
        >
          {(props.includeHeaders ?? true) && (
            <thead>
              <tr>
                {visibleColumns.map((column) => (
                  <th key={column.accessorKey}>{column.header}</th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {(props.singleRowFormTable ? ([{}] as T[]) : props.data ?? []).map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => handleRowClick(row, rowIndex)} style={{ cursor: props.onRowClick ? 'pointer' : '' }}>
                {visibleColumns.map((visibleColumn) => (
                  <td
                    className={visibleColumn.className}
                    style={{ backgroundColor: `${rowIndex === props.selectedRowIndex ? 'rgba(0,205,229,0.77)' : ''}`, ...(visibleColumn.style ?? {}) }}
                    key={visibleColumn.accessorKey}
                  >
                    {visibleColumn.valueFormatter
                      ? visibleColumn.valueFormatter(row[visibleColumn.accessorKey as unknown as keyof T], row)
                      : row[visibleColumn.accessorKey]}
                  </td>
                ))}
                {props.actionButton && <td>{props.actionButton(row)}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}
