import React from 'react';
import s from './CheckBox.module.scss';

interface Props {
  style?: React.CSSProperties;
  value: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
  enabled?: boolean;
  innerRef?: React.Ref<HTMLInputElement>;
  label: string;
  size?: 'sm' | 'md' | 'lg' | undefined;
}

export function CheckBox(props: Props) {
  const getFontSize = (size?: 'sm' | 'md' | 'lg') => {
    switch (size) {
      case 'sm':
        return 12; // Small size in pixels
      case 'md':
        return 16; // Medium size in pixels
      case 'lg':
        return 20; // Large size in pixels
      default:
        return 14; // Default size if not specified
    }
  };

  const styleWithFontSize = {
    ...props.style,
    fontSize: getFontSize(props.size) + 'px' // Apply the dynamic font size
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <input
        defaultChecked={props.value}
        disabled={!props.enabled}
        style={styleWithFontSize}
        ref={props.innerRef}
        className={props.className ? props.className : s['check-input']}
        onClick={(event) => {
          props.onChange(event.currentTarget.checked);
        }}
        type='checkbox'
      />
      {props.label}
    </div>
  );
}
