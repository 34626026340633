import ReactDOM from 'react-dom/client';
import React from 'react';
import { FormBuilderPage } from './FormBuilderPage';
import { createStandardQueryClient } from '../../utils/react-query/client';
import { QueryClientProvider } from '@tanstack/react-query';
const queryClient = createStandardQueryClient();
// @ts-ignore
window.BootstrapFormBuilder = (targetId: string) => {
  const root = ReactDOM.createRoot(document.getElementById(targetId) as HTMLElement);
  console.log('form builder page');
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FormBuilderPage />
      </QueryClientProvider>
    </React.StrictMode>
  );
};
