import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css';
import './introjs-customizations.css';
import { useEffect, useRef } from 'react';
import type { IntroJs } from 'intro.js/src/intro';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import type { IntroStep } from 'intro.js/src/core/steps';
import introJs from 'intro.js';
import { reportException } from '../../util/sentryProvider';
import { useEffectAlways } from '../../hooks/useEffectAlways';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import type { AddInteractionRequest, UserOnboardingInteraction } from '../rmx-service/Service.types';
import { NullErrorBoundary } from '../../components/util/NullErrorBoundary';

type OnboardingGuideComponentProps = {
  guide: UserOnboardingInteraction;
  addGuideInteraction: UseMutateAsyncFunction<void, Error, AddInteractionRequest, unknown>;
  steps: (Partial<IntroStep> & { onBefore?: () => Promise<unknown> })[];
  onExit?: () => void;
};

export function OnboardingGuideComponent(props: OnboardingGuideComponentProps) {
  return (
    <NullErrorBoundary>
      <OnboardingGuideComponentInner {...props} />
    </NullErrorBoundary>
  );
}

export function OnboardingGuideComponentInner({ addGuideInteraction, guide, steps: stepsProp, onExit: onExitProp }: OnboardingGuideComponentProps) {
  const introRef = useRef<IntroJs | null>(null);
  // this variable is used to check if the intro has exited to prevent an exception from being thrown by calling refresh after it has been closed.
  const introHasExited = useRef(false);
  const steps = stepsProp.map((step) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onBefore: _, ...rest } = step;
    return rest;
  });

  const onBeforeSteps = stepsProp.map((step, index) => ({ onBefore: step.onBefore, index })).filter((x) => x.onBefore);
  useEffectOnce(async () => {
    introRef.current = await introJs()
      .setOptions({
        steps: steps,
        showBullets: false,
        showProgress: true,
        exitOnOverlayClick: false,
        doneLabel: 'Got it!',
        exitOnEsc: false,
        tooltipPosition: 'bottom'
      })
      .onbeforechange(async function (targetElement: HTMLElement, currentStep: number, direction: 'backward' | 'forward') {
        const onBefore = onBeforeSteps.find((x) => x.index === currentStep)?.onBefore;
        if (onBefore) {
          await onBefore();
        }
        return true;
      })
      .onexit(async function () {
        try {
          introHasExited.current = true;
          if (onExitProp) onExitProp();
          // eslint-disable-next-line no-invalid-this
          if (this.currentStep() === steps.length - 1) {
            await addGuideInteraction({ guideId: guide.guideId, isCompleted: true });
            return;
          }
          await addGuideInteraction({
            guideId: guide.guideId,

            // eslint-disable-next-line no-invalid-this
            step: this.currentStep(),
            isCompleted: guide.hasCompleted ?? false
          });
        } catch (e) {
          alert('An error occurred while saving your progress. Please try again.');
          console.error(e);
          reportException(e);
        }
      })
      .start();
  });
  //
  useEffect(() => {
    if (!introHasExited.current) {
      introRef.current?.setOption('steps', steps);
      introRef.current?.refresh(true);
    }
  }, [steps]);

  useEffectAlways(() => {
    // ensure the hint moves with the element if react re-renders and moves the element
    if (!introHasExited.current) {
      introRef.current?.refresh(true);
    }
  });

  return <></>;
}
