
export const emptyArray = [];

export const makeReduceToDictionary = <T, K extends keyof any, V>(getKey: (val: T) => K, getVal: (val: T) => V) => (dictionary: Record<K, V>, item: T) =>
  ({...dictionary, [getKey(item)]: getVal(item)});

export const reduceToDictionary = <T, K extends keyof any, V>(values: T[], getKey: (val: T) => K, getVal: (val: T) => V) =>
  values.reduce<Record<K, V>>(makeReduceToDictionary(getKey, getVal), {} as Record<K, V>);


export const reduceToMap = <T, K, V>(values: T[], getKey: (val: T) => K, getVal: (val: T, index: number) => V) =>
  values.reduce<Map<K,V>>((map, item, index) => map.set(getKey(item), getVal(item, index)), new Map<K,V>());

export function immutableSort<T>(arr: T[], compareFn?: (a: T, b: T) => number): T[] {
  return [...arr].sort(compareFn);
}

export function arrAny<T>(arr: T[], predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any) {
  return arr.filter(predicate).length > 0;
}

export function arrFirst<T>(arr: T[], predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any) {
  return arr.filter(predicate)[0];
}

/**
 * Returns empty array if null or undefined, otherwise maps
 */
export function mapOrDefault<T, U>(arr: T[]|null|undefined, callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any) {
  if(arr)
    return arr.map(callbackfn, thisArg);
  return [];
}


export function firstOrUndefined<T>(arr?: T[]): T | undefined {
  if(!arr || arr.length === 0)
    return undefined;
  return arr[0];
}





export function sortByIdAscending<T extends {id: number}>(arr: T[]) {
  return immutableSort(arr, (a,b) =>  a.id - b.id);
}



export function filterEmpty<T>(arr: (T|null|undefined|false)[]): T[] {
  return arr.filter(x => x !== null && x !== undefined && x !== false) as T[];
}


export function distinctArray<T>(arr: T[]): T[] {
  if(arr.length < 230) {
    // the indexOf approach is faster for an array length less than 230. benchmarked.
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }
  // greater lengths are more efficient with a Set
  return [...new Set(arr)];
}

/**
 * Maps an array to a tuple with the value and the index
 * @param arr
 */
export function mapIndexed<const T extends readonly any[]>(arr: T) {
  return arr.map((val, index) => ([val, index] as const)) as unknown as {
      [Index in keyof T]: readonly [
        T[Index],
      // ensure that array tuples with length 15 or less are typed correctly as a constant numeric index
      // the keys by default are strings, so we need to convert them to numbers
        Index extends `${0}` ? 0 :
        Index extends `${1}` ? 1 :
        Index extends `${2}` ? 2 :
        Index extends `${3}` ? 3 :
        Index extends `${4}` ? 4 :
        Index extends `${5}` ? 5 :
        Index extends `${6}` ? 6 :
        Index extends `${7}` ? 7 :
        Index extends `${8}` ? 8 :
        Index extends `${9}` ? 9 :
        Index extends `${10}` ? 10 :
        Index extends `${11}` ? 11 :
        Index extends `${12}` ? 12 :
        Index extends `${13}` ? 13 :
        Index extends `${14}` ? 14 :
        Index extends `${15}` ? 15 :
        number
    ]
  };
}
