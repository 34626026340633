import React, { type ComponentType, useEffect, useState } from 'react';
import { formatDateShort } from '../../utils';
import { type ComponentId, deleteReplacement, getAssetComponentResets } from '../api';
import s from './ReplacementTableDialog.module.scss';
import { useCloseIfMissingResource, useModal } from '../../hooks/useModal';
import { ModalHoc } from '../../utils/Modal/ModalHoc';
import { OpenLastChangeModal } from '../ReplacementEditorDialog/ReplacementEditorDialog';
import { OpenConfirmDialog } from '../../utils/ConfirmDialog';
import { WarningText } from '../WarningText';
import { OpenFirstChangeDueDateEditorDialog } from '../FirstChangeDueDateEditorDialog/FirstChangeDueDateEditorDialog';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { type AssetComponentReset, type ComponentLifeModel } from '../component-life-types';
import { useComponentLifeData } from '../ComponentLifeDataContext';
import { handleAxiosError } from '../../utils/http';

export let OpenReplacementTableDialog: (row: ComponentLifeModel) => void = () => {};
export function ReplacementTableDialogContainer() {
  const modal = useModal<ComponentLifeModel, null>();
  OpenReplacementTableDialog = modal.open;
  return <ReplacementTableDialog state={modal} />;
}

interface Props {
  state: ReplacementTableDialogModalState;
}

function setDisplayName<TProps>(displayName: string, component: ComponentType<TProps>) {
  component.displayName = displayName;
  return component;
}

export const ReplacementTableDialog = ModalHoc(
  setDisplayName('ReplacementTableDialog', ({ state }: Props) => {
    const { updateComponentLifeRow, getByComponentId } = useComponentLifeData();
    const componentId = state.param?.ComponentId;
    const component = getByComponentId(state.param!.ComponentId);
    useCloseIfMissingResource(component, state);
    const [replacements, setReplacements] = useState<AssetComponentReset[] | null>(null);
    const loadData = async (componentId: ComponentId) => {
      try {
        const replacements = await getAssetComponentResets(componentId);
        setReplacements(replacements);
      } catch (err) {
        alert(handleAxiosError(err, { serverError: 'Failed to load replacements due to a server error.' }));
      }
    };
    useEffect(() => {
      if (state.isOpen && componentId) {
        void loadData(componentId);
      }
    }, [state.isOpen, componentId]);

    const onReplacementEdit = (replacement: AssetComponentReset) => {
      OpenLastChangeModal({ row: state.param!, editDate: replacement.Date, addReplacement: false, onComplete: () => loadData(state.param!.ComponentId) });
    };
    const onAdd = async () => {
      OpenLastChangeModal({ row: state.param!, addReplacement: true, onComplete: () => loadData(state.param!.ComponentId) });
    };

    const confirmDelete = (replacement: AssetComponentReset) => {
      OpenConfirmDialog({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this replacement?',
        onConfirm: async () => {
          try {
            const result = await deleteReplacement(replacement);
            updateComponentLifeRow(result);
            await loadData(state.param!.ComponentId);
          } catch (err) {
            alert(handleAxiosError(err, { serverError: 'Failed to delete replacement due to a server error.' }));
          }
        }
      });
    };
    return (
      <GenericModal hideFooter={true} shrinkMode={true} open={state.isOpen} setIsOpen={state.setIsOpen} showClose={true} label='Component Replacements'>
        <>
          <div className={`replacement-table-dialog__content ${s['replacement-table-dialog__content']}`}>
            {component?.FirstChangeDue ? (
              <WarningText className='mt-3'>
                This component has the first replacement scheduled for {formatDateShort(component.FirstChangeDue)}
                <br />
                <a
                  href='#'
                  onClick={() => {
                    OpenFirstChangeDueDateEditorDialog({ row: component, originalFirstChangeDate: component.FirstChangeDue! });
                  }}
                >
                  Click here if you need to modify that scheduled date
                </a>
                <br />
                If you need to add a replacement then click the + button below.
              </WarningText>
            ) : null}
            <div className='d-flex justify-content-between align-items-start mt-1'>
              <label className='d-block' htmlFor='replacement-table-dialog__date'>
                Replacements
              </label>
              <i
                title='Add Replacement'
                style={{ cursor: 'pointer', fontSize: 22 }}
                onClick={onAdd}
                className='fas fa-plus-circle replacement-table-dialog__add'
              />
            </div>
            <div className={`mb-2`}>
              <table className={`table table-striped  ${s['table']}`}>
                <thead>
                  <tr>
                    <th>Date</th>
                    {/*<th>Hours</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {replacements?.length === 0 ? (
                    <tr>
                      <td>No replacements yet</td>
                    </tr>
                  ) : null}
                  {replacements?.map((replacement) => (
                    <tr key={replacement.Date.toString()}>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <a href={'#'} onClick={() => onReplacementEdit(replacement)}>
                            {formatDateShort(replacement.Date)}
                          </a>
                          <a href='#' onClick={() => confirmDelete(replacement)} style={{ color: 'red' }}>
                            <i className='fas fa-trash-alt' />
                          </a>
                        </div>
                      </td>
                      {/*<td>N/A</td>*/}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      </GenericModal>
    );
  })
);

export type ReplacementTableDialogModalState = ReturnType<typeof useModal<ComponentLifeModel, null>>;
