import { type Nominal } from './typescript-utils';
import React, { type PropsWithChildren, type Provider, type ReactElement } from 'react';

export type ContextWithValue<T> = Nominal<[Provider<T>, T], 'ContextWithValue'>;

export function createContextWithValue<T>(provider: Provider<T>, value: T): ContextWithValue<T> {
  return [provider, value] as ContextWithValue<T>;
}

/**
 * Renders a list of contexts with its associated value
 * @param contexts
 * @param children
 * @constructor
 */
export function ContextProvider({ contexts, children }: { contexts: ContextWithValue<any>[] } & PropsWithChildren) {
  if (contexts.length === 0) return null;
  let child: ReactElement = (children as ReactElement) ?? null;
  for (let i = contexts.length - 1; i >= 0; i--) {
    const currentItem = contexts[i];
    const ContextComponent = currentItem![0];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    child = <ContextComponent value={currentItem![1] as any}>{child}</ContextComponent>;
  }
  return child;
}
