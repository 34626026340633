import { EllipsisDropdown, type EllipsisDropdownItem } from '../../../components/util/widgets/EllipsisDropdown';
import { ReactTable, type RowData, type SimpleColumnDef } from '../ReactTable/ReactTable';
import React from 'react';
import s from './DropdownTable.module.scss';

type TableEllipsisDropdownItem<T> = Omit<EllipsisDropdownItem, 'onClick'> & {
  onClick: (row: T) => void;
};

interface Props<T extends RowData> {
  data?: T[];
  columns: SimpleColumnDef<T>[];
  ellipsisDropDownItems?: TableEllipsisDropdownItem<T>[];
}

export function DropdownTable<T extends RowData>({ data, ellipsisDropDownItems, columns }: Props<T>) {
  const dropDownItems = (item: T) => {
    return (
      ellipsisDropDownItems?.map((dropDownItem) => {
        return {
          label: dropDownItem.label,
          onClick: () => {
            dropDownItem.onClick(item);
          }
        };
      }) ?? []
    );
  };

  return (
    <>
      <ReactTable
        bordered={false}
        striped={false}
        className={s['contact-table']}
        noItemsText='No Contacts'
        includeHeaders={true}
        data={data}
        actionButton={(data) => <EllipsisDropdown items={dropDownItems(data)} />}
        columns={columns}
      />
    </>
  );
}
