import type { Column, Table } from '@tanstack/react-table';
import { type TableState, useServerOptions } from '../hooks';
import React, { useEffect, useId, useRef, useState } from 'react';
import { DataList } from '../../DataList';
import { DebouncedInput } from '../DebouncedInput';
import { transformOr } from '../../../utils';
import clsx from 'clsx';
import s from '../DataTable.module.scss';

interface Props<T> {
  column: Column<any, any>;
  table: Table<any>;
  tableState: TableState<T>;
  forceInitialize?: boolean;
  onFilteredFocus?: () => void;
}

export function TextColumnFilter<T>(props: Props<T>) {
  const { column } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [initialized, setInitialized] = useState(props.forceInitialize ?? false);
  const { optionsCount, isLoading, error, sortedUniqueValues, usingServerOptions, serverOptions } = useServerOptions({ ...props, initialized });
  const [focus, setFocus] = useState(false);

  const [columnFilterValue, setColumnFilterValue] = useState<string | undefined>();

  useEffect(() => {
    if (props.forceInitialize) {
      const currentFilterValue = column.getFilterValue() as string | undefined;
      if (!focus && initialized && optionsCount === 1) {
        // setting the filter value to the only available option
        setColumnFilterValue(serverOptions?.[0]);
      } else if (!focus && columnFilterValue !== undefined) {
        // If the input is not focused and the filter value is not undefined, clears the filter value so that we can programmatically set it
        setColumnFilterValue(undefined);
      } else if (focus && currentFilterValue !== columnFilterValue) {
        // when user focuses on input this updates display options (if needed, i.e user has typed something in the input box)
        setColumnFilterValue(currentFilterValue);
      }
    }
  }, [column, columnFilterValue, focus, initialized, optionsCount, props.forceInitialize, serverOptions]);

  const dataListId = useId();
  return (
    <>
      {error && <span style={{ color: 'red' }}>Failed to retrieve options</span>}
      {serverOptions !== undefined && <DataList id={dataListId} inputRef={inputRef} options={sortedUniqueValues} openOnMount={usingServerOptions} />}
      <DebouncedInput
        onFocus={() => {
          setFocus(true);
          if (!initialized) setInitialized(true);
          else if (initialized) {
            props.onFilteredFocus?.();
          }
        }}
        onBlur={() => setFocus(false)}
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => {
          column.setFilterValue(value);
        }}
        placeholder={isLoading ? `Loading options...` : `Search... ${transformOr(optionsCount, (n) => `(${n})`, '')}`}
        className={clsx('border shadow rounded', s['input-box'])}
        list={serverOptions !== undefined ? dataListId : undefined}
        ref={inputRef}
      />
      <div className='h-1' />
    </>
  );
}
