import { createContext, type PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { type SubmissionInfo, GetSubmissionAndSchemaInfoByActivityId, type ExistingSubmissionAndSchemaInfo } from '../../form/api';
import { arrayToMap } from '../../../common/util';

type ExistingFormsContextData = {
  activityId: string;
  getFormByTitle: (title: string) => SubmissionInfo | null | undefined;
  formTitleValueMap: Map<string, SubmissionInfo> | undefined;
  requiredForms: Map<string, SubmissionInfo> | undefined;
  nonRequiredForms: Map<string, ExistingSubmissionAndSchemaInfo> | undefined;
  formSubmissionQuery: UseQueryResult<SubmissionInfo[], Error>;
  formSubmittedByTitle: Map<string, boolean | undefined> | undefined;
};

const FormDataContext = createContext<ExistingFormsContextData | null>(null);

export function useFormDataContext() {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error('useExistingFormsContext must be used within a ExistingFormsContextProvider');
  }
  return context;
}

export function useFormData() {
  const { formTitleValueMap, requiredForms, nonRequiredForms, formSubmittedByTitle, formSubmissionQuery } = useFormDataContext();
  return { formTitleValueMap, requiredForms, nonRequiredForms, formSubmittedByTitle, formSubmissionQuery };
}

export function FormDataProvider({ activityId, children }: { activityId: string } & PropsWithChildren) {
  const submissionAndSchemaInfoQuery = useQuery({
    queryKey: ['existingForms', activityId],
    queryFn: async () => {
      return await GetSubmissionAndSchemaInfoByActivityId(activityId);
    }
  });

  const submissionFormsByTitle = useMemo(
    () =>
      submissionAndSchemaInfoQuery.data
        ? arrayToMap(
            submissionAndSchemaInfoQuery.data ?? [],
            (form) => form.formName,
            (form) => form
          )
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const requiredFormsByTitle = useMemo(
    // showing required forms regardless of whether they have a submission
    // this allows the slam to appear by default
    () =>
      submissionAndSchemaInfoQuery.data
        ? arrayToMap(
            submissionAndSchemaInfoQuery.data.filter((form) => form.required) ?? [],
            (form) => form.formName,
            (form) => form
          )
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const existingNonRequiredFormsByTitle = useMemo(
    // only showing non-required forms that have an existing submission
    // this makes it so all forms don't appear by default
    // the AddFormModal shows all forms that have not been submitted and that are not required
    () =>
      submissionAndSchemaInfoQuery.data
        ? (arrayToMap(
            submissionAndSchemaInfoQuery.data.filter((form) => !form.required && form.id !== null) ?? [],

            (form) => form.formName,
            (form) => form
          ) as Map<string, ExistingSubmissionAndSchemaInfo>)
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const formSubmittedByTitle = useMemo(
    () =>
      submissionAndSchemaInfoQuery.data
        ? arrayToMap(
            submissionAndSchemaInfoQuery.data.filter((form) => !form.data?.submit) ?? [],
            (form) => form.formName,
            (form) => form.data?.submit
          )
        : undefined,
    [submissionAndSchemaInfoQuery.data]
  );

  const getFormByTitle = useCallback(
    (title: string) => (submissionFormsByTitle ? submissionFormsByTitle.get(title) ?? null : undefined),
    [submissionFormsByTitle]
  );
  const contextValue = useMemo(
    () => ({
      getFormByTitle,
      activityId: activityId,
      formTitleValueMap: submissionFormsByTitle,
      requiredForms: requiredFormsByTitle,
      nonRequiredForms: existingNonRequiredFormsByTitle,
      formSubmissionQuery: submissionAndSchemaInfoQuery,
      formSubmittedByTitle: formSubmittedByTitle
    }),
    [
      activityId,
      submissionFormsByTitle,
      submissionAndSchemaInfoQuery,
      formSubmittedByTitle,
      getFormByTitle,
      existingNonRequiredFormsByTitle,
      requiredFormsByTitle
    ]
  );
  return <FormDataContext.Provider value={contextValue}>{children}</FormDataContext.Provider>;
}
