import { createServerColumnHelper } from 'src/portal/shared-compat/DataTable/hooks';
import type { MyPartsResponse } from '../Service.types';

const helper = createServerColumnHelper<MyPartsResponse>();
const ch = helper.accessor;
export const myPartColumnsDef = ({ dropDownValue }: { dropDownValue: string }) =>
  [
    ch('ccn', 'string', {
      header: 'CCN',
      enableSorting: true,
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id
    }),
    ch('partName', 'string', {
      header: 'Part Name',
      enableSorting: true,

      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id
    }),
    ch('quantity', 'number', {
      header: 'In Stock',
      enableSorting: true,
      enableColumnFilter: true,
      cell: (info) => {
        const row = info.row.original;
        return row.quantity;
      },
      footer: (props) => props.column.id
    }),
    ch('price', 'number', {
      header: 'Price',
      enableSorting: true,
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      meta: {
        isVisible: dropDownValue === 'Inventory'
      }
    }),
    ch('customerName', 'string', {
      header: 'Customer Name',
      enableSorting: true,
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      meta: {
        isVisible: dropDownValue === 'Surplus' || dropDownValue === 'WIP'
      }
    }),
    ch('activityId', 'number', {
      header: 'Activity Id',
      enableSorting: true,
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      meta: {
        isVisible: dropDownValue === 'WIP' || dropDownValue === 'Surplus'
      }
    })
  ] as const;
