import {isNullOrUndefined, getObjectKeys} from 'src/common/util/index';


export function makeUrl(templateUrl: string , params: Record<string, string|number|null> | Array<number|string|null> | string) {
  if(!templateUrl)
    throw new Error('cannot make a url on ' + templateUrl);
  let finalString = templateUrl;
  if (typeof params === 'string') {
    finalString = finalString.replace(':0', encodeURIComponent(params));
  } else if (Array.isArray(params)) {
    params.forEach((val, key) => {
      finalString = finalString.replace(':' + key, encodeURIComponent(String(val)));
    });
  } else {
    const paramNames = Object.keys(params);
    for (const paramName of paramNames) {
      finalString = finalString.replace(':' + paramName, encodeURIComponent(String(params[paramName])));
    }
  }
  return finalString;
}

export function sanitizeUrl(template: TemplateStringsArray, ...params: (string|number|boolean)[]){
  return template.reduce((result, part, i) => {
    result += part;
    if (params[i] !== undefined)
      result += encodeURIComponent(params[i]);
    return result;
  }, '');
}

export type ToNumbers<T> = {
  [P in keyof T]: number;
};

export type ToStrings<T> = {
  [P in keyof T]: string;
};


type ParamTypeSchema = 'number' | 'string' | 'string?' | 'number?';
export type ParamDef = Record<string, ParamTypeSchema>;
export type ParamDefOf<T> = Record<keyof T, ParamTypeSchema>;
export type ParamsType<T> = {
  [K in keyof T]:
  T[K] extends 'string' ? string
    : T[K] extends 'number' ? number
      : T[K] extends 'string?' ? string | undefined
        : T[K] extends 'number?' ? number | undefined
  : never;
};

export function getParamsFromProps<T>(p: {match: {params: ToStrings<T>}}, definition: ParamDefOf<T>) {
  return getParams(p.match.params, definition);
}

export function getParams<T>(match: ToStrings<T>, definition: ParamDefOf<T>) {
  const result: T = {} as T;
  const keys = getObjectKeys(match);
  for (const key of keys) {
    if(definition[key] === 'number')
    { // @ts-ignore
      result[key] = !isNullOrUndefined(match[key])  ? Number(match[key]) : match[key];
    }
    else
    { // @ts-ignore
      result[key] = match[key] !== undefined ? decodeURIComponent(match[key]) : match[key];
    }
  }
  return result;
}

export function getNumberParams<V extends keyof T, T>(match: T, ...keys: V[]) {
  const result: ToNumbers<Pick<T, V>> = {} as ToNumbers<Pick<T, V>>;
  for (const key of keys) {
    result[key] = Number(match[key]);
  }
  return result;
}
