import { type MutableRefObject } from 'react';
import { useEffect } from 'react';
import { useForceUpdate } from '../../hooks/forceUpdate';

/**
 * Rerenders the component when the ref is set
 * @param ref
 * @param interval the interval to check when the ref is set
 */
export function useForceUpdateWhenRefIsSet<T>(ref: MutableRefObject<T | null>, interval = 100) {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    // if the ref is already set there is no need to wait for it to be set
    if (ref.current) {
      return;
    }
    const intervalIdRef = { current: null as number | null };
    intervalIdRef.current = window.setInterval(() => {
      if (ref.current !== null) {
        // if the ref it set, clear the interval and force a rerender
        if (intervalIdRef.current !== null) window.clearInterval(intervalIdRef.current);
        forceUpdate();
      }
    }, interval);
    return () => {
      if (intervalIdRef.current !== null) window.clearInterval(intervalIdRef.current);
    };
  }, [forceUpdate, interval, ref]);
}
