import { Button, Card, CardBody, Col, Row, Spinner } from 'react-bootstrap';
import React, { Suspense, useState } from 'react';
import { ReactTable, type RowData, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { LazyQRCodeScanner } from './LazyQRCodeScanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
  enabled: boolean;
}
export function RmxServiceSample({ enabled }: Props) {
  const [showQrScanner, setQrScanner] = useState(false);
  const [sampleHistory, setSampleHistory] = useState<RowData[]>([]);

  const sampleHistoryColumns = [
    { header: 'Date', accessorKey: 'Date' },
    { header: 'Sample ID', accessorKey: 'SampleID' }
  ] satisfies SimpleColumnDef[];

  return (
    <div style={{ minHeight: '415px', overflowY: 'auto', overflowX: 'hidden' }}>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <FontAwesomeIcon icon={faInfoCircle} /> Coming Soon Samples are a work in progress and currently not functional
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className={'mb-3'}>
        <Col>
          <Card style={{ width: '100%' }}>
            <CardBody style={{ display: 'flex', justifyContent: 'center' }}>
              {
                <GenericModal open={showQrScanner} setIsOpen={setQrScanner} shrinkMode={true}>
                  <Suspense fallback={<Spinner />}>
                    <LazyQRCodeScanner setQrScanner={setQrScanner} sampleHistory={sampleHistory} setSampleHistory={setSampleHistory} />
                  </Suspense>
                </GenericModal>
              }
              {!showQrScanner ? (
                <Button disabled={!enabled} variant={'secondary'} onClick={() => setQrScanner(true)}>
                  Click here to scan a sample
                </Button>
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className={'mb-3'}>
        <Col>
          <Card>
            <Card.Header>Sample History</Card.Header>
            <Card.Body style={{ width: '100%', overflowY: 'auto', maxHeight: '200px' }}>
              {sampleHistory.length === 0 ? (
                <p style={{ textAlign: 'center' }}>No Samples Have Been Captured</p>
              ) : (
                <ReactTable bordered={false} striped={false} includeHeaders={false} columns={sampleHistoryColumns} data={sampleHistory} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
