import type { RefObject } from 'react';
import { useOnboardingGuide } from './OnboardingContext';
import { OnboardingGuideComponent } from './OnboardingGuideComponent';
import DebriefedWithContact from './media/DebriefedWithContact.gif';

interface Props {
  headerRef: RefObject<HTMLElement>;
  forceDisplay: boolean;
  onExit: () => void;
}

export function DebriefWithOnboardingGuide(props: Props) {
  const { guide, addGuideInteraction } = useOnboardingGuide('DebriefWithOnboardingGuide', props.forceDisplay, false);
  if (!guide) {
    return null;
  }
  return (
    <OnboardingGuideComponent
      guide={guide}
      addGuideInteraction={addGuideInteraction}
      onExit={props.onExit}
      steps={[
        {
          // element: props.headerRef.current,
          title: '<u>Debrief With</u>',
          position: 'top',
          intro: `
          <strong>To debrief with select <code>Add Contact</code> and then select the ellipse button and press on <code>Debriefed With</code> </strong>
          `
        },
        {
          // element: props.headerRef.current,
          title: '<u>Debrief With</u>',
          position: 'top',
          intro: `
          <img src="${DebriefedWithContact}" alt="Debrief With Contact" style="max-width: 100%; height: auto; display: block; margin: auto;">
          `
        }
      ]}
    />
  );
}
