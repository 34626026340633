import type * as Highcharts from 'highcharts';
import { type AlignValue, type Axis, type Options, type Point, type SeriesOptionsType, type YAxisOptions } from 'highcharts';
import type { AlertGroupName, ChartAlert, ChartData, ChartStyleOptions } from './charting.types';
import { addMinutes, format, parseISO } from 'date-fns';
import { ExclamationTriangle } from './icons/exclamation-triangle';
import { ExclamationCircle } from './icons/exclamation-circle';
import { Info } from './icons/info';
import { WifiOff } from './icons/wifi-off';
import { convertArrayToDictionary, getObjectValues, range } from '../../common/util';

declare module 'highcharts' {
  interface Chart {
    plotBandsTooltip: SVGElement[];
    autumnBracket?: SVGElement;
    autumnText?: SVGElement;
    addedIcons?: boolean;
  }
}

// plot band with tooltip: https://jsfiddle.net/BlackLabel/nk41ebvL/
// plot bands https://www.highcharts.com/docs/chart-concepts/plot-bands-and-plot-lines
// rendering items on the chart in respect to axis coordinates: https://jsfiddle.net/api/post/library/pure/
// https://jsfiddle.net/BlackLabel/pfqv5e6s/ syncing multiple charts
// scrollbar on chart: https://www.highcharts.com/docs/chart-concepts/scrollbar
// margins https://www.highcharts.com/docs/chart-design-and-style/design-and-style
// mouse tracking https://jsfiddle.net/highcharts/YQ6Bt/
// changing color of line between specified zones https://jsfiddle.net/qjmjd34d/6/
// legend scrollbar https://jsfiddle.net/BlackLabel/kb4gu5rn/
async function buildChart(containerId: string, chartData: ChartData, chartOptions: ChartStyleOptions = {}) {
  // uncomment to add fake alerts for testing
  // chartData.Alerts = makeFakeAlerts(chartData);
  const alerts = chartData.Alerts;
  const yAxisGenerator = new YAxisGenerator();
  const alertsById = convertArrayToDictionary(alerts, 'Id');
  const alertPoints = mapAlertsToAlertPoints(chartData, yAxisGenerator);
  const ys = alertPoints.map((p) => p.y);
  const yMax = Math.max(...ys);
  const yMin = Math.min(...ys);
  const unitsByYAxisId = chartData.YAxisLabels.map((l) => l.SeriesUnit);
  const yAxisLabelsById = chartData.YAxisLabels;
  const Highcharts = (await import('./lazyHighcharts')).Highcharts;
  const options: Options = {
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'printChart',
            // 'separator',
            'downloadPNG',
            // 'downloadJPEG',
            'downloadPDF',
            // 'downloadSVG',
            // 'separator',
            'downloadCSV',
            'downloadXLS'
            //"viewData",
            // 'openInCloud'
          ]
        }
      }
    },
    boost: { seriesThreshold: 50_000 },
    colors: ['#6ec5ff', '#CEEDA5', '#9F6AE1', '#FEA26E', '#6BA48F', '#EA3535', '#8D96B7', '#ECCA15', '#20AA09', '#E0C3E4'],
    chart: {
      ...(chartOptions.width ? { width: chartOptions.width } : {}),
      ...(chartOptions.height ? { height: chartOptions.height } : {}),
      animation: false,
      zooming: {
        type: 'x',
        resetButton: {
          // the title could overlap with the button but it will just flow behind the button
          // which isn't that big of an issue since they can reset zoom to see the title better.
          // ensure the reset button is not covering the graph and getting in the way
          relativeTo: 'spacingBox',
          position: {
            x: -70, // ensure the hamburger menu is not covered up.
            align: 'right' // align it right next to the hamburger menu
          }
        }
      },
      panning: {
        enabled: true
      },
      panKey: 'shift',
      events: {
        render: function () {
          if (!this.addedIcons) {
            this.renderer.box.insertAdjacentHTML('afterbegin', ExclamationTriangle);
            this.renderer.box.insertAdjacentHTML('afterbegin', ExclamationCircle);
            this.renderer.box.insertAdjacentHTML('afterbegin', Info);
            this.renderer.box.insertAdjacentHTML('afterbegin', WifiOff);
            this.addedIcons = true;
          }
          const xRangeSeries = this.series.filter((s) => s.type === 'xrange')[0]?.data;
          (xRangeSeries ?? []).forEach((point) => {
            if (!point.graphic?.element) return;
            const xAxis = this.xAxis[0]!;
            const alert = alertsById[point.options!.custom!['alertId'] as number];
            addAlertIconToXRangeSeriesPoint(point, xAxis, alert);
          });
        }
      }
    },
    title: {
      text: chartData.Title,
      ...(chartOptions.titleAlign ? { align: chartOptions.titleAlign as AlignValue } : {})
    },
    subtitle: {
      text: chartData.SubTitle,
      ...(chartOptions.subTitleAlign ? { align: chartOptions.subTitleAlign as AlignValue } : {})
    },
    xAxis: {
      accessibility: {
        rangeDescription: chartData.SubTitle
      },
      type: 'datetime',
      dateTimeLabelFormats: makeDateTimeLabelFormats(true, true),
      crosshair: true
    },
    tooltip: {
      formatter: function () {
        let output = '';
        const x = this.point.x!;
        const isAlert = this.series.type === 'xrange';
        if (isAlert) {
          const alert = alertsById[this.point.options!.custom!['alertId'] as number];
          const end = alert.End;
          const start = alert.Start;
          const installationModel = alert.AssetId ? chartData.Assets[alert.AssetId] : chartData.SystemProcesses[alert.SystemProcessId!];
          output += installationModel.Name + '<br/>';
          output += 'Start: ' + formatUnixTimeStamp(start) + '<br/>End: ' + (end !== null ? '&nbsp;' + formatUnixTimeStamp(end) : 'Still Active') + '<br/>';
          return output + alert.AlertDefinition;
        } else {
          output += '<b>' + this.series.name + '</b><br/>';
          output += formatUnixTimeStamp(x) + '<br/>';
          const unit = unitsByYAxisId[this.series.userOptions.yAxis! as number];
          return output + Highcharts.numberFormat(this.y!, 2) + ' ' + unit;
        }
      },
      dateTimeLabelFormats: makeDateTimeLabelFormats(false, false)
    },
    legend: {
      // margin: 100,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      alignColumns: true
    },
    plotOptions: {
      series: {
        boostThreshold: 1,
        states: {
          hover: {
            // enabled: false,
            shadow: false,
            lineWidth: 2
          }
        },
        lineWidth: 2
      },
      xrange: {
        minPointLength: 1 // ensure alerts always show no matter how small they are
      }
    },
    navigation: {
      menuItemStyle: {
        fontFamily: 'Times New Roman',
        fontSize: '30'
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }
      ]
    },
    series: chartData.SeriesList.map((s) => {
      const isBoolean = yAxisLabelsById[s.YAxis as unknown as number].IsBoolean;
      const series: SeriesOptionsType = {
        name: s.Name,
        yAxis: s.YAxis,
        data: isBoolean ? lastValueInterpolate(booleanInterpolate(s.Data)) : interpolate(s.Data),
        type: 'line',
        marker: {
          enabled: false
        }
      };
      if (isBoolean) {
        series.softThreshold = false;
      }
      return series as SeriesOptionsType;
    }).concat(
      alerts.length > 0
        ? [
            {
              name: 'Alert',
              showInLegend: false,
              type: 'xrange',
              pointWidth: 20,
              yAxis: chartData.YAxisLabels.length,
              events: {
                mouseOver: function () {
                  console.log('Mouse over');
                }
              },
              data: alertPoints
            } as SeriesOptionsType
          ]
        : []
    ),
    yAxis: chartData.YAxisLabels.map((l) => {
      const opts: YAxisOptions = {
        gridLineWidth: 1,
        gridLineColor: '#343436',
        zoomEnabled: false,
        title: {
          text: l.SeriesName
        },
        labels: {
          format: '{value} ' + l.SeriesUnit
        },
        height: alerts.length > 0 ? '70%' : undefined
      };
      if (l.Opposite) opts.opposite = true;
      if (l.IsBoolean) {
        opts.alignTicks = false;
        opts.endOnTick = false;
        opts.startOnTick = false;
        opts.maxPadding = 0.25;
        opts.minPadding = 0.25;
        opts.allowDecimals = false;
      }
      return opts;
    }).concat(
      alerts.length > 0
        ? [
            {
              type: 'category',
              gridLineWidth: 1,
              gridLineColor: '#343436',
              top: '70%',
              height: '30%',
              min: 0,
              staticScale: 60,
              tickLength: 0,
              max: yAxisGenerator.getTotal() - 1,
              scrollbar: {
                enabled: true
              },
              minPadding: 0.25,
              maxPadding: 0.25,
              endOnTick: false,
              startOnTick: false,
              alignTicks: false,
              title: {
                text: ''
              },
              categories: [''].concat(range(yMin, yMax).map(() => '')).concat(['', ''])
            }
          ]
        : []
    )
  };
  // @ts-ignore
  Highcharts.setOptions({ global: { useUTC: false } });
  return Highcharts.chart(containerId, options);
}

// @ts-ignore
window.buildChart = buildChart;

class YAxisGenerator {
  private readonly yAxisLabelsById: string[] = [];

  public getYAxisIndex(label: string): number {
    const index = this.yAxisLabelsById.indexOf(label);
    if (index === -1) {
      this.yAxisLabelsById.push(label);
      return this.yAxisLabelsById.length - 1;
    }
    return index;
  }

  public getTotal(): number {
    return this.yAxisLabelsById.length;
  }
}

function mapAlertsToAlertPoints(chartData: ChartData, yAxisGenerator: YAxisGenerator) {
  return chartData.Alerts.map((alert) => {
    return {
      x: alert.Start < chartData.StartDate ? chartData.StartDate : alert.Start,
      x2: alert.End ? (alert.End > chartData.EndDate ? chartData.EndDate : alert.End) : chartData.EndDate,
      y: yAxisGenerator.getYAxisIndex('A' + (alert.AssetId ?? 0) + 'SP' + alert.SystemProcessId + 'Def' + alert.AlertDefinition),
      custom: {
        alertId: alert.Id
      },
      name: alert.AlertDefinition,
      ...colorsByAlertGroup[alert.AlertGroupName as keyof typeof colorsByAlertGroup]
    };
  }) satisfies Array<Highcharts.XrangePointOptionsObject>;
}

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function makeFakeAlerts(chartData: ChartData) {
  const firstAsset = getObjectValues(chartData.Assets)[0];
  const firstSystemProcess = getObjectValues(chartData.SystemProcesses)[0];
  return range(0, 30).map((i) => {
    const alertGroupType = i % 3;
    const alertGroup = alertGroupType === 0 ? 'Trip' : alertGroupType === 1 ? 'Warning' : 'Error';
    return {
      Id: i,
      Start: addMinutes(parseISO('2024-06-05T13:20:00'), i * 5).valueOf(),
      End: addMinutes(parseISO('2024-06-05T13:40:00'), i * 5).valueOf(),
      AlertGroupName: alertGroup,
      DataPointId: 1,
      AlertDefinition: 'Trip - System Pressure - Low Value',
      AssetId: firstAsset ? firstAsset.Id : null,
      SystemProcessId: !firstAsset && firstSystemProcess ? firstSystemProcess.Id : null
    } as ChartAlert;
  });
}

const colorsByAlertGroup = {
  Trip: {
    color: 'rgb(255,70,70)',
    borderColor: 'rgb(191,52,52)'
  },
  Warning: {
    color: 'rgb(255,165,0)',
    borderColor: 'rgb(191,124,0)'
  },
  Info: {
    color: 'rgb(0,205,229)',
    borderColor: 'rgb(0,154,172)'
  },
  Error: {
    color: 'rgb(128,128,128)',
    borderColor: 'rgb(96,96,96)'
  }
} as const;

function getIconIdByAlertGroupName(alertGroupName: AlertGroupName) {
  return alertGroupName === 'Trip'
    ? 'icon-id-exclamation-triangle'
    : alertGroupName === 'Warning'
      ? 'icon-id-exclamation-circle'
      : alertGroupName === 'Info'
        ? 'icon-id-info'
        : alertGroupName === 'Error'
          ? 'icon-id-wifi-off'
          : 'icon-id-exclamation-triangle';
}

function addAlertIconToXRangeSeriesPoint(point: Point, xAxis: Axis, alert: ChartAlert) {
  const lastChild = point.graphic!.element!.lastChild!;
  const pathElement = point.graphic!.element!.firstChild! as SVGPathElement;
  // if the lastChild is not the icon (highcharts adds a path element there by default)
  if (lastChild instanceof SVGPathElement) {
    const iconId = getIconIdByAlertGroupName(alert.AlertGroupName);
    // note: do not nest svgs as it will break highchart's export to image functionality
    (point.graphic!.element as HTMLElement).insertAdjacentHTML('beforeend', `<use href="#${iconId}" />`);
  }
  const iconElement = point.graphic!.element!.lastChild! as SVGElement;
  const x2 = xAxis.toPixels(point.x2!, true);
  const x = xAxis.toPixels(point.x, true);
  const width = Math.abs(x2 - x);
  const y = point.pos(false, undefined)![1];
  const height = pathElement.getClientRects()[0].height;
  const iconHeight = height * 0.8;
  const iconWidth = width > 20 ? 10 : width * 0.5;
  const iconSize = Math.min(iconHeight, iconWidth);
  const iconX = x + (width > 20 ? 5 : width * 0.2);
  const iconY = y - iconSize / 2;
  iconElement.setAttribute('width', String(iconSize));
  iconElement.setAttribute('height', String(iconSize));
  iconElement.setAttribute('x', String(iconX));
  iconElement.setAttribute('y', String(iconY));
  iconElement.setAttribute('fill', point.options.borderColor as string);
}

function interpolate(data: [number, number][]) {
  const resolution = 1000 * 60;
  const cutOff = 1000 * 60 * 30; // 30 minutes
  const interpolatedData: [number, number][] = [];
  for (let i = 0; i < data.length; i++) {
    const point = { x: data[i][0], y: data[i][1] };
    if (i > 0) {
      const lastPoint = { x: data[i - 1][0], y: data[i - 1][1] };
      const deltaY = point.y - lastPoint.y;
      const deltaX = point.x - lastPoint.x;
      if (deltaX > cutOff) {
        interpolatedData.push([lastPoint.x + resolution, 0]);
        interpolatedData.push([point.x - resolution, 0]);
      } else {
        for (let x = lastPoint.x + resolution; x < point.x; x += resolution) {
          const currentXDelta = x - lastPoint.x;
          interpolatedData.push([x, lastPoint.y + deltaY * (currentXDelta / deltaX)]);
        }
      }
    }
    interpolatedData.push([point.x, point.y]);
  }
  return interpolatedData;
}

function lastValueInterpolate(data: [number, number][]) {
  const resolution = 1000 * 60,
    interpolatedData: [number, number][] = [];
  for (let i = 0; i < data.length; i++) {
    const point = { x: data[i][0], y: data[i][1] };
    if (i > 0) {
      const lastPoint = { x: data[i - 1][0], y: data[i - 1][1] };
      for (let x = lastPoint.x + resolution; x < point.x; x += resolution) {
        interpolatedData.push([x, lastPoint.y]);
      }
    }
    interpolatedData.push([point.x, point.y]);
  }
  return interpolatedData;
}

function booleanInterpolate(data: [number, number][]) {
  const interpolatedData: [number, number][] = [];
  for (let i = 0; i < data.length; i++) {
    const point = { x: data[i][0], y: data[i][1] };
    if (i > 0) {
      const lastPoint = { x: data[i - 1][0], y: data[i - 1][1] };
      if (lastPoint.y === 1 && point.y === 0) {
        interpolatedData.push([lastPoint.x, point.y]);
      }
      if (lastPoint.y === 0 && point.y === 1) {
        interpolatedData.push([point.x, lastPoint.y]);
      }
    }
    interpolatedData.push([point.x, point.y]);
  }
  return interpolatedData;
}

function makeDateTimeLabelFormats(withLineBreakOnTheMinute: boolean, withAnyLineBreak: boolean) {
  const br = withAnyLineBreak ? '<br/>' : ' ';
  const minuteBr = withLineBreakOnTheMinute ? '<br/>' : '';
  return {
    millisecond: '%m/%d/%y' + br + '%I:%M:%S.%L %P',
    second: '%m/%d/%y' + br + '%I:%M:%S %P',
    minute: '%m/%d/%y' + minuteBr + ' %I:%M %P',
    hour: '%m/%d/%y' + br + '%I:%M %P',
    day: '%m/%d' + br + '%Y',
    week: 'Week from %A, %b %d, %Y',
    month: '%m/%Y',
    year: '%Y'
  };
}

function formatUnixTimeStamp(unixTimeStamp: number) {
  const a = new Date(unixTimeStamp);
  return format(a, 'MM/dd/yy hh:mm aaa');
}
