import './ComponentLifeComponent/ComponentLifeComponent';
import { type ComponentLifeResponse } from './component-life-types';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ComponentChoiceModalContainer } from './ComponentChoiceModal/ComponentChoiceModal';
import { ReplacementTableDialogContainer } from './ReplacementTableDialog/ReplacementTableDialog';
import { ReplacementEditorDialogContainer } from './ReplacementEditorDialog/ReplacementEditorDialog';
import { ComponentEditorDialogContainer } from './ComponentEditorDialog/ComponentEditorDialog';
import { FirstChangeDueDateEditorDialogContainer } from './FirstChangeDueDateEditorDialog/FirstChangeDueDateEditorDialog';
import { AddAssetTypeComponentDialogComponentContainer } from './AddAssetTypeComponentDialog';
import { ComponentSetupDialogComponentContainer } from './ComponentSetupDialog/ComponentSetupDialog';
import { ConfirmDialog } from '../utils/ConfirmDialog';
import React from 'react';
import { ComponentLifeComponent, openComponentLifeModal } from './ComponentLifeComponent/ComponentLifeComponent';
import { ComponentLifeDataProvider } from './ComponentLifeDataContext';
import { createStandardQueryClient } from '../utils/react-query/client';
import { useListenToPortalTabsClick } from '../portal-tabs-hook';

const queryClient = createStandardQueryClient();

export function initComponentLifeComponent(target: string, dataRequester: () => Promise<ComponentLifeResponse>, fromDashboard: boolean, assetId: number) {
  const root = ReactDOM.createRoot(document.getElementById(target) as HTMLElement);
  document.querySelector('.component-life-container')?.addEventListener('click', async () => openComponentLifeModal());

  root.render(
    <React.StrictMode>
      <ComponentLifeWrapper fromDashboard={fromDashboard} assetId={assetId} dataRequester={dataRequester} />
    </React.StrictMode>
  );
}

type Props = {
  dataRequester: () => Promise<ComponentLifeResponse>;
  fromDashboard: boolean;
  assetId: number;
};
function ComponentLifeWrapper({ fromDashboard, assetId, dataRequester }: Props) {
  const tabBtn = document.querySelector('#tab_rmx_insights_btn');
  const isDashboardTabVisible = tabBtn?.classList.contains('active');
  useListenToPortalTabsClick();
  return (
    <QueryClientProvider client={queryClient}>
      <ComponentLifeDataProvider>
        <ComponentChoiceModalContainer />
        <ReplacementTableDialogContainer />
        <ReplacementEditorDialogContainer />
        <ComponentEditorDialogContainer />
        <FirstChangeDueDateEditorDialogContainer />
        <AddAssetTypeComponentDialogComponentContainer />
        <ComponentSetupDialogComponentContainer />
        <ConfirmDialog />
        <ComponentLifeComponent isVisible={isDashboardTabVisible} fromDashboard={fromDashboard} dataRequester={dataRequester} assetId={assetId} />
      </ComponentLifeDataProvider>
      {/*<ReactQueryDevtools initialIsOpen={true}  />*/}
    </QueryClientProvider>
  );
}

window.initComponentLifeComponent = initComponentLifeComponent;

declare global {
  interface Window {
    initComponentLifeComponent: typeof initComponentLifeComponent;
    openComponentLifeModal: typeof openComponentLifeModal;
  }
}
