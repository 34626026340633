import React, { type CSSProperties, useMemo } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CreatableDropdown.module.scss';
import { typedMemo } from '../react-util';
import { type OnChangeValue } from 'react-select/dist/declarations/src/types';
import clsx from 'clsx';

export interface DropdownOption<Value extends NullableDropDownOptionValue = DropDownOptionValue> {
  value: Value;
  label: string;
  hide?: boolean;
  thumbnailSrc?: string;
  imageSrc?: string;
}
export type DropdownOptions<Value extends NullableDropDownOptionValue = DropDownOptionValue> = Array<DropdownOption<Value>>;

export type DropDownOptionValue<T = any> = T | string | number | boolean;
export type NullableDropDownOptionValue<T = any> = DropDownOptionValue<T> | null;

interface Props<Value extends NullableDropDownOptionValue> {
  options: Array<DropdownOption<Value>>;
  defaultValue?: DropdownOption<Value>;
  disabled?: boolean;
  isClearable?: boolean;
  isValid?: boolean;
  showImagesInOptions?: boolean;
  isInvalid?: boolean;
  onSelect?: (selectedValue: Value) => void;
  onNewValue?: (label: string) => void;
  value?: DropdownOption<Value> | null;
  className?: string;
  nonFormikError?: string;
  placeholder?: string;
  style?: CSSProperties;
  loggingEnabled?: boolean;
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={'search'} />
      </components.DropdownIndicator>
    )
  );
};

export const CreatableDropdown = typedMemo(<Value extends NullableDropDownOptionValue>(props: Props<Value>) => {
  const { onSelect, options, defaultValue, disabled, onNewValue } = props;
  const className = clsx(props.className, styles['dropdown'], props.isValid ? '-valid' : '-invalid');

  const handleChange = useMemo(
    () => (selectedOption: OnChangeValue<DropdownOption<Value>, false>) => {
      console.log('handleChange', selectedOption);
      if (onSelect) {
        const option = selectedOption as DropdownOption<Value>;
        const value = option ? option.value : (null as unknown as Value);
        onSelect(value);
      }
    },
    [onSelect]
  );

  return (
    <>
      <CreatableSelect
        className={className}
        // errorText={props.isInvalid}
        components={{ DropdownIndicator }}
        value={props.value}
        onChange={handleChange}
        options={options.filter((d) => d.hide !== true)}
        defaultValue={defaultValue}
        isDisabled={disabled}
        placeholder={props.placeholder}
        onCreateOption={onNewValue}
        menuPortalTarget={document.body}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: 'orangered',
            primary25: '#ced4da',
            primary: '#3b5872'
          }
        })}
        minMenuHeight={500}
        isClearable={props.isClearable || false}
        styles={{
          container: (provided) => ({
            ...provided,
            ...(props.isInvalid
              ? {
                  border: '1px solid red',
                  borderRadius: '4px'
                }
              : {}),
            ...(props.style ? props.style : {})
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 9999, color: 'black' })
        }}
      />
      {props.nonFormikError ? <div className={'Input_form-errors__1w0oO'}>{props.nonFormikError}</div> : null}
    </>
  );
});
