import { type MenuConfig } from '../menu-modal/MenuModal/MenuModal';
import { createContext, useContext } from 'react';

// Creating a new context to pass through the component tree without having to pass it through props
const RmxServiceMenuContext = createContext<OpenRmxServiceMenu | null>(null);

// This allows any React component that wraps its children with <RmxServiceMenuProvider> to provide the RmxServiceMenu context value to all its child components.
export const RmxServiceMenuProvider = RmxServiceMenuContext.Provider;

// async function that will open the RmxServiceMenu
export type OpenRmxServiceMenu = (extra: Partial<MenuConfig<number>>, assetId?: number, assetName?: string) => Promise<void>;

// Custom hook to use the context
export function useRmxServiceMenu() {
  const fn = useContext(RmxServiceMenuContext);

  if (!fn) {
    throw new Error('useRmxServiceMenu must be used within a RmxServiceMenuProvider');
  }

  return { openRmxServiceMenu: fn };
}
