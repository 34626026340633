import { type ModalState, useModal } from '../../hooks/useModal';
import { addComponentReset, updateReplacementDate } from '../api';
import React, { useState } from 'react';
import { ModalHoc } from '../../utils/Modal/ModalHoc';
import { DateInput } from '../../utils/DateInput';
import { OpenConfirmDialog } from '../../utils/ConfirmDialog';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { type ComponentLifeModel } from '../component-life-types';
import { handleAxiosError } from '../../utils/http';
import { useComponentLifeData } from '../ComponentLifeDataContext';

export let OpenLastChangeModal: (r: LastChangeModalParam) => void = (_) => {};

export function ReplacementEditorDialogContainer() {
  const modal = useModal<LastChangeModalParam, undefined>();
  OpenLastChangeModal = (r: LastChangeModalParam) => modal.open(r);
  return <ReplacementEditorDialog state={modal} />;
}

type OnCompleteHandler = () => void;
export type LastChangeModalParam = {
  row: ComponentLifeModel;
  editDate?: Date;
  addReplacement: boolean;
  onComplete?: OnCompleteHandler;
};
type LastChangeModalProps = {
  state: ModalState<LastChangeModalParam, undefined>;
};
export const ReplacementEditorDialog = ModalHoc(({ state }: LastChangeModalProps) => {
  const { updateComponentLifeRow } = useComponentLifeData();
  const onComplete = state.param?.onComplete;
  const row = state.param?.row;
  const editDate = state.param?.editDate;
  const addReplacement = state.param?.addReplacement || false;
  const [date, setDate] = useState<Date | null>(state.param?.editDate ?? new Date());
  const [submitting, setSubmitting] = useState(false);
  const submitComponentLastChanged = async (row: ComponentLifeModel, removeReset: boolean, addReplacement: boolean): Promise<void> => {
    const componentId = row.ComponentId;
    if (!removeReset && date === null) {
      alert('Please select a date');
      return;
    }
    try {
      setSubmitting(true);
      const result = addReplacement ? await addComponentReset(componentId, date!) : await updateReplacementDate(componentId, editDate!, date!);
      updateComponentLifeRow(result);
      if (onComplete) {
        onComplete();
      }
      state.close();
    } catch (error) {
      alert(handleAxiosError(error, { serverError: 'Failed to update replacement date due to a server error. Our development team has been notified.' }));
    } finally {
      setSubmitting(false);
    }
  };
  const confirmSubmit = () =>
    OpenConfirmDialog({
      title: 'Confirm Reset',
      message: 'Are you sure you want to reset this component? This will affect the component life prediction algorithm.',
      onConfirm: async () => {
        await submitComponentLastChanged(row!, false, addReplacement);
      }
    });

  return (
    <GenericModal
      shrinkMode={true}
      hideFooter={true}
      open={state.isOpen}
      setIsOpen={state.setIsOpen}
      label={addReplacement ? 'Add Replacement' : 'Update Replacement'}
    >
      <div className='form-group'>
        <label style={{ display: 'block' }} htmlFor='dialog-component-change__date'>
          Replacement Date
        </label>
        <DateInput value={date} onChange={setDate} />
      </div>
      {!addReplacement && (
        <div className='d-flex flex-row align-items-center mb-4' style={{ maxWidth: '150px' }}>
          <i className='fas fa-exclamation-triangle text-warning mr-2' style={{ fontSize: '16px', display: 'flex' }} />
          <span>Only edit this date if the last time this component was replaced is wrong</span>
        </div>
      )}
      <div className='row mt-2'>
        <div className='col-6 d-flex'>
          <button onClick={confirmSubmit} disabled={submitting} className='btn-primary btn btn-small ml-auto text-white'>
            {submitting ? 'Updating...' : addReplacement ? 'Submit' : 'Update'}
          </button>
        </div>
        <div className='col-6'>
          <button onClick={() => state.close()} className='btn-warning btn btn-small ml-auto text-white'>
            Cancel
          </button>
        </div>
      </div>
    </GenericModal>
  );
});
