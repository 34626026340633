import React, { type CSSProperties, type PropsWithChildren } from 'react';

export function WarningText(props: PropsWithChildren & { className?: string; style?: CSSProperties }) {
  return (
    <div className={`d-flex flex-row align-items-center mb-4 ${props.className ?? ''}`} style={props.style}>
      <i className='fas fa-exclamation-triangle text-warning mr-2' style={{ fontSize: '16px', display: 'flex' }} />
      <span>{props.children}</span>
    </div>
  );
}
