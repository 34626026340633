import './buildChart';
import './applyTheme';
import './getChart';
import './imageExporter';
import { createStandardAxios } from '../utils/ajax';
import type { ChartData } from './charting.types';

const chartApi = createStandardAxios();

export type GraphSelection = {
  installationModelId: number;
  dataPointId: number;
};

async function getChartData(systemProcessDataPoints: GraphSelection[], assetDataPoints: GraphSelection[], start: string, end: string) {
  return (
    await chartApi.post<{ data?: ChartData; hasdata: boolean }>('/Charts/GetChartData', {
      SystemProcessDataPoints: systemProcessDataPoints.map((s) => ({ InstallationModelId: s.installationModelId, DataPointId: s.dataPointId })),
      AssetDataPoints: assetDataPoints.map((a) => ({ InstallationModelId: a.installationModelId, DataPointId: a.dataPointId })),
      Start: start,
      End: end
    })
  ).data;
}

// @ts-ignore
window.getChartDataAsync = getChartData;
