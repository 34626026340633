import Compressor from 'compressorjs';

export function compressImageFile(file: File) {
  return new Promise<Blob>((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      success: (result) => {
        console.log('compressed image', result);
        return resolve(result as Blob);
      },
      error: (err: Error) => {
        reject(err);
      }
    });
  });
}

export function compressImageFiles(files: File[]) {
  return Promise.all(files.map(compressImageFile));
}
