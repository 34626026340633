import type { Quote } from '../Service.types';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import React from 'react';

const col = createServerColumnHelper<Quote>().accessor;

interface Props {
  initializeAssetFilter: boolean;
  setAssetFilter: (assetId: number | null) => void;
}

export const quoteColumnsDef = (props: Props) => [
  col('status', 'string', {
    meta: { serverFilters: ['text-contains'] },
    header: 'Status',
    enableColumnFilter: true,
    enableSorting: true,
    sortDescFirst: false,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id
  }),
  col('quoteCrmId', 'string', {
    meta: { serverFilters: ['text-contains'] },
    header: 'Quote Id',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('type', 'string', {
    meta: { serverFilters: ['text-contains'] },
    header: 'Quote Type',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('customerName', 'string', {
    meta: { serverFilters: ['text-contains'] },
    header: 'Customer',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('description', 'string', {
    meta: { serverFilters: ['text-contains'] },
    header: 'Description',
    cell: (info) => {
      const description = info.getValue();
      const maxLength = 100;
      const trimmedDescription = description && description.length > maxLength ? `${description.substring(0, maxLength)}...` : description;

      return <span>{trimmedDescription}</span>;
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('quoteTotal', 'number', {
    meta: { serverFilters: ['text-contains'] },
    header: 'Quote Value',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('assetName', 'string', {
    meta: {
      serverFilters: ['text-contains'],
      initializeFilter: props.initializeAssetFilter,
      // when the column has been filtered and the user clicks on the filter input
      onFilteredFocus: () => {
        props.setAssetFilter(null);
      }
    },
    header: 'Asset',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('createdByName', 'string', {
    meta: { serverFilters: ['text-contains'] },
    header: 'Created By',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('createdAt', 'date', {
    meta: { serverFilters: ['exact-date'] },
    header: 'Created At',
    cell: (info) => {
      return <div>{info.getValue()?.toLocaleDateString()}</div>;
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true,
    enableSorting: true,
    sortDescFirst: true
  }),
  col('expiresAt', 'date', {
    meta: { serverFilters: ['exact-date'] },
    header: 'Expiration Date',
    cell: (info) => {
      return <div>{info.getValue()?.toLocaleDateString()}</div>;
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true,
    enableSorting: true,
    sortDescFirst: true
  })
];
