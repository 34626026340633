import type { DropdownOption } from '../SearchableDropdown/SearchableDropdown';
import Select from 'react-select';
import React from 'react';

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly DropdownOption<number>[];
}
interface Props {
  value: DropdownOption<number> | undefined;
  options: { label: string; options: DropdownOption<number>[] }[];
  onChange: (value: DropdownOption<number>) => void;
  disabled?: boolean;
}

export function GroupedSectionedSelect({ value, options, onChange, disabled }: Props) {
  return (
    <Select<DropdownOption<number>, false, GroupedOption>
      value={value}
      options={options}
      formatGroupLabel={formatGroupLabel}
      // menuIsOpen={true}
      classNames={{
        // control: () => 'bg-dark border-dark'
        // option: () => 'bg-dark'
        multiValue: () => 'text-white',
        input: () => 'text-white',
        valueContainer: () => 'text-white',
        placeholder: () => 'text-white',
        singleValue: () => 'text-white'
        // : () => 'bg-dark'
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: '#515151',
          borderColor: '#515151',
          color: 'white'
        }),
        option: (provided, options) => ({
          ...provided,
          backgroundColor: options.isFocused ? '#8c8c8c' : '#515151',
          color: 'white'
        }),
        groupHeading: (provided) => ({
          ...provided,
          color: 'white'
        }),
        menuList: (provided) => ({
          ...provided,
          backgroundColor: '#515151',
          color: 'white'
        })
      }}
      onChange={async (e) => {
        if (e) {
          onChange(e);
        }
      }}
      isDisabled={disabled ?? false}
    />
  );
}

const formatGroupLabel = (data: GroupedOption) =>
  data.label === '' ? null : (
    <div>
      <h5 className='text-white text-center'>{data.label}</h5>
    </div>
  );
