import type { ColumnDef } from '@tanstack/react-table';
import { throwErr } from '../../utils';
import type { AccessorKeyColumnDef } from '@tanstack/table-core/src/types';

export function getColumnAccessorKey<T>(columnDef: ColumnDef<T, any>) {
  return (columnDef as AccessorKeyColumnDef<T, unknown>).accessorKey as string | undefined;
}

export function getColumnName<T>(columnDef: ColumnDef<T, any>) {
  return getColumnAccessorKey(columnDef) ?? columnDef.id ?? throwErr<string>('Column must have an id or accessorKey. ' + JSON.stringify(columnDef));
}
