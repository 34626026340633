import { Component, type ReactNode } from 'react';
import { reportException } from '../../util/sentryProvider';


interface ErrorBoundaryState {
  error: unknown | undefined;
}

/**
 * Displays nothing when an error happens
 */
export class NullErrorBoundary extends Component<{ children: ReactNode }, ErrorBoundaryState> {
  state: ErrorBoundaryState = { error: undefined };

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    reportException(error);
  }

  render() {
    if (this.state.error !== undefined) {
      return null;
    }
    return this.props.children;
  }
}
