import React, { type PropsWithChildren } from 'react';
import { Table } from 'react-bootstrap';

interface Props extends PropsWithChildren {}

export function RmxServiceDetails({ children }: Props) {
  return (
    <>
      <div>
        <Table striped={false} bordered={false}>
          <tbody>{children}</tbody>
        </Table>
      </div>
    </>
  );
}
