import React, { type CSSProperties, type ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';
import s from './GrayButton.module.scss';
type GrayButtonProps = {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
};
export function GrayButton(props: GrayButtonProps) {
  const { children, ...buttonProps } = props;
  return (
    <Button type='button' {...buttonProps} className={clsx(buttonProps.className, s['gray-button'])}>
      {children}
    </Button>
  );
}
