import { useQuery } from '@tanstack/react-query';
import { type FormSchemaModel, getLatestFormSchemas } from '../api';
import { CenteredSpinner } from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import { LazyFormBuilder } from './LazyFormBuilder';
import { type CSSProperties, type Dispatch, type SetStateAction, Suspense, useState } from 'react';
import type { FormSchema, FormSchemaFormValues } from './FormBuilder';

const listStyles: CSSProperties = { paddingBottom: '1rem', fontSize: '1rem' };
export function FormBuilderPage() {
  const [selectedForm, setSelectedForm] = useState<FormSchemaFormValues | null>(null);
  const { data: latestFormSchemas, isFetching } = useQuery({
    queryKey: ['form-schemas'],
    queryFn: getLatestFormSchemas
  });

  return isFetching ? (
    <CenteredSpinner />
  ) : selectedForm ? (
    <Suspense fallback={<CenteredSpinner />}>
      <LazyFormBuilder form={selectedForm} setFormValues={setSelectedForm as Dispatch<SetStateAction<FormSchemaFormValues>>} />{' '}
    </Suspense>
  ) : (
    <div style={{ padding: '2rem' }}>
      <ul>
        {latestFormSchemas!.map((s) => (
          <li style={listStyles} key={s.formSchemaId}>
            <a href='#' onClick={() => setSelectedForm(convertFormSchemaModelToFormSchemaFormValues(s))}>
              Edit {s.formName} Version {s.version}
            </a>
          </li>
        ))}
        <li style={listStyles}>
          <a href='#' onClick={() => setSelectedForm(convertFormSchemaModelToFormSchemaFormValues())}>
            Create new Form
          </a>
        </li>
      </ul>
    </div>
  );
}

function convertFormSchemaModelToFormSchemaFormValues(model?: FormSchemaModel): FormSchemaFormValues {
  return {
    existing: !!model,
    formSchema: model?.schema ? (JSON.parse(model?.schema) as FormSchema) : { components: [] },
    formName: model?.formName ?? '',
    dealerId: model?.dealerId ? parseInt(model?.dealerId) : 1, // air systems
    formSchemaId: model?.formSchemaId
  };
}
