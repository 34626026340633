import { type ModalState, useModal } from '../../hooks/useModal';
import { updateFirstChangeDate } from '../api';
import React, { useState } from 'react';
import { ModalHoc } from '../../utils/Modal/ModalHoc';
import { DateInput } from '../../utils/DateInput';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { type ComponentLifeModel } from '../component-life-types';
import { useComponentLifeData } from '../ComponentLifeDataContext';
import { handleAxiosError } from '../../utils/http';

export let OpenFirstChangeDueDateEditorDialog: (r: FirstChangeEditorDialogParam) => void = (_) => {};

export function FirstChangeDueDateEditorDialogContainer() {
  const modal = useModal<FirstChangeEditorDialogParam, undefined>();
  OpenFirstChangeDueDateEditorDialog = (r: FirstChangeEditorDialogParam) => modal.open(r);
  return <FirstChangeDueDateEditorDialog state={modal} />;
}

type OnCompleteHandler = (componentLifeModel: ComponentLifeModel) => void;

export type FirstChangeEditorDialogParam = {
  row: ComponentLifeModel;
  originalFirstChangeDate: Date;
  onComplete?: OnCompleteHandler;
};
type FirstChangeEditorDialogProps = {
  state: ModalState<FirstChangeEditorDialogParam, undefined>;
};
export const FirstChangeDueDateEditorDialog = ModalHoc(({ state }: FirstChangeEditorDialogProps) => {
  const { updateComponentLifeRow } = useComponentLifeData();
  const onComplete = state.param?.onComplete;
  const row = state.param?.row;
  const [date, setDate] = useState<Date | null>(state.param?.originalFirstChangeDate ?? null);
  const [submitting, setSubmitting] = useState(false);
  const submitFirstChangeDueDate = async (): Promise<void> => {
    const componentId = row!.ComponentId;
    if (date === null) {
      alert('Please select a date');
      return;
    }
    if (date < new Date()) {
      alert('Please select a future date.');
      return;
    }
    try {
      setSubmitting(true);
      const result = await updateFirstChangeDate(componentId, date);
      updateComponentLifeRow(result);
      if (onComplete) {
        onComplete(result);
      }
      state.close();
    } catch (error) {
      alert(handleAxiosError(error, { serverError: 'Failed to update first change date due to a server error. Our development team has been notified.' }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <GenericModal open={state.isOpen} setIsOpen={state.setIsOpen} label={'Update First Change Date'} hideFooter={true} shrinkMode={true}>
      <>
        <div className='form-group'>
          <label style={{ display: 'block' }} htmlFor='dialog-component-change__date'>
            Replacement Date
          </label>
          <DateInput value={date} onChange={setDate} />
        </div>
        <div className='row mt-2'>
          <div className='col-6 d-flex'>
            <button onClick={submitFirstChangeDueDate} disabled={submitting} className='btn-primary btn btn-small ml-auto text-white'>
              {submitting ? 'Updating...' : 'Update'}
            </button>
          </div>
          <div className='col-6'>
            <button onClick={() => state.close()} className='btn-warning btn btn-small ml-auto text-white'>
              Cancel
            </button>
          </div>
        </div>
      </>
    </GenericModal>
  );
});
