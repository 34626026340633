import React, { type ComponentType } from 'react';
import { type ModalState } from '../../hooks/useModal';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ModalHoc<TProps extends { state: ModalState<any, any> }>(Component: ComponentType<TProps>) {
  const ModalWrapped = (props: TProps) => {
    return props.state.isOpen ? <Component {...props} /> : null;
  };
  ModalWrapped.displayName = `ModalHoc_${Component.displayName}`;
  return ModalWrapped;
}
