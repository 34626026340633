import { parse } from 'date-fns';

type Hour = number;
type Minute = number;
type Second = number;
export type TimeSpan = `${Hour}:${Minute}:${Second}`;

export function parseTimeSpan(timeSpan: TimeSpan, date: Date = new Date()): Date {
  return parse(timeSpan, 'HH:mm:ss', date);
}

export function formatDateToTimeSpan(date: Date): TimeSpan {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours}:${minutes}:00`;
}

export class TimeSpanInstance {
  private readonly hour: number;
  private readonly minute: number;
  private readonly second: number;

  constructor(time: TimeSpan) {
    const parts = time.split(':');
    this.hour = parseInt(parts[0]);
    this.minute = parseInt(parts[1]);
    this.second = parseInt(parts[2]);
  }

  getHour(): number {
    return this.hour;
  }

  getMinute(): number {
    return this.minute;
  }

  getSecond(): number {
    return this.second;
  }
}
