import React from 'react';
import { Form } from 'react-bootstrap';
import { type IRmxServiceApi } from '../api';
import { VerticalInputRow } from '../../../components/util/form-components/VerticalInputRow';
import { useDebounceRequest } from '../../../hooks/useDebounceRequest';
import { GrowingTextArea } from '../../utils/GrowingTextArea';

interface Props {
  api: IRmxServiceApi;
  activityId: string;
  workScopeText: string;
  setWorkScopeText: (value: string) => void;
  enabled: boolean;
}

export function RmxServiceScope({ api, enabled, activityId, workScopeText, setWorkScopeText }: Props) {
  const { handleDebounceRequest, statusInfo } = useDebounceRequest({
    fn: async (value: string, signal) => {
      await api.updateActivityScope(activityId, value, signal);
    },
    debounceTime: 1000
  });

  return (
    <VerticalInputRow label='Scope'>
      <GrowingTextArea
        value={workScopeText}
        setValue={async (val) => {
          setWorkScopeText(val);
          await handleDebounceRequest(val);
        }}
        readOnly={!enabled}
      />
      {/* Conditionally render the "Saving" message */}
      <Form.Text style={{ color: statusInfo?.color, height: '1.5em', fontSize: '0.9rem' }}>{statusInfo?.text}</Form.Text>
    </VerticalInputRow>
  );
}
