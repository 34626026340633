import { isNullOrUndefined, numberOr } from './index';

export function parseTime(value: string): string | null {
  if (isNullOrUndefined(value)) return null;
  value = value.trim();
  if (value.endsWith('AM')) {
    value = value.replace('AM', '');
  }
  let isPm = false;
  if (value.endsWith('PM')) {
    value = value.replace('PM', '');
    isPm = true;
  }
  const containsInvalidInput = value
    .split(':')
    .map((s) => s.trim())
    .some((s) => isNaN(Number(s)));
  if (containsInvalidInput) return null;
  const parsedParts = value
    .split(':')
    .map((n) => Math.floor(numberOr(n, 0)))
    .map((n) => n.toString().padStart(2, '0'));
  if (parsedParts.length < 3) {
    for (let i = parsedParts.length; i < 3; i++) parsedParts.push('00');
  }
  if (isPm && parseInt(parsedParts[0]!) !== 12) {
    parsedParts[0] = (parseInt(parsedParts[0]!) + 12).toString().padStart(2, '0');
  }
  if (parseInt(parsedParts[0]!) > 23 || parseInt(parsedParts[1]!) > 59 || parseInt(parsedParts[2]!) > 59) return null;
  if (parseInt(parsedParts[0]!) < 0 || parseInt(parsedParts[1]!) < 0 || parseInt(parsedParts[2]!) < 0) return null;
  return parsedParts.join(':');
}

export function formatTimeForUser(standardFormattedTime: string) {
  const parsedParts = standardFormattedTime.split(':').map((n) => Math.floor(numberOr(n, 0)));
  let isPm = false;
  if (parsedParts[0]! > 12) {
    parsedParts[0] -= 12;
    isPm = true;
  }
  const suffix = isPm ? ' PM' : ' AM';
  return `${parsedParts[0]!.toString().padStart(2, '0')}:${parsedParts[1]!.toString().padStart(2, '0')}${
    parsedParts[2] !== 0 ? `:${parsedParts[2]!.toString().padStart(2, '0')}` : ''
  }${suffix}`;
}
