import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import s from './DateInput.module.scss';
import clsx from 'clsx';
import { flip, shift } from '@floating-ui/dom';
import { PortalToBody } from '../../components/util/PortalToBody';

type Props = {
  value: Date | null;
  onChange: (date: Date | null) => void;
  tabIndex?: number;
  id?: string;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  disabled?: boolean;
};

export function DateInput({ value, onChange, tabIndex, id, className, placeholder, isClearable, disabled }: Props) {
  return (
    <DatePicker
      className={clsx('form-control', className)}
      popperClassName={s['popper-container']}
      tabIndex={tabIndex}
      disabled={disabled}
      placeholderText={placeholder}
      popperContainer={PortalToBody}
      selected={value}
      isClearable={isClearable}
      id={id}
      onKeyDown={(e) => e.preventDefault()}
      onChange={onChange}
      popperModifiers={[shift(), flip()]}
    />
  );
}
