import type { Column, Table } from '@tanstack/react-table';
import type { TableState } from '../hooks';
import { DateInput } from '../../../utils/DateInput';
import React from 'react';
import s from './DateColumnFilter.module.scss';
import clsx from 'clsx';
import dtStyles from '../DataTable.module.scss';

export function DateColumnFilter<T>(props: { column: Column<any, any>; table: Table<any>; tableState: TableState<T> }) {
  const { column } = props;
  return (
    <DateInput
      className={clsx(s['date-input'], dtStyles['input-box'])}
      isClearable={true}
      placeholder='Search...'
      value={(column.getFilterValue() as Date | undefined) ?? null}
      onChange={(value) => column.setFilterValue(value)}
    />
  );
}
