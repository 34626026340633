/* eslint-disable */
import { MenuConfig } from './MenuModal';
import { ActionConfig } from '../ActionModal/ActionModal';

/**
 * This File contains  the company and site menu modals, action modals, and indicator modals
 * initialized in the CompanyTiles and the SiteTiles pages
 */
// @ts-ignore
window.initializeMenuModal = () => {
  function getCompanyOrSiteNameAsync(id: string, type: string): Promise<string> {
    // console.log(`id: ${id}, type: ${type}`)
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: type === 'Site' ? '/Alerts/GetSiteName' : '/Alerts/GetCompanyName',
        cache: false,
        async: false,
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { data: string }) {
          resolve(oData.data);
        }
      });
    });
  }

  function getSiteIntIdByGuid(uuid: string): Promise<number> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Site/RmxId',
        cache: false,
        async: false,
        data: { uuid: uuid },
        dataType: 'json',
        success: function (oData: number) {
          resolve(oData);
        }
      });
    });
  }

  function getAlertDetailsAsync(id: string, type: string): Promise<any> {
    return new Promise((resolve) => {
      $.ajax({
        url: type === 'Site' ? '/Alerts/GetSiteAlertsMouseHover' : '/Alerts/GetCompanyAlertsMouseHover',
        method: 'get',
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { havedata: boolean; data: string }) {
          if (oData.havedata === true) {
            resolve(oData);
          }
        },
        error: function (result) {}
      });
    });
  }

  async function ViewAlertDetails(id: string, type: string): Promise<any> {
    try {
      $('#dialog-actions').hide();
      $('#dialog-actions').dialog('close');
    } catch (e) {}
    try {
      const oData = await getAlertDetailsAsync(id, type);
      // @ts-ignore
      $('#dialog-alert-details').html(oData.data);
      $('#dialog-alert-details').show();
      $(function () {
        $('#dialog-alert-details').dialog({
          autoOpen: true,
          dialogClass: 'no-close',
          resizable: false,
          height: 'auto',
          width: 400,
          modal: true,

          // @ts-ignore
          fluid: true, //new option
          open: function () {
            jQuery('.ui-widget-overlay').on('click', function () {
              jQuery('#dialog-alert-details').dialog('close');
            });
          },
          buttons: [
            {
              text: 'OK',
              class: 'btn btn-block btn-lg',
              click: function () {
                $(this).dialog('close');
              }
            }
          ],
          show: {
            effect: 'drop',
            duration: 500
          },
          hide: {
            effect: 'clip',
            duration: 500
          }
        });
      });
      $('.selector').dialog({
        closeOnEscape: false
      });
    } catch (err) {}
  }

  let orgId = '';
  let orgType = '';
  const modal_title = '';

  function getInstallationName(companyName: string, siteName: string): Promise<string> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Home/GetInstallationName',
        cache: false,
        async: false,
        data: {
          CompanyName: companyName,
          SiteName: siteName
        },
        success: function (oData) {
          resolve(oData.data);
        }
      });
    });
  }

  async function openCompanyOrSiteMenuModal(id: string, type: string, modalTitle: string) {
    const url = new URL(window.location.href);
    let menuConfig: MenuConfig<any> = { getLabel: () => modalTitle };
    let actionConfig: ActionConfig<any> = {};

    // these are required to be defined or else bypasses will not work
    orgId = id;
    orgType = type;

    if (type === 'Site') {
      // if we are at the site level

      // get the company id from any tile
      const companyId = window.PageParams.CompanyId;
      const siteId = id;
      menuConfig = {
        open: () => {
          window.location.href = `${window.origin}?p=InstallationPage&pid=${siteId}`;
        },
        alerts: () => {
          ViewAlertDetails(id, type);
        },
        flag: () => {},
        graphing: () => {
          window.location.href = `${window.origin}?p=ChartingPrefill&sId=${siteId}`;
        },
        reports: () => {
          window.location.href = `${window.origin}?p=SystemAssessment&cId=${companyId}&sId=${siteId}`;
        },
        getLabel: () => modalTitle,

        bypass: () => {
          // @ts-ignore
          window.orgId = id;
          // @ts-ignore
          window.orgType = type;
          AddByPass();
        },
        edit: () => {
          // @ts-ignore
          openNotesDialog(id, type);
        }
      };
    } else if (type === 'Company') {
      // if we are at the company level
      menuConfig = {
        open: () => {
          window.location.href = `${window.origin}?p=SiteTiles&pid=${id}`;
        },

        // action: () => {
        // }, // empty action handler used to show the action button
        alerts: () => {
          ViewAlertDetails(id, type);
        },
        // history: () => {}, // coming soon
        graphing: () => {
          window.location.href = `${window.origin}?p=ChartingPrefill&cId=${id}`;
        },
        getLabel: () => modalTitle,
        // change: () => {},
        // snooze: () => {}, // coming soon
        edit: () => {
          // @ts-ignore
          openNotesDialog(id, type);
        } // coming soon
      };
      actionConfig = {
        view_requests: () => {
          // @ts-ignore
          openViewRequestsDialog(id, type);
        },
        submit_requests: () => {
          // @ts-ignore
          window.openSubmitRequestDialogFor(id, type);
        },
        // change: () => {},
        // snooze: () => {}, // coming soon
        edit: () => {
          // @ts-ignore
          openNotesDialog(id, type);
        } // coming soon
        // add_bypass: () => {}, // bypasses don't exist at the company level
        // clear_bypass: () => {}, // bypasses don't exist at the company level
      };
    }

    if (!window.User.IsRmxServiceEnabled) {
      delete menuConfig.rmx_service;
      delete menuConfig.flag;
    }

    window.OpenMenuModal(modalTitle, menuConfig, actionConfig);
  }

  // @ts-ignore
  window.OpenIndicatorMenuModal = async function (id: string, type: string) {
    const url = new URL(window.location.href);
    const modalTitle = await getCompanyOrSiteNameAsync(id, type);

    // get the company id from any tile
    const element = document.querySelector(`.colored-tile`);
    // console.log(element)
    const anchor = element!.querySelector('a.hoverDarken');
    const href = anchor!.getAttribute('href');
    const ids = href!.split('pid=')[1];

    const companyId = type === 'Site' ? window.PageParams.CompanyId : id;
    // these are required to be defined or else bypasses will not work
    orgId = id;
    orgType = type;
    const menuConfig: MenuConfig<any> = {
      // view_requests: () => {
      //   // view requests
      //   // @ts-ignore
      //   openViewRequestsDialog(id, type);
      // },
      // // action: () => {}, // empty action handler used to show the action button
      // request: () => {
      //   // @ts-ignore
      //   window.openSubmitRequestDialogFor(id, type);
      // },
      alerts: () => (window.location.href = `${url.origin}?p=Alerts&c=${companyId}`),
      graphing: () => {
        window.location.href = `${window.origin}?p=ChartingPrefill&cId=${companyId}`;
      },
      getLabel: () => modalTitle
      // history: () =>{}
    };

    const actionConfig: ActionConfig<any> = {
      view_requests: () => {
        // @ts-ignore
        window.openViewRequestsDialog(id, type);
      },
      submit_requests: () => {
        // @ts-ignore
        window.openSubmitRequestDialogFor(id, type);
      }
      // snooze: () => {}, // coming soon
      // edit: () => {openNotesDialog(id, type)},
      // add_bypass: () => {}, // bypasses don't exist at the company level
      // clear_bypass: () => {}, // bypasses don't exist at the company level
    };

    window.OpenMenuModal(modalTitle, menuConfig, actionConfig);
  };

  //@ts-ignore
  window.ShowActionModal = async function (id, type) {
    const modal_title = await getCompanyOrSiteNameAsync(id, type);
    // new menu modal
    await openCompanyOrSiteMenuModal(id, type, modal_title);
  };

  function AddByPass() {
    window.performShowByPass(modal_title);
  }

  // @ts-ignore
  window.Manage = function () {
    // @ts-ignore
    if (window.isTabFormat && window.currentSelectedTab === 'tab_active_alerts') {
      //@ts-ignore
      window.ManageAlert();
    } else {
      ManageFromTiles();
    }
  };

  function ManageFromTiles() {
    window.performAddBypass(orgType === 'Site' ? '/Alerts/ManageSite' : '/Alerts/ManageCompany', orgType === 'Site' ? { SiteID: orgId } : { CompanyID: orgId });
  }
};
