import * as Sentry from '@sentry/react';
let sentryEnabled = false;
export function initSentry() {
  const dsn = import.meta.env.VITE_SENTRY_DSN;
  if(dsn) {
    sentryEnabled = true;
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN
    });
  }
}

export function setSentryUser(email: string) {
  if(sentryEnabled) {
    Sentry.setUser({ email: email });
  }
}

export function reportException(e: any) {
  console.error(e);
  Sentry.captureException(e);
}
