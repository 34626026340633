import ReactDOM from 'react-dom/client';
import React from 'react';
import { LazyFormRenderer } from './LazyFormRenderer';
import { QueryClientProvider } from '@tanstack/react-query';
import { createStandardQueryClient } from '../../utils/react-query/client';
import { GlobalConfirmDialogProvider } from 'src/portal/utils/GlobalConfirmDialog';

const queryClient = createStandardQueryClient();

// @ts-ignore
window.BootstrapFormRenderer = (targetId: string) => {
  const root = ReactDOM.createRoot(document.getElementById(targetId) as HTMLElement);
  root.render(
    <React.StrictMode>
      <GlobalConfirmDialogProvider>
        <QueryClientProvider client={queryClient}>
          <LazyFormRenderer />
        </QueryClientProvider>
      </GlobalConfirmDialogProvider>
    </React.StrictMode>
  );
};
