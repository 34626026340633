import React, { useEffect, useState } from 'react';
import { Nav, Spinner, Tab } from 'react-bootstrap';
import s from './Tabs.module.scss';
import clsx from 'clsx';

export interface ITab {
  key: string;
  title: string;
  content: any;
  loading?: boolean;
  hide?: boolean;
}

interface TabsContainerProps {
  tabs: ITab[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  withScroll?: boolean;
  noContentPadding?: boolean;
  noMargin?: boolean;
  growContent?: boolean;
}

export function Tabs({ tabs, setActiveTab, activeTab, withScroll, noContentPadding, noMargin, growContent }: TabsContainerProps) {
  const filteredTabs = tabs.filter((tab) => !tab.hide);
  const [openedTabs, setOpenedTabs] = useState<string[]>([]);

  useEffect(() => {
    if (activeTab && !openedTabs.includes(activeTab)) {
      setOpenedTabs((s) => [...s, activeTab]);
    }
  }, [activeTab, openedTabs]);
  const handleSelect = (k: string) => setActiveTab(k);
  return (
    <Tab.Container id='tabs-container' activeKey={activeTab} onSelect={(e) => handleSelect(e as string)}>
      <Nav variant='tabs' className='flex-row flex-shrink-0'>
        {filteredTabs.map((tab, index) => (
          <React.Fragment key={tab.key}>
            <Nav.Item key={tab.key}>
              <Nav.Link style={{ color: 'inherit', borderColor: 'transparent' }} eventKey={tab.key}>
                {tab.title}
              </Nav.Link>
            </Nav.Item>
            {index === filteredTabs.length - 1 && tabs.find((t) => t.loading) && (
              <Nav.Item key='loading'>
                <Nav.Link disabled={true} style={{ color: 'inherit', borderColor: 'transparent' }}>
                  <Spinner style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} size={'sm'} />
                </Nav.Link>
              </Nav.Item>
            )}
          </React.Fragment>
        ))}
      </Nav>
      <Tab.Content
        className={clsx(
          s['tab-content'],
          withScroll && 'd-flex flex-column overflow-auto',
          noContentPadding && 'p-0',
          noMargin && 'mb-0',
          growContent && 'flex-grow-1',
          'flex-shrink-1'
        )}
      >
        {filteredTabs.map((tab) => (
          <Tab.Pane key={tab.key} eventKey={tab.key}>
            {(tab.key === activeTab || openedTabs.includes(tab.key)) && tab.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
}
