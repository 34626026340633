import { createStandardAxios } from '../utils/ajax';
import { formatDateShort, parseAspNetDateTime } from '../utils';
import {
  type AssetComponentReset,
  type AssetComponentResetResponse,
  type AssetTypeComponent,
  type ComponentLifeModel,
  type ComponentLifeModelResponse,
  type ComponentLifeResponse,
  type PartVendor,
  type UpsertComponentDto
} from './component-life-types';

const api = createStandardAxios();

export async function upsertComponent(request: UpsertComponentDto) {
  return parseComponentLifeRow((await api.post<ComponentLifeModelResponse>('/ComponentLife/UpsertComponent', request)).data);
}

export async function toggleComponentLifeEnabled(assetId: number) {
  return parseComponentLifeResponse((await api.post<ComponentLifeResponse>('/ComponentLife/ToggleComponentLifeEnabled', { AssetId: assetId })).data);
}

export async function addComponentReset(componentId: number, date: Date) {
  return parseComponentLifeRow(
    (await api.post<ComponentLifeModelResponse>('/ComponentLife/AddComponentReset', { ComponentId: componentId, Date: formatDateShort(date) })).data
  );
}
export async function updateReplacementDate(componentId: number, oldDate: Date, newDate: Date) {
  return parseComponentLifeRow(
    (
      await api.post<ComponentLifeModelResponse>('/ComponentLife/UpdateComponentReplacement', {
        ComponentId: componentId,
        OldDate: formatDateShort(oldDate),
        NewDate: formatDateShort(newDate)
      })
    ).data
  );
}

export async function updateFirstChangeDate(componentId: number, newDate: Date) {
  return parseComponentLifeRow(
    (await api.post<ComponentLifeModelResponse>('/ComponentLife/UpdateFirstChangeDate', { ComponentId: componentId, Date: formatDateShort(newDate) })).data
  );
}

export async function snoozeComponent(componentId: number) {
  return parseComponentLifeRow((await api.post<ComponentLifeModelResponse>('/ComponentLife/SnoozeComponent', { ComponentId: componentId })).data);
}

export async function setupComponent({ componentId, lastChange, nextChange }: { componentId: number; lastChange: string | null; nextChange: string | null }) {
  return parseComponentLifeRow(
    (await api.post<ComponentLifeModelResponse>('/ComponentLife/SetupComponent', { ComponentId: componentId, NextChange: nextChange, LastChange: lastChange }))
      .data
  );
}
export async function componentLifeDataExists(componentId: number, lastChange: string) {
  const result = (await api.post<string>('/ComponentLife/ComponentLifeDataExists', { ComponentId: componentId, LastChange: lastChange })).data;
  return result.toLowerCase() === 'true';
}

export async function getAssetTypeComponents(assetId: number) {
  if (!assetId) throw new Error('assetId is required: ' + assetId);
  return (await api.get<AssetTypeComponent[]>('/ComponentLife/GetAssetTypeComponents?AssetId=' + assetId)).data;
}

export async function getPartVendors(assetId: number) {
  if (!assetId) throw new Error('assetId is required: ' + assetId);
  return (await api.get<PartVendor[]>('/ComponentLife/GetPartVendors?AssetId=' + assetId)).data;
}

export async function addAssetTypeComponent(name: string, assetId: number, fullLoadHours: number) {
  return (await api.post<AssetTypeComponent>('/ComponentLife/AssetTypeComponent', { Name: name, AssetId: assetId, FullLoadHours: fullLoadHours })).data;
}

export function parseComponentLifeRow(row: ComponentLifeModelResponse): ComponentLifeModel {
  return {
    ...row,
    FormattedNextChangeDate: formatDateShort(parseAspNetDateTime(row.NextChangeDate!)),
    NextChangeDate: parseAspNetDateTime(row.NextChangeDate),
    LastChangeDate: parseAspNetDateTime(row.LastChangeDate),
    FirstChangeDue: parseAspNetDateTime(row.FirstChangeDue)
  };
}

export function parseComponentLifeRows(rows: ComponentLifeModelResponse[]): ComponentLifeModel[] {
  return rows.map((row) => parseComponentLifeRow(row));
}
export function parseComponentLifeResponse(r: ComponentLifeResponse): ComponentLifeParsedResponse {
  return {
    ...r,
    Components: parseComponentLifeRows(r.Components)
  };
}

export type ComponentLifeParsedResponse = Omit<ComponentLifeResponse, 'Components'> & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Components: ComponentLifeModel[];
};

export type ComponentId = number;

export async function deleteReplacement(reset: AssetComponentReset) {
  return parseComponentLifeRow((await api.post<ComponentLifeModelResponse>('/ComponentLife/DeleteReplacement', reset)).data);
}
export async function archiveComponent(componentId: number) {
  await (
    await api.post('/ComponentLife/ArchiveComponent', { ComponentId: componentId })
  ).data;
}

export async function getAssetComponentResets(componentId: number): Promise<AssetComponentReset[]> {
  // return [];
  // return makeResets(50);
  return (
    await api.get<AssetComponentResetResponse[]>('/ComponentLife/AssetComponentResets', { params: { componentId: componentId } })
  ).data.map<AssetComponentReset>((r) => ({
    ...r,
    Date: parseAspNetDateTime(r.Date)
  }));
}

// function makeReset(): AssetComponentReset {
//     return {
//         AssetComponentId: 0,
//         Date: new Date()
//     };
// }
//
// function makeResets(total: number): AssetComponentReset[] {
//     const resets: AssetComponentReset[] = [];
//     for(let i = 0; i < total; i++)
//         resets.push(makeReset());
//     return resets;
// }
