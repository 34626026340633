import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faExclamationTriangle,
  faExclamationCircle,
  faInfo,
  faBellSlash,
  faEllipsisV,
  faHourglassHalf,
  faTimes,
  faSearch,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';

export function configureFontAwesome(): void {
  library.add(
    faBellSlash,
    faCheck,
    faExclamationCircle,
    faExclamationTriangle,
    faInfo,
    faEllipsisV,
    faCommentDots,
    faHourglassHalf,
    faTimes,
    faSearch,
    faInfoCircle
  );
}
