import React, { useState } from 'react';
import { RmxServiceDetails } from '../RmxServiceActivityDetails/RmxServiceDetails';
import s from '../RmxServiceTabs/RmxServiceTabs.module.scss';
import { type ModalState } from '../../hooks/useModal';
import { type Sample } from '../Service.types';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { Tabs } from '../../utils/Tabs/Tabs';
import { RmxServiceSampleReview } from '../RmxServiceSampleReview/RmxServiceSampleReview';
import { SampleDetails } from '../RmxServiceActivityDetails/SampleDetails';

export type ModalTab = 'details';
export type RmxServiceSampleModalParam = { resource: Sample };
type Props = {
  state: ModalState<RmxServiceSampleModalParam, undefined>;
};

export function RmxServiceSampleModal(props: Props) {
  const resource = props.state.param!.resource;
  const sample = resource as Sample;
  const [activeTab, setActiveTab] = useState<ModalTab | string>('details');
  return (
    <GenericModal
      open={props.state.isOpen}
      setIsOpen={props.state.setIsOpen}
      className={s['tab-modal']}
      dialogClassName={s['tab-modal-dialog']}
      contentClassName={s['tab-modal-content']}
      bodyClassName='d-flex flex-column align overflow-hidden p-0'
      label={'Sample'}
      hideFooter={true}
      showClose={true}
    >
      <Tabs
        setActiveTab={setActiveTab}
        withScroll={true}
        activeTab={activeTab}
        noMargin={true}
        growContent={true}
        tabs={[
          {
            key: 'details',
            title: 'Details',
            content: (
              <RmxServiceDetails>
                <SampleDetails sample={sample} />
              </RmxServiceDetails>
            )
          },
          {
            key: 'review',
            title: 'Review',
            content: <RmxServiceSampleReview />
          }
        ]}
      />
    </GenericModal>
  );
}
