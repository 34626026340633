import type { Flag } from '../Service.types';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import React from 'react';
const col = createServerColumnHelper<Flag>().accessor;
const timeOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true // or false for 24-hour clock
};

export const flagsColumnsDef = () =>
  [
    col('status', 'string', {
      meta: { serverFilters: ['exact-text'] },
      header: 'Status',
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id
    }),
    col('flagRecipientGroup', 'string', {
      meta: { serverFilters: ['exact-text'] },
      header: 'User Group',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    col('createdAt', 'date', {
      header: 'Created Date',
      cell: (info) => {
        return (
          <div style={{ display: 'flex' }}>
            <div>
              <div>
                {info.getValue()?.toLocaleDateString()} - {info.getValue()?.toLocaleTimeString(undefined, timeOptions)}{' '}
              </div>
            </div>
          </div>
        );
      },
      footer: (props) => props.column.id,
      enableColumnFilter: true,
      enableSorting: true,
      sortDescFirst: true
    }),
    col('createdByUserName', 'string', {
      header: 'Created By',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    col('reason', 'string', {
      header: 'Reason',
      cell: (info) => {
        const description = info.getValue();
        const maxLength = 50;
        const trimmedDescription = description && description.length > maxLength ? `${description.substring(0, maxLength)}...` : description;

        return <span>{trimmedDescription}</span>;
      },
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    col('claimedByUserName', 'string', {
      header: 'Claimed By',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    col('clearedAt', 'date', {
      header: 'Resolved Date',
      cell: (info) => {
        return (
          <div style={{ display: 'flex' }}>
            <div>
              <div>
                {info.getValue()?.toLocaleDateString()} {info.getValue() && `-`} {info.getValue()?.toLocaleTimeString(undefined, timeOptions)}{' '}
              </div>
            </div>
          </div>
        );
      },
      footer: (props) => props.column.id,
      enableColumnFilter: true,
      enableSorting: true,
      sortDescFirst: true
    })
  ] as const;
