import { createContext, type PropsWithChildren, useContext } from 'react';
import type { QueryObserverResult } from '@tanstack/react-query';
import type { IActivityUpload } from '../api/portal-api';

type RmxServiceModalContextData = {
  refetchIsSlamCompleted: () => Promise<QueryObserverResult<unknown, Error>>;
  refetchUploads: { refetch: () => Promise<QueryObserverResult<IActivityUpload[], Error>>; isRefetching: boolean };
};

// tslint:disable-next-line:no-empty
const RmxServiceModalContext = createContext<RmxServiceModalContextData | undefined>(undefined);
export function useRmxServiceModalContext() {
  const value = useContext(RmxServiceModalContext);
  if (!value) throw new Error('useRmxServiceModalContext must be used within a RmxServiceModalContextProvider');
  return value;
}

export function RmxServiceModalContextProvider({ refetchIsSlamCompleted, refetchUploads, children }: RmxServiceModalContextData & PropsWithChildren) {
  return <RmxServiceModalContext.Provider value={{ refetchIsSlamCompleted, refetchUploads }}>{children}</RmxServiceModalContext.Provider>;
}
