import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { ReactTable, type RowData, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import React, { useEffect, useRef, useState } from 'react';
import {
  type Activity,
  type ActivityContactInteraction,
  type AssetWithDetailsResponse,
  getAssetDetailsLabel,
  isActivityInProgress,
  type Part,
  type Quote,
  type UpdateActivityDebriefRequest
} from '../Service.types';
import { range, stringOrNull } from '../../utils';
import { RmxServiceScope } from '../RmxServiceScope/RmxServiceScope';
import { DarkSelect } from '../../shared-compat/utils/DarkSelect/DarkSelect';
import { ConfirmationDialog } from '../../utils/ConfirmationDialog/ConfirmationDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faQuestionCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useRmxServiceApi } from '../api';
import { convertToDropDownOptionByPredicate, getMapEntries, getMapValues, groupBy, setMap } from '../../../common/util';
import { Map } from 'immutable';
import { useRmxServiceContext } from '../RmxServiceComponent/RmxServiceContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { TimeInfo } from '../RmxServiceTime/RmxServiceTime';
import { useSyncRef } from '../../../hooks/useSyncRef';
import { VerticalInputRow } from '../../../components/util/form-components/VerticalInputRow';
import { useFormData } from '../RmxServiceForms/FormDataContext';
import { useDebounceRequest } from '../../../hooks/useDebounceRequest';
import s from './RmxServiceDebrief.module.scss';
import { GroupedSectionedSelect } from '../../utils/GroupSectionedSelect/GroupedSectionedSelect';
import { parseEstimatedDurationInto8HourDays } from '../RmxServiceQuotes/QuoteForm';
import { useModal } from '../../hooks/useModal';
import { ContactModal } from '../../utils/Contact/ContactModal/ContactModal';
import { reportException } from '../../../util/sentryProvider';
import { CheckBox } from '../../shared-compat/utils/CheckBox/CheckBox';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import { DebriefWithOnboardingGuide } from '../../onboardings/DebriefWithOnboardingGuide';

interface RmxServiceDebriefProps {
  activity: Activity;
  activityPartCount: number;
  techTime: TimeInfo[];
  pickedParts: Part[];
  isSlamCompleted: boolean | undefined;
  setTabbedModalOpen: (isOpen: boolean) => void;
  assetOptions: AssetWithDetailsResponse[];
  enabled: boolean;
  uploadedAllRequiredUploads: boolean;
  fetchingPickedParts: boolean;
  quoteHistory: Quote[];
}
type PickedPartMap = Map<number, { usedQuantity: number | null; surplusReason: string | null }>;

const makePickedPartsMap = (p: Part[], partUsageMap: PickedPartMap, isEditable: boolean): PickedPartMap =>
  Map(
    p.map((part: Part) => {
      const { usedQuantity, surplusReason } = partUsageMap.get(part.id) ?? part;
      return [part.id, { usedQuantity: isEditable && usedQuantity === 0 ? null : usedQuantity, surplusReason: surplusReason }];
    })
  );

export function RmxServiceDebrief({
  activity,
  isSlamCompleted,
  uploadedAllRequiredUploads,
  activityPartCount,
  techTime,
  pickedParts,
  setTabbedModalOpen,
  assetOptions,
  enabled,
  quoteHistory
}: RmxServiceDebriefProps) {
  const [workScope, setWorkScope] = useState(activity.workCompletedDescription);

  const [confirmAsset, setConfirmAsset] = useState<number | null>(
    !enabled && activity.confirmAssetId === null
      ? activity.assetId // if disabled and confirm asset id is null show asset id
      : activity.confirmAssetId //
  );

  const [confirmAssetHours, setConfirmAssetHours] = useState<number | null>(activity.assetHours);
  const [unknownAssetHours, setUnknownAssetHours] = useState<boolean>(activity.unknownAssetHoursReason !== null);
  const [unknownAssetHourReason, setUnknownAssetHourReason] = useState<string>(activity.unknownAssetHoursReason ?? 'Select');
  const [additionalReviewRequired, setAdditionalReviewRequired] = useState<boolean>(activity.additionalReviewRequired ?? false);
  const [forceOpenDebriefedWithOnboarding, setForceOpenDebriefWithOnboarding] = React.useState(false);
  const debriefWithHeader = useRef<HTMLDivElement>(null);

  const { formSubmittedByTitle } = useFormData();

  const [partUsageMap, setPartUsageMap] = useState<PickedPartMap>(Map());

  const partUsageMapRef = useSyncRef(partUsageMap);
  const isEditable = isActivityInProgress(activity);
  useEffect(() => {
    setPartUsageMap(makePickedPartsMap(pickedParts, partUsageMapRef.current, isEditable));
  }, [pickedParts, partUsageMapRef, isEditable]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const [openNoTimeConfirmationDialog, setOpenNoTimeConfirmationDialog] = useState(false);

  const api = useRmxServiceApi();
  const { refetchMyWorkTable } = useRmxServiceContext();

  const assetGroups = groupBy(assetOptions, (a) => a.location ?? 'No Location');
  const assetGroupedOptions = getMapEntries(assetGroups).map(([key, g]) => ({
    label: key,
    options: convertToDropDownOptionByPredicate(
      g,
      (asset) => getAssetDetailsLabel(asset),
      (a) => a.id
    )
  }));
  if (assetGroupedOptions.length === 1 && assetGroupedOptions[0].label === 'No Location') {
    assetGroupedOptions[0].label = '';
  }

  const {
    data: debriefContacts,
    isFetching,
    refetch: refetchDebriefWithContacts
  } = useQuery({
    queryKey: ['debriefWithContacts', activity.id],
    queryFn: async () => {
      return await api.getDebriefWithContacts(activity.id);
    }
  });

  const contactModal = useModal({
    onClose: async () => {
      await refetchDebriefWithContacts();
    }
  });

  const { mutateAsync: addDebriefWith, isPending: addingDebriefWith } = useMutation({
    mutationFn: async (contactId: number) => {
      return await api.debriefWithContactInteraction(activity.id, contactId);
    }
  });

  const { mutateAsync: removeDebriefWith, isPending: removingDebriefWith } = useMutation({
    mutationFn: async (contactInteractionId: number) => {
      return await api.deleteDebriefWithContactInteraction(contactInteractionId);
    }
  });

  const { mutateAsync: updateDebriefWithCommunicationMethod, isPending: updatingDebriefWithCommunicationMethod } = useMutation({
    mutationFn: async ({ contactInteractionId, communicationMethod }: { contactInteractionId: number; communicationMethod: string | null }) => {
      return await api.updateDebriefWithCommMethod(contactInteractionId, communicationMethod);
    }
  });

  const onDebriefWithCommunicationMethodChange = async (contactInteractionId: number, communicationMethod: string | null) => {
    await updateDebriefWithCommunicationMethod({ contactInteractionId, communicationMethod });
    await refetchDebriefWithContacts();
  };

  // on submit update the debrief With contact with the communicationMethod
  const debriefTypeOptions = ['Debrief Type', 'In Person', 'Phone Call', 'Text', 'Email'];
  const debriefWithColumns = [
    {
      header: 'Name',
      accessorKey: 'name',
      valueFormatter: (_, row) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>{row.contact.contactName}</div>;
      }
    },
    {
      header: 'Communication Method',
      accessorKey: 'communicationMethod',
      valueFormatter: (_, row) => {
        return (
          <div>
            {updatingDebriefWithCommunicationMethod ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner size={'sm'} />
              </div>
            ) : (
              <DarkSelect
                style={{ minHeight: '0.6rem', fontSize: '10px' }}
                enabled={enabled}
                options={debriefTypeOptions}
                value={row.communicationMethod ? row.communicationMethod : 'Debrief Type'}
                onChange={async (value) => {
                  await onDebriefWithCommunicationMethodChange(row.id, value === 'Debrief Type' ? null : value);
                }}
              />
            )}
          </div>
        );
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      valueFormatter: (_, row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a href={`mailto:${row?.contact.contactEmail}`}>{row?.contact.contactEmail}</a>
          </div>
        );
      }
    },
    {
      header: 'Phone',
      accessorKey: 'phone',
      valueFormatter: (_, row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '9rem' }}>
            <a href={`tel:${row.contact?.contactPhone}`}>{row.contact?.contactPhone}</a>
          </div>
        );
      }
    },
    {
      header: ' ',
      accessorKey: 'delete',
      valueFormatter: (value, row) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='outline-secondary'
              size='sm'
              disabled={!enabled || removingDebriefWith}
              onClick={async () => {
                try {
                  const contactId = row.id;
                  contactId && (await removeDebriefWith(contactId));
                  await refetchDebriefWithContacts();
                } catch (e) {
                  reportException(`Error removing debrief with contact: ${e}`);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        );
      }
    }
  ] as const satisfies SimpleColumnDef<ActivityContactInteraction>[];

  async function handleOnSubmit() {
    const surplusExists =
      partUsageMap.find((partUsage, key) => {
        const part = pickedParts.find((part) => part.id === key);
        return partUsage.usedQuantity !== part?.totalQuantity && partUsage.surplusReason === null;
      }) !== undefined;

    const unselectedUsedPartsExist = partUsageMap.find((s) => s.usedQuantity === null) || pickedParts.length !== partUsageMap.size;
    if (workScope === '' || workScope === null) {
      alert('Please add work scope');
    } else if (confirmAsset === null) {
      alert('Please confirm asset');
    } else if (pickedParts.length !== activityPartCount) {
      alert('You must pick all parts');
    } else if (unselectedUsedPartsExist) {
      alert('Please select used quantities for all parts');
    } else if ((debriefContacts ?? []).length === 0) {
      alert('Please debrief with at least one person');
    } else if ((debriefContacts ?? []).find((s) => s.communicationMethod == null)) {
      alert('Communication methods for all debriefed with contacts must be selected');
    } else if (surplusExists) {
      alert('You must include a surplus reason if you have not used all part quantities');
    } else if (!isSlamCompleted) {
      alert('Please complete slam');
    } else if (formSubmittedByTitle && getMapValues(formSubmittedByTitle).includes(false)) {
      alert('You have incomplete forms, please complete them or delete them before submitting the debrief.');
    } else if (!uploadedAllRequiredUploads) {
      alert('Please upload all required files before submitting the debrief.');
    } else if ((confirmAssetHours === 0 || confirmAssetHours === null) && !unknownAssetHours) {
      alert('Please enter confirm asset hours or select unknown asset hours');
    } else if (unknownAssetHourReason === 'Select' && unknownAssetHours) {
      alert('Please select unknown asset hour reason');
    } else if (techTime.length === 0) {
      setOpenNoTimeConfirmationDialog(true);
    } else {
      setOpenConfirmationDialog(true);
    }
  }

  // TODO if have picked qty is null then set it to qty

  // adds a select input to the PickedParts table to allow the user to select the quantity of parts used
  function getUsedPartsTableData() {
    if (pickedParts) {
      return pickedParts.map<RowData>((part: Part) => {
        return {
          ...part,
          usedQuantity: (
            <Form.Control
              as='select'
              disabled={!enabled}
              size={'sm'}
              aria-label='Select number of used parts'
              value={partUsageMap.get(part.id)?.usedQuantity ?? ''}
              onChange={(e) => {
                setPartUsageMap((s) =>
                  s.set(
                    part.id,
                    e.target.value === ''
                      ? { usedQuantity: null, surplusReason: null }
                      : { usedQuantity: parseInt(e.target.value), surplusReason: s.get(part.id)?.surplusReason ?? null }
                  )
                );
              }}
            >
              {<option value=''>Select</option>}
              {range(0, part.totalQuantity).map((value, index) => (
                <option key={index} value={index}>
                  {value}
                </option>
              ))}
            </Form.Control>
          ),
          surplusReason: (
            <Form.Control
              as='select'
              disabled={!enabled || partUsageMap.get(part.id)?.usedQuantity === null || partUsageMap.get(part.id)?.usedQuantity === part.totalQuantity}
              size={'sm'}
              aria-label='Enter surplus reason'
              value={partUsageMap.get(part.id)?.surplusReason ?? ''}
              onChange={(e) => {
                setPartUsageMap((s) =>
                  setMap(s, part.id, (prev) => ({ usedQuantity: prev?.usedQuantity ?? null, surplusReason: stringOrNull(e.target.value) }))
                );
              }}
            >
              {<option value=''>Select</option>}
              {['Not Needed', 'Part Issue', 'Left At Site'].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Control>
          )
        };
      });
    }
    return [];
  }

  const timeColumns = [
    { header: 'Date', accessorKey: 'date' },
    { header: 'Start Time', accessorKey: 'startTime' },
    { header: 'End Time', accessorKey: 'endTime' },
    { header: 'Total', accessorKey: 'total' },
    { header: 'Type', accessorKey: 'type' }
  ] satisfies SimpleColumnDef[];

  const pickedPartsColumns = [
    { header: 'CCN', accessorKey: 'ccn' },
    { header: 'Part Name', accessorKey: 'partName' },
    { header: 'Quantity', accessorKey: 'totalQuantity' },
    { header: 'Used', accessorKey: 'usedQuantity', valueFormatter: (value) => <div style={{ width: '8rem' }}>{value}</div> },
    {
      header: 'Surplus Reason',
      accessorKey: 'surplusReason',
      valueFormatter: (value) => {
        return <div style={{ width: '8rem' }}>{value}</div>;
      }
    }
  ] satisfies SimpleColumnDef[];
  const quoteHistoryColumns = [
    {
      header: 'Date',
      accessorKey: 'date',
      valueFormatter: () => new Date().toLocaleDateString()
    },
    { header: 'Title', accessorKey: 'title' },
    { header: 'Techs', accessorKey: 'numberOfTechs' },
    {
      header: 'Estimated Duration (8 Hr Days)',
      accessorKey: 'estimatedDuration',
      valueFormatter: (value: string) => {
        return `${parseFloat(parseEstimatedDurationInto8HourDays(value))} days`;
      }
    },
    { header: 'Private Notes', accessorKey: 'privateNotes' },
    { header: 'Description', accessorKey: 'description', valueFormatter: (value: string) => (value === '' ? 'Description Pending' : value) }
  ] as const satisfies SimpleColumnDef[];

  const { mutateAsync: updateActivityDebrief, isPending: submitting } = useMutation({
    mutationFn: async (req: UpdateActivityDebriefRequest) => {
      return api.updateActivityDebrief(req);
    }
  });
  const { mutateAsync: updateActivityConfirmAsset } = useMutation({
    mutationFn: async ({ activityId, assetId }: { activityId: string; assetId: number }) => {
      return api.updateActivityConfirmAsset(activityId, assetId);
    }
  });

  const debounceConfirmHours = useDebounceRequest({
    fn: async (hours: number, signal) => {
      await api.updateActivityAssetHours(activity.id, hours, signal);
    },
    debounceTime: 1000
  });

  const selectedAssetOption = assetGroupedOptions.flatMap((g) => g.options).find((a) => a.value === confirmAsset);
  return (
    <div>
      <Form>
        <RmxServiceScope
          enabled={enabled}
          api={api}
          activityId={activity.id}
          workScopeText={workScope !== null ? workScope : ' '}
          setWorkScopeText={setWorkScope}
        />
        <VerticalInputRow label='Confirm Asset'>
          <GroupedSectionedSelect
            value={selectedAssetOption}
            options={assetGroupedOptions}
            onChange={async (e) => {
              setConfirmAsset(e.value); // e.value is the rmx asset id
              await updateActivityConfirmAsset({ activityId: activity.id, assetId: e.value });
            }}
            disabled={!enabled}
          />
        </VerticalInputRow>

        <VerticalInputRow label={'Asset Hours'}>
          <Form.Control
            disabled={!enabled || unknownAssetHours}
            size={'sm'}
            type='number'
            value={confirmAssetHours !== null ? confirmAssetHours : ''}
            onChange={async (e) => {
              const inputtedHours = parseInt(e.target.value);
              const formattedHours = isNaN(inputtedHours) ? null : inputtedHours;
              setConfirmAssetHours(formattedHours);
              await debounceConfirmHours.handleDebounceRequest(formattedHours ?? 0);
            }}
          />
        </VerticalInputRow>

        <VerticalInputRow className={s['asset-hour-confirm']}>
          <CheckBox
            size='md'
            enabled={enabled}
            label='Unknown Asset Hours'
            value={unknownAssetHours}
            onChange={async (checked) => setUnknownAssetHours(checked)}
          />
          <DarkSelect
            enabled={unknownAssetHours && enabled}
            className={s['dark-select']}
            options={['Select', 'Offsite', 'No power to unit', 'N/A']}
            value={unknownAssetHourReason}
            onChange={(e) => setUnknownAssetHourReason(e)}
          />
        </VerticalInputRow>
        <Row className={'mb-3'}>
          <Col sm={12}>
            <Card>
              <Card.Header>Time Entries</Card.Header>
              {techTime.length > 0 ? (
                <ReactTable includeHeaders={true} columns={timeColumns} data={techTime} />
              ) : (
                <Card.Body>
                  <> No Time Entries Have Been Added</>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Header>Parts Used</Card.Header>
              {pickedParts && pickedParts.length > 0 ? (
                <ReactTable includeHeaders={true} columns={pickedPartsColumns} data={getUsedPartsTableData()} />
              ) : (
                <Card.Body>
                  <> No Parts Have Been Added</>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>

        <Card>
          <Card.Header>Quote History</Card.Header>
          {quoteHistory && quoteHistory.length > 0 ? (
            <ReactTable bordered={false} striped={false} includeHeaders={true} columns={quoteHistoryColumns} data={quoteHistory} />
          ) : (
            <Card.Body>
              <> No Quotes Have Been Added</>
            </Card.Body>
          )}
        </Card>

        <Card>
          <Card.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <>
              {/*//setForceOpenContactOnboarding(true)} />*/}
              <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                <div ref={debriefWithHeader}>Debrief With</div>
                <IconButton
                  icon={faQuestionCircle}
                  size='lg'
                  onClick={() => {
                    setForceOpenDebriefWithOnboarding(true);
                  }}
                />
              </div>
              <div>
                <Button disabled={!enabled} size={'sm'} variant={'outline-secondary'} onClick={() => contactModal.setIsOpen(true)}>
                  Add Contact
                </Button>
              </div>
            </>
          </Card.Header>
          <Card.Body>
            {debriefContacts && debriefContacts.length > 0 ? ( // if debrief contacts exist show if there are none but the activity.debriefedWith is not null show that
              <>
                <ReactTable striped={false} bordered={false} includeHeaders={true} columns={debriefWithColumns} data={debriefContacts} />
              </>
            ) : isFetching || addingDebriefWith || removingDebriefWith || updatingDebriefWithCommunicationMethod ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner />
              </div>
            ) : activity.debriefedWith ? (
              activity.debriefedWith
            ) : (
              <>No Debrief With Contacts Have Been Added</>
            )}
          </Card.Body>
        </Card>

        <DebriefWithOnboardingGuide
          forceDisplay={forceOpenDebriefedWithOnboarding}
          headerRef={debriefWithHeader}
          onExit={() => setForceOpenDebriefWithOnboarding(false)}
        />

        <div className='mb-3' key={activity.id} style={{ display: 'flex', flexDirection: 'row', gap: '3px' }}>
          <CheckBox
            size='md'
            value={additionalReviewRequired}
            enabled={enabled}
            label='Additional Review Required'
            onChange={(checked) => {
              console.log(checked);
              setAdditionalReviewRequired(checked);
            }}
          />
        </div>

        <h5 className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '10px', color: 'white' }}>
          {isSlamCompleted ? 'SLAM Completed' : 'SLAM Not Completed'}
          {isSlamCompleted ? (
            <FontAwesomeIcon icon={faCheckCircle} className='text-success' style={{ color: 'green' }} />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} className='text-danger' style={{ color: 'red' }} />
          )}
        </h5>
        <Button disabled={!enabled || submitting} className='w-100' variant='secondary' onClick={async () => await handleOnSubmit()}>
          Submit
        </Button>
      </Form>
      <ConfirmationDialog
        title={'No Time Entries'}
        open={openNoTimeConfirmationDialog}
        onAccept={async () => {
          setOpenNoTimeConfirmationDialog(false);
          setOpenConfirmationDialog(true);
        }}
        onDecline={() => setOpenNoTimeConfirmationDialog(false)}
        prompt='There are no time entries on this activites. Are you sure you want to continue?'
        positiveText='Yes'
        negativeText='No'
      />
      <ConfirmationDialog
        title={' '}
        open={openConfirmationDialog}
        onAccept={async () => {
          console.log(`submitting ${additionalReviewRequired}`);
          await updateActivityDebrief({
            activityId: activity?.id,
            scope: workScope!,
            usedParts: getMapEntries(partUsageMap).map(([partId, part]) => ({
              partId: partId,
              quantity: parseInt(String(part.usedQuantity)),
              surplusReason: part.surplusReason
            })),
            confirmAssetId: confirmAsset!,
            assetHours: unknownAssetHours ? null : confirmAssetHours,
            unknownAssetHourReason: !unknownAssetHours ? null : unknownAssetHourReason,
            additionalReviewRequired: additionalReviewRequired
          });
          await refetchMyWorkTable();
          setTabbedModalOpen(false);
        }}
        onDecline={() => setOpenConfirmationDialog(false)}
        prompt='Confirm submission. Ensure all details are correct before proceeding.'
        positiveText='Confirm'
        negativeText='Continue Editing'
      />
      <ContactModal
        activity={activity}
        state={contactModal}
        extraCustomerContactEllipsisItems={[
          {
            label: 'Debriefed With',
            onClick: async (value) => {
              value.id && (await addDebriefWith(value.id));
              contactModal.close();
              await refetchDebriefWithContacts();
            }
          }
        ]}
        extraWorkOrderContactEllipsisItems={[
          {
            label: 'Debriefed With',
            onClick: async (value) => {
              value.id && (await addDebriefWith(value.id));
              contactModal.close();
            }
          }
        ]}
        extraAgreementContactEllipsisItems={[
          {
            label: 'Debriefed With',
            onClick: async (value) => {
              value.id && (await addDebriefWith(value.id));
              contactModal.close();
            }
          }
        ]}
      />
    </div>
  );
}
